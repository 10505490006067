import M from 'backbone.marionette'
import T from './suiviitem.html'
import { BaseSuiviView } from './basesuiviview';
import { userdata } from '../../user';

const ListSuiviItem = BaseSuiviView.extend({
    tagName: 'tr',
    template: _.template(T),
    editing: null,
    initialModel: null,
    ui: {
        'btModifier':'.btModifier',
        'btSauvegarder':'.btSauvegarder',
        'btAnnuler':'.btAnnuler',
        'btSupprimer':'.btSupprimer'
    },
    triggers: {
        'click .btModifier': 'modifier',
        'click .btSauvegarder': 'sauvegarder',
        'click .btAnnuler': 'annuler',
        'click .btSupprimer': 'supprimer',
    },
    serializeData: function(){
        
        return _.extend(
            this.model.toJSON(),
            { nomContributeur: this.model.get('author') }  
        );
    },
    canEdit: function(){
        return userdata('Type') === 'contributeur' || userdata('user_id') == this.model.get('author_id');
    },
    onRender: function(){
        if(this.editing){
            this.renderInputs(this.initialModel);
            this.ui.btModifier.hide();
            this.ui.btSupprimer.hide();
            this.ui.btAnnuler.show();
            this.ui.btSauvegarder.show();
        }else{
            if(this.canEdit()){
                this.ui.btModifier.show();
                this.ui.btSupprimer.show();
            }else{
                this.ui.btModifier.hide();
                this.ui.btSupprimer.hide();
            }
            this.ui.btAnnuler.hide();
            this.ui.btSauvegarder.hide();
        }
    },
    onReset: function(){
        this.editing = false;
        this.render();
    },
    onModifier: function(){
        if(!this.canEdit())return;
        this.editing = true;
        this.initialModel = this.model.clone();
        this.render();
    },
    onAnnuler: function(){
        this.editing = false;
        this.model.set(this.initialModel.toJSON());
        this.render();
    },
})

export const ListSuivis = M.CollectionView.extend({
    template: _.template(''),
    tagName: 'tbody',
    childViewTriggers: {
        'modifier': 'modifier',
        'sauvegarder': 'sauvegarder',
        'annuler': 'annuler',
        'supprimer': 'supprimer',
    },
    childView: ListSuiviItem,
    childViewOptions: function(){
        return  { controller: this.getOption('controller')}
    }
})