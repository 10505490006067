import M from 'backbone.marionette'
import { IndexeurAutocompleteView } from '../utils/autocomplete/indexeurautocomplete'
import { FormElement } from '../utils/bootstrap/formelement';

export const AutocompleteView = M.View.extend({
    template: _.template('<div></div>'),
    regions: {
        'content':{
            el: '>div',
            replaceElement: true,
        }
    },
    initialize: function () {
        if(!this.getOption('attributeName'))console.error('Module autocomplete -> il manque l\'attribut data-attribute-name', this.el);
        if(!this.getOption('indexType'))console.error('Module autocomplete -> il manque l\'attribut data-index-type', this.el );
        this.model = new Backbone.Model();
        this.model.set(this.getOption('attributeName'), null);
    },
    onRender: function(){
        this.showChildView('content', new FormElement({
            type: 'indexeurautocomplete',
            model: this.model,
            attribute: this.getOption('attributeName'),
            indexType: this.getOption('indexType'),
            hideLabel: true,
            serviceUrl: 'autocomplete/search/'+this.getOption('indexType'),
            inputAttributes: {
                placeholder: this.getOption('placeholder')
            },
        }))
        /*
        this.showChildView('content', new IndexeurAutocompleteView({
            model: this.model,
            attribute: this.getOption('attributeName'),
            indexType: this.getOption('indexType'),
            serviceUrl: 'autocomplete/search/'+this.getOption('indexType'),
        }))
        */
    }

})