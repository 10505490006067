import M from 'backbone.marionette'
import { Loading } from '../../utils/bootstrap/loading';
import T from './programme.html'
import { FormElement } from '../../utils/bootstrap/formelement';
import { FormHandler } from '../../utils/bootstrap/form/formhandler';
import { Toast } from '../../utils/bootstrap/toast';
import { RacAPI } from '../dataapi';
import { CoursAssocies } from './coursassocies';

const ProgrammeContainer = M.View.extend({
    template: _.template(T),
    childViewTriggers: {
        'supprimer:cours':'supprimer:cours',
        'associer:cours':'associer:cours',
    },
    triggers: {
        'click .btSauvegarder':'sauvegarder'
    },
    regions: {
        'CodeProgramme':'.CodeProgramme',
        'NomProgramme':'.NomProgramme',
        'Type':'.Type',
        'listecours':'.listecours'
    },
    onRender: function(){
        this.showChildView('CodeProgramme', new FormElement({
            type: 'text',
            model: this.model,
            attribute: 'CodeProgramme',
            label : 'Code du programme',
        }))
        this.showChildView('NomProgramme', new FormElement({
            type: 'text',
            model: this.model,
            attribute: 'NomProgramme',
            label : 'Nom du programme',
        }))
        this.showChildView('Type', new FormElement({
            type: 'select',
            model: this.model,
            attribute: 'Type',
            label : 'Type (AEC ou DEC)',
            collection: new Backbone.Collection([
                {value: '', caption : '[Sélectionner...]'},
                {value: 'AEC'},
                {value: 'DEC'}
            ])
        }))
        
        this.showChildView('listecours', new CoursAssocies({
            collection: this.getOption('listecours')
        }))
        
    },
    onSauvegarder: function(){
        const formHandler = new FormHandler();
        formHandler.setModel(this.model);
        formHandler.save(
            'api/programme/save',
            (response) => {
                this.model.set(response);
                this.render();
                Toast.add('Les informations du programme ont été modifiés avec succès','success');
            },
            (xhr) => {
                RacAPI.handleError(xhr)
            }
        )
    },
    onSupprimerCours: function(view){
        const model = view.model;
        if(confirm('Êtes-vous certain de vouloir dissocier ce cours du programme ?')){
            $.ajax({
                url: 'api/programme/supprimer_cours',
                data: {
                    NoListeCours: model.get('NoListeCours')
                },
                type: 'post',
                success: () => {
                    this.getOption('listecours').remove(model);
                    Toast.add('Le cours a été dissociée avec succès', 'success');
                    view.triggerMethod('reset');
                },
                error: (xhr) => {
                    RacAPI.handleError(xhr)
                }
            })
        }
    },
    
    onAssocierCours: function(view){
        $.ajax({
            url: 'api/programme/associer_cours',
            data: {
                NoProgramme: this.model.get('NoProgramme'),
                NoCours: view.model.get('newNoCours')
            },
            type: 'post',
            success: (response) => {
                this.getOption('listecours').add(response);
                Toast.add('Le cours a été associé avec succès', 'success');
                view.triggerMethod('reset'); 
            },
            error: (xhr) => {
                RacAPI.handleError(xhr)
            }
        })
    }
});


// a loader class
export const ProgrammeView = M.View.extend({
    template: _.template('<div></div>'),
    modelEvents: {
        'change:loaded':'render',
    },
    regions: {
        'content':{
            el: '>div',
            replaceElement: true
         }
    },
    listecours: null,
    initialize: function(){
        this.model = new Backbone.Model({
            loaded: false,
            NoProgramme: this.getOption('noProgramme')
        })
        this.listecours = new Backbone.Collection();
        this.load();
    },
    load: function(){
        $.ajax({
            url: 'api/programme/data/'+ this.model.get('NoProgramme'),
            success: (response) => {
                this.listecours.reset(response.listecours);
                this.model.set(response.programme);
                this.model.set('loaded', true);
            }
        })
    },
    onRender: function(){
        if(this.model.get('loaded')){
            this.showChildView('content', new ProgrammeContainer({
                model: this.model,
                listecours: this.listecours
            }))
        }else{
            this.showChildView('content', new Loading());
        }
    }
})