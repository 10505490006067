import M from 'backbone.marionette'
import T from './accesetudiant.html'
import { EtudiantInvitations } from './invitations';
import { RacAPI } from '../../dataapi';
import { Toast } from '../../../utils/bootstrap/toast';
import { InvitationSender } from './invitationsender';
import { showModal } from '../../../utils/bootstrap/modal';

const STATE_DEACTIVATED = 1;
const STATE_ACTIVE = 2;
const STATE_INACTIVE = 3;

export const AccesEtudiant = M.View.extend({
    template: _.template(T),
    regions: {
        'invitations': '.invitations'
    },
    ui: {
        'accessLabel': '.accessLabel',
        'btnAccessAction': '.btnAccessAction'
    },
    triggers: {
        'click .btnAccessAction': 'action'
    },
    state: null,
    initialize: function () {
        this.listenTo(this.getOption('controller').get('etudiant'), 'change:Courriel', _.bind(this.updateUI, this))
        this.listenTo(this.getOption('controller').get('etudiant'), 'change:PlatformAccess', _.bind(this.updateUI, this))
    },
    onRender: function () {
        this.updateUI();
        this.showChildView('invitations', new EtudiantInvitations({
            controller: this.getOption('controller')
        }))
    },
    updateUI: function () {
        const state = this.getState();
        if (state !== this.state) {
            this.state = state;
            switch (this.state) {
                case STATE_DEACTIVATED:
                    this.ui.accessLabel.addClass('label-danger').removeClass('label-success').html('Non, accès retirées');
                    this.ui.btnAccessAction.show().html('Réactiver le compte');
                    break;
                case STATE_ACTIVE:
                    this.ui.accessLabel.removeClass('label-danger').addClass('label-success').html('Oui');
                    this.ui.btnAccessAction.show().html('Retirer l\'accès');
                    break;
                case STATE_INACTIVE:
                    this.ui.accessLabel.addClass('label-danger').removeClass('label-success').html('Non');
                    this.ui.btnAccessAction.show().html('Inviter le candidat à se connecter');
                    break;
            }
        }
    },
    getState: function () {
        // L'étudiant a-t-il accès à la plateforme ?
        // Tout d'abord, vérifier s'il a une entrée dans la liste des utilisateurs
        const col = this.getOption('controller').get('utilisateurs').where({ Courriel: this.getOption('controller').get('etudiant').get('Courriel') })
        if (col && col.length) {
            // ok, une entrée lui permettant de se connecter existe
            if (!this.getOption('controller').get('etudiant').get('PlatformAccess')) {
                // accès manuellement désactivé
                return STATE_DEACTIVATED;
            } else {
                // il a accès
                return STATE_ACTIVE;
            }
        } else {
            // pas de compte pour se connecter, on doit permettre de lui en créer un
            return STATE_INACTIVE;
        }
    },
    onAction: function () {
        switch (this.state) {
            case STATE_DEACTIVATED:
                this.ui.btnAccessAction.prop('disabled', true);
                $.ajax({
                    url: 'api/etudiants/activatestudentaccess',
                    type: 'post',
                    data: {
                        NoEtudiant: this.getOption('controller').get('etudiant').get('NoEtudiant')
                    },
                    success: (response) => {
                        if (response === true) {
                            this.getOption('controller').get('etudiant').set('PlatformAccess', true)
                        } else {
                            Toast.add("Impossible de donner accès de ce candidat", "danger")
                        }
                        this.ui.btnAccessAction.prop('disabled', false)
                    },
                    error: (xhr) => {
                        RacAPI.handleError(xhr)
                        this.ui.btnAccessAction.prop('disabled', false)
                    }
                })
                break;
            case STATE_ACTIVE:
                this.ui.btnAccessAction.prop('disabled', true);
                $.ajax({
                    url: 'api/etudiants/deactivatestudentaccess',
                    type: 'post',
                    data: {
                        NoEtudiant: this.getOption('controller').get('etudiant').get('NoEtudiant')
                    },
                    success: (response) => {
                        if (response === true) {
                            this.getOption('controller').get('etudiant').set('PlatformAccess', false)
                        } else {
                            Toast.add("Impossible de retirer les accès de ce candidat", "danger")
                        }
                        this.ui.btnAccessAction.prop('disabled', false)
                    },
                    error: (xhr) => {
                        RacAPI.handleError(xhr)
                        this.ui.btnAccessAction.prop('disabled', false)
                    }
                })
                break;
            case STATE_INACTIVE:
                // afficher la fenêtre d'invitation
                const view = new InvitationSender({ model: this.getOption('controller') });
                showModal('Invitation à se connecter', view);
                break;
        }
    }
})
