import Backbone from 'backbone';
import M from 'backbone.marionette'
import T from './modal.html'

const Modal = M.View.extend({
    views: null,
    template: _.template(T),
    ui: {
        'title': '.modal-title',
        'body': '.modal-body',
    },
    events: {
        'click .btModalClose': 'onCurrentViewClose'
    },
    regions: {
        'body': '.modal-body'
    },
    childViewEventPrefix: 'childview',
    initialize: function () {
        this.views = [];
        this.model = new Backbone.Model({
            title: ''
        })
        this.$el.hide();
        $(window).on('resize', () => {
            this.adjustHeight();
        });
    },
    show: function (title, view) {
        this.views.push({
            title: title,
            view: view
        });
        this.showLastView();
    },
    showLastView: function () {
        if (this.views.length) {
            var r = this.getRegion('body');
            if (r.hasView()) r.detachView();
            this.ui.title.html(this.views[this.views.length - 1].title);
            this.showChildView('body', this.views[this.views.length - 1].view);
            this.$el.show();
            this.adjustHeight();
        } else {
            this.$el.hide();
        }
    },
    onCurrentViewClose: function(){
        this._closeView(this.views[this.views.length-1].view);
    },
    onChildviewResponse: function(_value, view){
        this._closeView(view);
    },
    onChildviewClose: function (view) {
        this._closeView(view);
    },
    _closeView: function(view){
        if(this.views[this.views.length-1].view === view){
            var r = this.getRegion('body');
            this.views.pop();
            if (r.hasView()) r.empty();
            this.showLastView();
        }else{
            for(var i = 0; i < this.views.length; i++){
                if(this.views[i].view == view){
                    this.views[i].view.destroy();
                    this.views.splice(i, 1)
                    i--;
                }
            }
        }
    },
    closeAll: function () {
        var r = this.getRegion('body');
        if (r.hasView()) r.empty();
        for(var i = 0; i < this.views.length; i++){
            this.views[i].view.destroy();
        }
        this.views = [];
        this.showLastView();
    },
    adjustHeight: function(){
        var bodyOffset = this.ui.body.offset();
        var availableHeight = $(window).height();
        var targetHeight = (availableHeight - bodyOffset.top) - 10;
        this.ui.body.css({
            'overflow-y': 'auto',
            'max-height': targetHeight
        })
    },
})
var __modal = null;
export const showModal = function (title, view) {
    if (__modal === null) {
        var el = $('<div id="modalBase"></div>');
        $('body').append(el)
        __modal = new Modal({ el: el });
        __modal.render();
    }
    __modal.show(title, view);
}
export const closeAllModal = function () {
    if (__modal !== null) {
        __modal.closeAll();
    }
}
const AlertView = M.View.extend({
    template: _.template('<p class="text-center"><%=message%></p><div class="text-center"><button class="btn btn-primary btn-close">OK</button></div>'),
    serializeData: function () {
        return {
            message: this.getOption('message')
        }
    },
    triggers: {
        'click .btn-close': 'close:click',
    },
    onCloseClick: function (event) {
        this.triggerMethod('close', this);
    }
})
export const showAlert = function (title, message) {
    if (__modal === null) {
        var el = $('<div id="modalBase"></div>');
        $('body').append(el)
        __modal = new Modal({ el: el });
        __modal.render();
    }
    __modal.show(title, new AlertView({ message: message }));
}

const ConfirmButtonView = M.View.extend({
    template: _.template('<%=id%>'),
    tagName: 'button',
    className: 'btn btn-default',
    triggers: {
        'click':'response'
    }
})

const ConfirmView = M.CollectionView.extend({
    template: _.template('<p class="text-center"><%=message%></p><div class="text-center buttonContainer"></div>'),
    serializeData: function () {
        return {
            message: this.getOption('message')
        }
    },
    initialize: function(){
        this.collection = new Backbone.Collection(_.keys(this.getOption('options')).map( (index) => {
            return { id: index, value: this.getOption('options')[index] }
        }))
    },
    events: {
        'click .btn-close': 'onCloseClick',
    },
    onCloseClick: function () {
        this.triggerMethod('close', this);
    },
    childViewEventPrefix: 'childview',
    childViewContainer: '.buttonContainer',
    childView: ConfirmButtonView,
    onChildviewResponse: function(view){
        this.triggerMethod('response', view.model.get('value'), this);
    }
})
export const showConfirm = function (message, onReponse, options) {
    if (__modal === null) {
        var el = $('<div id="modalBase"></div>');
        $('body').append(el)
        __modal = new Modal({ el: el });
        __modal.render();
    }
    let view = null
    const onViewResponse = (value) => { 
        onReponse(value)
        __modal._closeView(view)
    }
    view = new ConfirmView({ message: message, options: options ? options : {'Ok': true, 'Annuler' : false}, onResponse: onViewResponse})
    __modal.show('Confirmation', view);
}

const LoadingView = M.View.extend({
    template: _.template('<p class="text-center"><%=message%></p><div class="text-center"><i class="loadingIcon"></i></div>'),
    serializeData: function () {
        return {
            message: this.getOption('message')
        }
    },
    events: {
        'click .btn-close': 'onCloseClick',
    },
    onCloseClick: function (event) {
        this.triggerMethod('close', this);
    },
    close: function(){
        this.triggerMethod('close', this);
    }
})
export const showLoading = function (message) {
    if (__modal === null) {
        var el = $('<div id="modalBase"></div>');
        $('body').append(el)
        __modal = new Modal({ el: el });
        __modal.render();
    }
    const v = new LoadingView({ message: message ? message : 'Chargement en cours ...' });
    __modal.show('Veuillez patienter', v);
    return v
}