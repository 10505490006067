import M from 'backbone.marionette'
import { FormElement } from '../../../utils/bootstrap/formelement'

export const BaseSuiviView = M.View.extend({
    regions: {
        'TextSuivi':'.TextSuivi',
        'Type':'.Type',
        'DateSuivi':'.DateSuivi',
    },
    renderInputs: function(model){
        this.showChildView('TextSuivi', new FormElement({
            model: model,
            attribute: 'TextSuivi',
            type:'textarea',
            label: 'Suivi',
            inputAttributes: {
                rows: 10
            }
        }))
        this.showChildView('Type', new FormElement({
            model: model,
            attribute: 'Type',
            type:'select',
            label: 'Type de suivi',
            collection: this.getOption('controller').get('types_suivis')
        }))
    }
})