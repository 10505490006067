import M from 'backbone.marionette'
import T from './competencesassociees.html'
import TItem from './competencesassocieesitem.html'
import { FormElement } from '../../utils/bootstrap/formelement'

const Item = M.View.extend({
    tagName: 'tr',
    triggers: {
        'click .btSupprimer':'supprimer:competence',
    },
    template: _.template(TItem),
    
})

const List = M.CollectionView.extend({
    tagName: 'tbody',
    childView: Item,
    childViewTriggers: {
        'supprimer:competence':'supprimer:competence',
    },
})

export const CompetencesAssociees = M.View.extend({
    template: _.template(T),
    childViewTriggers: {
        'supprimer:competence':'supprimer:competence',
    },
    triggers: {
        'click .btAssocier':'associer:competence',
    },
    regions: {
        'list':{
            el: '.list',
            replaceElement: true,
        },
        'newNoCompetence':'.newNoCompetence'
    },
    initialize: function(){
        this.model = new Backbone.Model({
            newNoCompetence: null,
        })
    },
    onDestroy: function(){
        this.model = null;
    },
    onReset: function(){
        this.model.set('newNoCompetence', null);
        this.showAutocomplete();
    },
    onRender: function(){
        this.showChildView('list', new List({
            collection: this.getOption('collection'),
        }))
        this.showAutocomplete();
    },
    showAutocomplete: function(){
        this.showChildView('newNoCompetence', new FormElement({
            type: 'indexeurautocomplete',
            indexType: 'competence',
            model: this.model,
            attribute: 'newNoCompetence',
            hideLabel: true,
            serviceUrl: 'autocomplete/search/competence',
            inputAttributes: {
                placeholder: 'Rechercher une compétence...'
            }
        }))
    }
})