import tinymce from 'tinymce';

import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import 'tinymce/plugins/code';
 import 'tinymce/plugins/link';
 import 'tinymce/plugins/lists';
 import 'tinymce/plugins/paste';
 import 'tinymce/plugins/image';
 import 'tinymce/plugins/media';

 import css from 'css.css'

import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
import contentCss from 'tinymce/skins/content/default/content.css';

export const tinymceView = M.View.extend({ 
    template: _.template(''),
    id: null,
    initialValue: null,
    initialize: function(){
        if(this.$el.attr('id')){
            this.id = this.$el.attr('id');
        }else{
            this.$el.attr('id', 'view' + this.cid)
            this.id = 'view' + this.cid;
        }
        this.initialValue = this.$el.text();
        console.log('this.initialValue', this.initialValue)
        tinymce.init(this.getPluginOptions());
        //tinymce.setContent(this.initialValue);
    },
    getPluginOptions: function(){
        console.log('id', this.id)
        if(this.getOption('full')){
            return {
                selector: '#' + this.id,
                plugins: 'code link lists paste image media',
                toolbar: 'formatselect | bold italic | bullist numlist | link image media',
                block_formats: 'Normal=p; Titre=h2; Titre2=h3; Titre3=h4',
                skin: false, 
                menubar: false,
                /*content_css: css,*/
                /*content_style: + contentUiCss + '\n' + contentCss,*/
                content_css: false,
                content_style: css + '\n' + contentUiCss + '\n' + contentCss,
                contextmenu: null,
                paste_auto_cleanup_on_paste : true,
                paste_remove_styles: true,
                paste_remove_styles_if_webkit: true,
                paste_strip_class_attributes: true,
                setup: (editor) => {
                    editor.on('init', () => {
                        var content = this.initialValue;
                        editor.setContent(content);
                    });
                }  
            };
        }else{
            return {
                selector: '#' + this.id,
                plugins: 'code link lists paste',
                toolbar: 'formatselect | bold italic | bullist numlist | link',
                block_formats: 'Normal=p; Titre=h2; Titre2=h3; Titre3=h4',
                skin: false,
                menubar: false,
                /*content_css: css,
                content_style: contentUiCss + '\n' + contentCss,*/
                content_css: false,
                content_style: css + '\n' + contentUiCss + '\n' + contentCss,
                contextmenu: null,
                paste_auto_cleanup_on_paste : true,
                paste_remove_styles: true,
                paste_remove_styles_if_webkit: true,
                paste_strip_class_attributes: true,
                setup: (editor) => {
                    editor.on('init', () => {
                        var content = this.initialValue;
                        editor.setContent(content);
                    });
                }  
            };
        }
    }
})