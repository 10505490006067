import Backbone from 'backbone';
import M from 'backbone.marionette'
import { ChargeDropZone } from './chargedropzone';
import { gestionChargeController } from './controller';
import TEnseignantListItem from './enseignantlisteitem.html'

const EnseignantListItemBase = M.View.extend({
    template :_.template(TEnseignantListItem),
    triggers: {
        'click .btConsulter': 'consulter:click',
    },
    serializeData: function(){
        const currCount = this.model.get('selectedCompetence') ? gestionChargeController.get('competenceetudiant').filter(p => p.get('enseignant_id') == this.model.get('NoEnseignant') && p.get('NoCompetence') == this.model.get('selectedCompetence').id).length : 0;
        if(gestionChargeController.get('id') == this.model.get('NoEnseignant')){
            return _.extend(this.model.toJSON(), {
                diff: this.model.get('listecompens') ? (currCount - (this.model.get('listecompens').compens.active_comps)) : null
            });
        }else{
            return _.extend(this.model.toJSON(), {
                diff: currCount
            });
        }
        
    },
    onConsulterClick: function(){
        console.log(this.model.toJSON())
        gestionChargeController.loadData('enseignant', this.model.get('NoEnseignant'))
    },
    
})
const EnseignantListItemActifs = EnseignantListItemBase.extend({
    className: 'list-group-item list-enseignant-item actif',
    regions: {
        'dropzone':{
            el: '.dropzone',
            replaceElement: true,
        },
    },
    onRender: function(){
        this.showChildView('dropzone', new ChargeDropZone({model: this.model}))
    }
})

const ListBase = M.CollectionView.extend({
    className: 'list-group',
    viewComparator: function(view){
        return view.model.get('NomEnseignant') + view.model.get('Prenom')
    }
})

const ListActives = ListBase.extend({
    childView: EnseignantListItemActifs
});

const EnseignantListItemInactifs = EnseignantListItemBase.extend({
    className: 'list-group-item list-enseignant-item inactif',
})



const ListInactives = ListBase.extend({
    childView: EnseignantListItemInactifs,
});

const EnseignantListItemNoaccess = EnseignantListItemBase.extend({
    className: 'list-group-item list-enseignant-item inactif noaccess',
})

const ListNoaccess = ListBase.extend({
    childView: EnseignantListItemNoaccess,
});

const ListHeader = M.View.extend({
    template: _.template(`<h4>Collègue(s) <small><%=competence != null ? ' de la compétence ' + competence.CodeCompetence : '' %></small></h4>`),
    serializeData: function(){
        return {
            'competence': gestionChargeController.get('selectedCompetence') ? gestionChargeController.get('selectedCompetence').toJSON() : null
        }
    },
    initialize: function(){
        this.listenTo(gestionChargeController, 'change:selectedCompetence', _.bind(this.render, this))
    }
})

export const ListeEnseignants = M.View.extend({
    template: _.template('<div class="liste-enseignants-header"></div><div class="liste-enseignants-actifs"></div><div class="liste-enseignants-inactifs"></div><div class="liste-enseignants-noaccess"></div>'),
    regions: {
        'header':'.liste-enseignants-header',
        'actifs':'.liste-enseignants-actifs',
        'inactifs':'.liste-enseignants-inactifs',
        'noaccess':'.liste-enseignants-noaccess'
    },
    actives: null,
    inactives: null,
    initialize: function(){
        this.actives = new Backbone.Collection();  
        this.inactives = new Backbone.Collection();
        this.noaccess = new Backbone.Collection();
        this.hydrateLists();
        this.listenTo(gestionChargeController, 'change:selectedCompetence', _.bind(this.onselectedCompetenceChanged, this));
    },
    onselectedCompetenceChanged: function(){
        this.hydrateLists();
        //this.render();
    },
    onRender: function(){
        this.showChildView('header', new ListHeader())
        this.showChildView('actifs', new ListActives({collection: this.actives}))
        this.showChildView('inactifs', new ListInactives({collection: this.inactives}))
        this.showChildView('noaccess', new ListNoaccess({collection: this.noaccess}))
    },
    hydrateLists: function(){
        const selectedCompetence = gestionChargeController.get('selectedCompetence') ? gestionChargeController.get('selectedCompetence').toJSON() : null
        if(!selectedCompetence){
            this.actives.reset()
            this.inactives.reset(_.map(gestionChargeController.get('enseignants').filter(item => item.get('active') == true), item => {return {
                ...item.toJSON(), 
                listecompens: null,
                selectedCompetence: selectedCompetence}}));
            this.noaccess.reset(_.map(gestionChargeController.get('enseignants').filter(item => item.get('active') != true), item => {return {
                ...item.toJSON(), 
                listecompens: null,
                selectedCompetence: selectedCompetence}}));
        }else{
            this.actives.reset();
            this.inactives.reset();
            gestionChargeController.get('enseignants').forEach(item => {
                const t = gestionChargeController.get('listecompens').find(p => p.get('compens').NoCompetence == selectedCompetence.id && p.get('compens').NoEnseignant == item.get('NoEnseignant'))
                if(t){
                    this.actives.add({
                        ...item.toJSON(), 
                        listecompens: t.toJSON(),
                        selectedCompetence: selectedCompetence
                    });
                }else{
                    if(item.get('active')){
                        this.inactives.add({
                            ...item.toJSON(), 
                            listecompens: null,
                            selectedCompetence: selectedCompetence
                        })
                    }else{
                        this.noaccess.add({
                            ...item.toJSON(), 
                            listecompens: null,
                            selectedCompetence: selectedCompetence
                        })
                    }
                }
            })
        }
    }
})