import M from 'backbone.marionette'
import T from './fpcellvalue.html'

export const FPCellValue = M.View.extend({
    template: _.template(T),
    /*triggers: {
        'click >.btn':'show:competence:statut',
    },*/
    serializeData: function(){
        return {
            value: this.model.get(this.getOption('attribute')),
            caption: this.model.get(this.getOption('attribute')),
            NoProgramme: this.getOption('controller').get('NoProgramme'),
            NoCompetence: this.model.get('NoCompetence'),
            Statut: encodeURIComponent(this.getOption('attribute')),
        }
    }
})

export const FPEVFPCellValue = M.View.extend({
    template: _.template(T),
    /*triggers: {
        'click >.btn':'show:competence:statut',
    },*/
    serializeData: function(){
        return {
            value: this.model.get('totevfp'),
            caption: this.model.get('totevfp') + ' (' + this.model.get('EV') + '/'+this.model.get('FP') + '/' + this.model.get('EVFP') +')',
            NoProgramme: this.getOption('controller').get('NoProgramme'),
            NoCompetence: this.model.get('NoCompetence'),
            Statut: this.getOption('attribute'),
        }
    }
})