import M from 'backbone.marionette'
import { Loading } from '../../utils/bootstrap/loading';
import T from './cours.html'
import { FormElement } from '../../utils/bootstrap/formelement';
import { CompetencesAssociees } from './competencesassociees';
import { FormHandler } from '../../utils/bootstrap/form/formhandler';
import { Toast } from '../../utils/bootstrap/toast';
import { RacAPI } from '../dataapi';
import { ProgrammesAssocies } from './programmesassocies';

const CoursContainer = M.View.extend({
    template: _.template(T),
    childViewTriggers: {
        'supprimer:competence':'supprimer:competence',
        'associer:competence':'associer:competence',
        'supprimer:programme':'supprimer:programme',
        'associer:programme':'associer:programme'
    },
    triggers: {
        'click .btSauvegarder':'sauvegarder'
    },
    regions: {
        'CodeCours':'.CodeCours',
        'NomCours':'.NomCours',
        'Duree':'.Duree',
        'listecompetences':'.listecompetences',
        'listecours':'.listecours'
    },
    onRender: function(){
        this.showChildView('CodeCours', new FormElement({
            type: 'text',
            model: this.model,
            attribute: 'CodeCours',
            label : 'Code du cours',
        }))
        this.showChildView('NomCours', new FormElement({
            type: 'text',
            model: this.model,
            attribute: 'NomCours',
            label : 'Nom du cours',
        }))
        this.showChildView('Duree', new FormElement({
            type: 'number',
            model: this.model,
            attribute: 'Duree',
            label : 'Durée en heure',
        }))
        this.showChildView('listecompetences', new CompetencesAssociees({
            collection: this.getOption('listecompetences')
        }))
        this.showChildView('listecours', new ProgrammesAssocies({
            collection: this.getOption('listecours')
        }))
        
    },
    onSauvegarder: function(){
        const formHandler = new FormHandler();
        formHandler.setModel(this.model);
        formHandler.save(
            'api/cours/save',
            (response) => {
                this.model.set(response);
                this.render();
                Toast.add('Le cours ' + this.model.get('NomCours') + 'a été modifié avec succès','success');
            },
            (err) => {
                Toast.add('Impossible de sauvegarder', 'danger')
            }
        )
    },
    onSupprimerCompetence: function(view){
        const model = view.model;
       
        if(confirm('Êtes-vous certain de vouloir dissocier la compétance ' + model.get('NomCompetence') + ' de ce cours ?')){
            $.ajax({
                url: 'api/cours/supprimer_competence',
                data: {
                    NoListeCompetences: model.get('NoListeCompetences')
                },
                type: 'post',
                success: (response) => {
                    this.getOption('listecompetences').remove(model);
                    Toast.add('La compétence a été dissociée avec succès', 'success');
                    view.triggerMethod('reset');
                },
                error: (xhr) => {
                    RacAPI.handleError(xhr)
                }
            })
        }
    },
    onAssocierCompetence: function(view){
        $.ajax({
            url: 'api/cours/associer_competence',
            data: {
                NoCours: this.model.get('NoCours'),
                NoCompetence: view.model.get('newNoCompetence')
            },
            type: 'post',
            success: (response) => {
                this.getOption('listecompetences').add(response);
                Toast.add('La compétence a été associée avec succès', 'success');
                view.triggerMethod('reset');
            },
            error: (xhr) => {
                RacAPI.handleError(xhr)
            }
        })
    },
    onSupprimerProgramme: function(view){
        const model = view.model;
        
        if(confirm('Êtes-vous certain de vouloir dissocier ce programme ?')){
            $.ajax({
                url: 'api/cours/supprimer_programme',
                data: {
                    NoListeCours: model.get('NoListeCours')
                },
                type: 'post',
                success: () => {
                    this.getOption('listecours').remove(model);
                    Toast.add('Le programme a été dissocié avec succès', 'success');
                    view.triggerMethod('reset');
                },
                error: (xhr) => {
                    RacAPI.handleError(xhr)
                }
            })
        }
    },
    onAssocierProgramme: function(view){
        $.ajax({
            url: 'api/cours/associer_programme',
            data: {
                NoCours: this.model.get('NoCours'),
                NoProgramme: view.model.get('newNoProgramme')
            },
            type: 'post',
            success: (response) => {
                this.getOption('listecours').add(response);
                Toast.add('Le programme a été associé avec succès', 'success');
                view.triggerMethod('reset');
            },
            error: (xhr) => {
                RacAPI.handleError(xhr)
            }
        })
    }
});


// a loader class
export const CoursView = M.View.extend({
    template: _.template('<div></div>'),
    modelEvents: {
        'change:loaded':'render',
    },
    regions: {
        'content':{
            el: '>div',
            replaceElement: true
         }
    },
    listecompetences: null,
    listecours: null,
    initialize: function(){
        this.model = new Backbone.Model({
            loaded: false,
            NoCours: this.getOption('noCours')
        })
        this.listecompetences = new Backbone.Collection();
        this.listecours = new Backbone.Collection();
        this.load();
    },
    load: function(){
        $.ajax({
            url: 'api/cours/data/'+ this.model.get('NoCours'),
            success: (response) => {
                this.listecompetences.reset(response.listecompetences);
                this.listecours.reset(response.listecours);
                this.model.set(response.cours);
                this.model.set('loaded', true);
            }
        })
    },
    onRender: function(){
        if(this.model.get('loaded')){
            this.showChildView('content', new CoursContainer({
                model: this.model,
                listecompetences: this.listecompetences,
                listecours: this.listecours
            }))
        }else{
            this.showChildView('content', new Loading());
        }
    }
})