import M from 'backbone.marionette'
import T from './coursassocies.html'
import TItem from './coursassociesitem.html'
import { FormElement } from '../../utils/bootstrap/formelement'

const Item = M.View.extend({
    tagName: 'tr',
    triggers: {
        'click .btSupprimer':'supprimer:cours',
    },
    template: _.template(TItem),
    
})

const List = M.CollectionView.extend({
    tagName: 'tbody',
    childView: Item,
    childViewTriggers: {
        'supprimer:cours':'supprimer:cours',
    },
})

export const CoursAssocies = M.View.extend({
    template: _.template(T),
    childViewTriggers: {
        'supprimer:cours':'supprimer:cours',
    },
    triggers: {
        'click .btAssocier':'associer:cours',
    },
    regions: {
        'list':{
            el: '.list',
            replaceElement: true,
        },
        'newNoCours':'.newNoCours'
    },
    initialize: function(){
        this.model = new Backbone.Model({
            newNoProgramme: null,
        })
    },
    onDestroy: function(){
        this.model = null;
    },
    onReset: function(){
        this.model.set('newNoCours', null);
        this.showAutocomplete();
    },
    onRender: function(){
        this.showChildView('list', new List({
            collection: this.getOption('collection'),
        }))
        this.showAutocomplete();
    },
    showAutocomplete: function(){
        this.showChildView('newNoCours', new FormElement({
            type: 'indexeurautocomplete',
            indexType: 'cours',
            model: this.model,
            attribute: 'newNoCours',
            hideLabel: true,
            serviceUrl: 'autocomplete/search/cours',
            inputAttributes: {
                placeholder: 'Rechercher un cours...'
            }
        }))
    }
})