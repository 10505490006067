import M from 'backbone.marionette'
import T from './coursinscrits.html'
import moment, { relativeTimeThreshold } from 'moment';
import { FormElement } from '../../../utils/bootstrap/formelement';
import { Toast } from '../../../utils/bootstrap/toast'
import { FormHandler } from '../../../utils/bootstrap/form/formhandler';
import { AjouterCours } from './ajoutercours';

const CoursItem = M.View.extend({
    tagName:'tr',
    template: _.template('<td><%=cours.CodeCours%></td><td><%=cours.NomCours%></td><td><%=DateInscription%></td><td><%=cours.Duree%> H</td><td><button class="btn btn-danger btn-sx btnRetirer">Retirer</button></td>'),
    triggers: {
        'click .btnRetirer':'retirer:cours'
    }
})
const CoursList = M.CollectionView.extend({
    template: _.template(''),
    tagName: 'tbody',
    childView: CoursItem,
    childViewTriggers: {
        'retirer:cours':'retirer:cours'
    }
});

export const CoursInscritsView = M.View.extend({
    template: _.template(T),
    id: 'etudiantPartial',
    regions: {
        'content':{
            el:'tbody.content',
            replaceElement: true
        },
        'ajouterCours':'.ajouterCours'
    },
    
    childViewTriggers: {
        'retirer:cours':'retirer:cours',
        'ajouter:cours':'ajouter:cours',
    },
    collection: null,
    newCours: null,
    initialize: function(){
        this.collection = new Backbone.Collection();
        this.collection.add(this.getOption('controller').get('inscriptionc').reduce((prev,item) => {
            if(item.get('cours')){
                prev.push(_.extend(item.get('inscriptionc'), {id: item.get('id'), cours: item.get('cours')}));
            }
            return prev
        }, []));
        this.newCours = new Backbone.Model()
    },
    onDestroy: function(){
        this.collection = null;
    },
    onRender: function(){
        this.showChildView('content', new CoursList({collection: this.collection}))
        this.renderAjouterCours();
    },
    renderAjouterCours: function(){
        this.showChildView('ajouterCours', new AjouterCours({
            controller: this.getOption('controller'),
            collection: this.collection
        }))
    },
    onAjouterCours: function(view){
        const formHandler = new FormHandler();
        formHandler.setModel(view.model);
        formHandler.save('api/etudiants/ajouterinscriptionc',
            (response) => {
                this.getOption('controller').get('inscriptionc').add(response.inscription);
                this.collection.add(_.extend(response.inscription.inscriptionc, {cours: response.inscription.cours}));
                Toast.add('Cours « ' + response.inscription.cours.NomCours + ' » ajouté avec succès !','success');
                if(response.paiement){
                    this.getOption('controller').get('paiement').add(response.paiement)
                }
                this.renderAjouterCours();
            },
            (errors) => {
                if(errors){
                    Toast.add(errors.join('<br />'), 'danger');
                }else{
                    RacAPI.handleError(errors);
                }
            }
        )
    },
    onRetirerCours: function(view){
        if(confirm('Voulez vous vraiment retirer ce cours ?')){
            const id = view.model.get('NoInscriptionC');
            $.ajax({
                url: 'api/etudiants/retirerinscriptionc/'+id,
                type: 'post',
                success: () => {
                    this.getOption('controller').get('inscriptionc').remove(id);
                    this.collection.remove(id);
                    Toast.add('Cours retiré avec success !', 'success');
                },
                error: () => {
                    Toast.add('Le cours ne peut être retiré', 'danger');
                }
            })
        }
    }
})