import M from 'backbone.marionette'
import T from './programmeliste.html'
import TItem from './programmelisteitem.html'
import { FPCellValue, FPEVFPCellValue } from './fpcellvalue';

const HeadingSorter = M.View.extend({
    template: _.template('<span class="glyphicon" aria-hidden="true"></span>'),
    tagName: 'button',
    className: 'btn btn-xs',
    modelEvents: {
        'change:orderBy': 'updateState',
        'change:orderAsc':'updateState'
    },
    ui: {
        'glyphicon':'.glyphicon',
    },
    triggers: {
        'click':'order:by',
    },
    onRender: function(){
        this.updateState();
    },
    updateState: function(){
        if(this.model.get('orderBy') == this.getOption('attribute')){
            this.$el.addClass('btn-primary').removeClass('btn-default');
            if(this.model.get('orderAsc')){
                this.ui.glyphicon.addClass('glyphicon-sort-by-order').removeClass('glyphicon-sort-by-order-alt');
            }else{
                this.ui.glyphicon.addClass('glyphicon-sort-by-order-alt').removeClass('glyphicon-sort-by-order');
            }
        }else{
            this.$el.addClass('btn-default').removeClass('btn-primary');
            this.ui.glyphicon.addClass('glyphicon-sort-by-order').removeClass('glyphicon-sort-by-order-alt');
        }
    }
})

const ProgrammeListeItem = M.View.extend({
    tagName: 'tr',
    template: _.template(TItem),
    childViewTriggers: {
        'show:competence:statut':'show:competence:statut'
    },
    regions: {
        'AS':'.AS',
        'EQ':'.EQ',
        'EQC':'.EQC',
        'EV':'.EV',
        'FP':'.FP',
        'EVFP':'.EVFP',
        'FC':'.FC',
        'R':'.R',
        'SU':'.SU',
        'OA':'.OA'
    },
    onRender: function(){
        this.showChildView('AS', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'AS',
        }));
        this.showChildView('EQ', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'EQ',
        }));
        this.showChildView('EQC', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'EQC',
        }));
        this.showChildView('EV', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'EV',
        }));
        this.showChildView('FP', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'FP',
        }));
        this.showChildView('EVFP', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'EVFP',
        }));
        this.showChildView('FC', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'FC',
        }));
        this.showChildView('R', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'R',
        }));
        this.showChildView('SU', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'SU',
        }));
        this.showChildView('OA', new FPCellValue({
            model: this.model,
            controller: this.getOption('controller'),
            attribute: 'OA',
        }));
    }
});

const List = M.CollectionView.extend({
    tagName: 'tbody',
    childViewTriggers: {
        'show:competence:statut':'show:competence:statut'
    },
    childViewOptions: function(){
        return {controller: this.model}
    },
    childView: ProgrammeListeItem,
    onChangeOrder: function(view){
        
        if(this.model.get('orderBy') == view.getOption('attribute')){
            this.model.set('orderAsc', !this.model.get('orderAsc'));
        }else{
            this.model.set({
                'orderBy': view.getOption('attribute'),
                'orderAsc': false
            });
        }
        this.removeComparator();
        if(this.model.get('orderAsc')){
            this.setComparator(this.model.get('orderBy'));
        }else{
            this.setComparator((view) => {
                return view.model.get(this.model.get('orderBy')) * -1
            })
        }
        
    }
})

export const ProgrammeListeView = M.View.extend({
    template: _.template(T),
    childViewTriggers: {
        'order:by':'order:by',
        'show:competence:statut':'show:competence:statut',
    },
    regions: {
        'tbody':{
            el: 'tbody.liste',
            replaceElement: true,
        },
        'filtreCODE':'.filtreCODE',
        'filtreAS':'.filtreAS',
        'filtreEQ':'.filtreEQ',
        'filtreEQC':'.filtreEQC',
        'filtreFC':'.filtreFC',
        'filtreEV':'.filtreEV',
        'filtreFP':'.filtreFP',
        'filtreEVFP':'.filtreEVFP',
        'filtreREUSSI':'.filtreREUSSI',
        'filtreSU':'.filtreSU',
        'filtreOA':'.filtreOA',
    },
    
    onRender: function(){
        this.showChildView('tbody', new List({
            model: this.model,
            collection: this.getOption('collection')
        }))
        this.showChildView('filtreCODE', new HeadingSorter({
            model: this.model,
            attribute: 'CodeCompetence',
        }));
        this.showChildView('filtreAS', new HeadingSorter({
            model: this.model,
            attribute: 'AS',
        }));
        this.showChildView('filtreEQ', new HeadingSorter({
            model: this.model,
            attribute: 'EQ',
        }));
        this.showChildView('filtreEQC', new HeadingSorter({
            model: this.model,
            attribute: 'EQC',
        }));
        this.showChildView('filtreFC', new HeadingSorter({
            model: this.model,
            attribute: 'FC',
        }));
        this.showChildView('filtreEV', new HeadingSorter({
            model: this.model,
            attribute: 'EV',
        }));
        this.showChildView('filtreFP', new HeadingSorter({
            model: this.model,
            attribute: 'FP',
        }));
        this.showChildView('filtreEVFP', new HeadingSorter({
            model: this.model,
            attribute: 'EVFP',
        }));
        this.showChildView('filtreREUSSI', new HeadingSorter({
            model: this.model,
            attribute: 'R',
        }));
        this.showChildView('filtreSU', new HeadingSorter({
            model: this.model,
            attribute: 'SU',
        }));
        this.showChildView('filtreOA', new HeadingSorter({
            model: this.model,
            attribute: 'OA',
        }));
    },
    onOrderBy: function(view){
        
        this.getChildView('tbody').triggerMethod('change:order', view)
    }
})