import M from 'backbone.marionette'
import TFactureItem from './factureitem.html'

const FactureItem = M.View.extend({
    tagName: 'span',
    template:_.template(TFactureItem),
    serializeData: function(){
        return _.extend(this.model.toJSON(), {etudiant: this.getOption('etudiant')});
    }
})

export const PaiementFactures = M.CollectionView.extend({
    tagName: 'span',
    template: _.template(''),
    initialize: function(){
        this.collection = new Backbone.Collection();
        this.updateCollection();
    },
    childViewOptions: function(){
        return {
            etudiant:  this.getOption('etudiant'),
        }
    },
    onDetroy: function(){
        this.collection = null;
    },
    updateCollection: function(){
        this.collection.reset(
            this.model.get('receipes') ?
            JSON.parse(this.model.get('receipes')) :
            []
        );
    },
    childView: FactureItem
})