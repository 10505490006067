import Backbone from 'backbone';
import T from './newdiscussionitem.html'
export const NewDiscussionItem = M.View.extend({
    template: _.template(T),
    ui: {
        'textarea':'textarea#content',
        'bt':'.btn-envoyer'
    },
    triggers: {
        'change @ui.textarea':'content:changed',
        'keyup @ui.textarea':'content:changed',
        'click @ui.bt':'submit:message', // géré par le parent (voir discussions::onChildviewSubmitMessage)
    },
    modelEvents: {
        'change:content':'onContentModelChanged',
        'change:submitting':'updateUI'
    },
    onRender: function(){
        this.updateUI();
    },
    onContentChanged: function(){
        this.model.set({
            'content':this.ui.textarea.val()
        }, {silent: true});
        this.updateUI();
    },
    onContentModelChanged: function(){
        this.ui.textarea.val(this.model.get('content'))
        this.updateUI();
    },
    updateUI: function(){
        if(this.model.get('content') && this.model.get('submitting')!== true){
            this.ui.bt.prop('disabled', false);
        }else{
            this.ui.bt.prop('disabled', true);
        }
    },
})