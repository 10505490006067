import M from 'backbone.marionette'
import T from './specialiste.html'
import { Loading } from '../../utils/bootstrap/loading';
import { FormElement } from '../../utils/bootstrap/formelement';
import { FormHandler } from '../../utils/bootstrap/form/formhandler';
import { Toast } from '../../utils/bootstrap/toast';
import { ListeCompEns } from './listecompens';
import { SpecialisteAcces } from './access';
import Backbone from 'backbone';

const Specialiste = M.View.extend({
    template: _.template(T),
    regions: {
        'listecompens':{
            el: '.listecompens',
            replaceElement: true
        },
        'nouvelleCompetence':'.nouvelleCompetence',
    },
    childViewTriggers : {
        'supprimer:compens':'supprimer:compens',
    },
    events: {
        'click .btEnregistrer':'onEnregistrerClick',
        'click .btAjouter':'onAjouterClick'
    },
    initialize: function(){
        this.model = new Backbone.Model({
            NoCompetence: null
        })
    },
    onDestroy: function(){
        this.model = null;
    },
    onRender: function(){
        
        this.showChildView('listecompens', new ListeCompEns({
            collection: this.getOption('controller').get('listecompens')
        }));
        this.showNouvelleCompetence();
        
        
    },
    showNouvelleCompetence: function(){
        this.showChildView('nouvelleCompetence', new FormElement({
            type: 'indexeurautocomplete',
            model: this.model,
            attribute: 'NoCompetence',
            indexType : 'competence',
            hideLabel: true,
            serviceUrl: 'autocomplete/search/competence',
            inputAttributes : {
                placeholder: 'Rechercher une compétence...'
            }
        }))
    },
    onEnregistrerClick: function(event){
        const formHandler = new FormHandler();
        formHandler.setModel(this.getOption('controller').get('enseignant'));
        formHandler.save(
            'api/specialiste/save',
            () => {
                Toast.add('Les informations du spécialiste ont été sauvegardées avec succès !', 'success')
            },
            () => {
                Toast.add('Erreur lors de la sauvegarder des informations du spécialiste', 'danger')
            }
        )
    },
    onSupprimerCompens: function(view){
        const model = view.model;
        if(confirm('Voulez-vous vraiment reliter la compétence ' + model.get('competence').NomCompetence + ' ?')){
            $.ajax({
                url: 'api/specialiste/supprimercompetence',
                method: 'post',
                data: {
                    NoCompEns: model.get('compens').NoCompEns
                },
                success: () => {
                    this.getOption('controller').get('listecompens').remove(model.get('id'));
                    Toast.add('La compétence ' + model.get('competence').NomCompetence + ' a été retirée avec succès.','success')
                },
                error: () => {
                    Toast.add('Impossible de retirer la compétence ' + model.get('competence').NomCompetence, 'danger');
                }
            })
        }
    },
    onAjouterClick: function(event){
        $.ajax({
            url : 'api/specialiste/ajoutercompetence',
            type: 'post',
            data: {
                NoCompetence: this.model.get('NoCompetence'),
                NoEnseignant: this.getOption('controller').get('enseignant').get('NoEnseignant')
            },
            success: (response) => {
                this.getOption('controller').get('listecompens').add(response);
                this.model.set('NoCompetence', null);
                this.showNouvelleCompetence();
                Toast.add('Compétence ajoutée avec succès !', 'success');
            },
            error: (xhr) => {
                if('responseJSON' in xhr && _.isObject(xhr.responseJSON)){
                    _.forEach((xhr.responseJSON), (item) => {
                        if(_.isString(item)){
                            Toast.add(item, 'danger');
                        }
                    })
                }else{
                    Toast.add('Une erreur a eu lieu. Impossible d\'ajouter cette compétence', 'danger');
                }
            }
        })
    }
})

export const SpecialisteView = M.View.extend({
    template: _.template('<div></div>'),
    regions: {
        'content': '>div'
    },
    modelEvents: {
        'change:loaded':'render',
    },
    initialize: function(){
        
        this.model = new Backbone.Model({
            loaded: false,
            enseignant: new Backbone.Model(),
            utilisateur: new Backbone.Model(),
            listecompens: new Backbone.Collection(),
            invitations: new Backbone.Collection()
        });
        $.ajax({
            url : 'api/specialiste/data/'+this.getOption('noEnseignant'),
            cache: false,
            success: (response) => {
                this.model.get('enseignant').set(response.enseignant);
                this.model.get('listecompens').reset(response.listecompens);
                if(response.utilisateur)this.model.get('utilisateur').set(response.utilisateur);
                else this.model.set('utilisateur', null);
                this.model.set('loaded', true);
                this.model.get('invitations').reset(response.invitations)
                this.render()
            },
            error: () => {
                this.showChildView('content', new M.View({template: _.template('<h2 class="bg-danger">Une erreur de chargement a eu lieu</h2>')}))
            }
        })
    },
    onRender: function(){
        if(!this.model.get('loaded')){
            this.showChildView('content', new Loading());
        }else{
            this.showChildView('content', new Specialiste({
                controller: this.model,
            }))
        }
    }
})