import M from 'backbone.marionette'
import T from './programmesassocies.html'
import TItem from './programmeassociesitem.html'
import { FormElement } from '../../utils/bootstrap/formelement'

const Item = M.View.extend({
    tagName: 'tr',
    triggers: {
        'click .btSupprimer':'supprimer:programme',
    },
    template: _.template(TItem),
    
})

const List = M.CollectionView.extend({
    tagName: 'tbody',
    childView: Item,
    childViewTriggers: {
        'supprimer:programme':'supprimer:programme',
    },
})

export const ProgrammesAssocies = M.View.extend({
    template: _.template(T),
    childViewTriggers: {
        'supprimer:programme':'supprimer:programme',
    },
    triggers: {
        'click .btAssocier':'associer:programme',
    },
    regions: {
        'list':{
            el: '.list',
            replaceElement: true,
        },
        'newNoProgramme':'.newNoProgramme'
    },
    initialize: function(){
        this.model = new Backbone.Model({
            newNoProgramme: null,
        })
    },
    onDestroy: function(){
        this.model = null;
    },
    onReset: function(){
        this.model.set('newNoProgramme', null);
        this.showAutocomplete();
    },
    onRender: function(){
        this.showChildView('list', new List({
            collection: this.getOption('collection'),
        }))
        this.showAutocomplete();
    },
    showAutocomplete: function(){
        this.showChildView('newNoProgramme', new FormElement({
            type: 'indexeurautocomplete',
            indexType: 'programme',
            model: this.model,
            attribute: 'newNoProgramme',
            hideLabel: true,
            serviceUrl: 'autocomplete/search/programme',
            inputAttributes: {
                placeholder: 'Rechercher un programme...'
            }
        }))
    }
})