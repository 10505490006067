import M from 'backbone.marionette'
import T from './courscompletes.html'
import TItem from './courscompletesitem.html'
import { complete_competences_status } from '../config'

const CoursInscritsItem = M.View.extend({
    tagName:'tr',
    template: _.template(TItem)
})

const CoursInscritsList = M.CollectionView.extend({
    tagName:'tbody',
    template: _.template(''),
    childView: CoursInscritsItem
})

export const CoursCompletesView = M.View.extend({
    template: _.template(T),
    id: 'etudiantPartial',
    regions: {
        'tbody':{
            el: 'tbody',
            replaceElement: true
        },
    },
    collection: null,
    initialize: function(){
        this.collection = new Backbone.Collection();
        this.getOption('controller').get('programmecours').forEach((item) => {
            var values = _.extend(item.toJSON(), {total: 0, complete: 0});
             
            _.reduce(this.getOption('controller').get('listecompetences').where({NoCours: item.get('NoCours')}), (prev, curr) => {
                    
                    
                    var competencesTrouvees = this.getOption('controller').get('competenceetudiant').where({NoCompetence: curr.get('NoCompetence')})
                    if(competencesTrouvees){
                        _.forEach(competencesTrouvees, (compEtud) => {
                            prev.total++;
                            if(competencesTrouvees.length && _.includes(complete_competences_status, compEtud.get('Statut')))prev.complete++;
                        })
                    }
                          
                    return prev;
                }, values);

            if(values.total==0){
                values.statut = 'En cours';
            }else{
                if(values.total==values.complete){
                    values.statut = 'Terminé';
                }else{
                    values.statut = 'En cours';
                }
            }

            this.collection.add(values);
                
        })
        
    },
    onRender: function(){
        this.showChildView('tbody', new CoursInscritsList({
            collection: this.collection,
            viewComparator: 'total',
            viewFilter: function(view){
                return view.model.get('total')>0
            }
        }))
    }
})