import M from 'backbone.marionette'
import { FormInput } from './form/forminput';
import { FormCheckbox } from './form/formcheckbox';
import { DatePickerView } from './form/datepicker';
import { FormSelect} from './form/formselect';
import { FormIndexeurAutocomplete } from './form/formindexeurautocomplete';
import { FormTextarea } from './form/formtextarea';

const FormLabel = M.View.extend({
    template: _.template('<%=val%>'),
    tagName: 'label',
    serializeData: function(){
        return { val : this.getOption('label') }
    },
    attributes: function(){
        return { 
            for: this.getOption('prefix') + this.getOption('attribute') + this.getOption('suffix'),
            class: this.getOption('horizontal') ? 'col-sm-4 control-label' : 'control-label'
        };
    }
})

export const FormElement = M.View.extend({
    getTemplate: function(){
        if(this.getOption('horizontal')){
            if(this.getOption('type')=='checkbox'){
                return _.template('<div class="label"></div><div class="col-sm-8 col-sm-offset-4"><div class="input"></div><div class="help-block" style="display: none"></div></div>')
            }else{
                return _.template('<div class="label"></div><div class="col-sm-8"><div class="input"></div><div class="help-block" style="display: none"></div></div>')
            }
        }else{
            return _.template('<div class="label"></div><div class="input"></div><div class="help-block" style="display: none"></div>')
        }
    },
    modelEvents: {
        'error':'onError',
        'inputValueChanged':'inputValueChanged',
    },
    regions: {
        'label': {
            el: '>.label',
            replaceElement: true
        },
        'input' : {
            el: '.input',
            replaceElement: true
        },
    },
    ui: {
        'help': '.help-block',
    },
    initialize: function(){
        if(this.getOption('hideLabel')!=true){
            this.$el.addClass('form-group');
            if(this.getOption('inline'))this.$el.addClass('form-inline');
        }
    },
    onError: function(errors){
        if(errors && this.getOption('attribute') in errors){
            this.$el.addClass('has-error');
            this.ui.help.html(errors[this.getOption('attribute')]);
            this.ui.help.show();
        }else{
            this.$el.removeClass('has-error');
            this.ui.help.html('');
            this.ui.help.hide();
        }
    },
    inputValueChanged: function(attribute, newValue){
        if(attribute!=this.getOption('attribute'))return;
        if(newValue!=this.model.get(this.getOption('attribute'))){
            this.$el.addClass('has-warning');
        }else{
            this.$el.removeClass('has-warning');
        }
    },
    onAttach: function(){
        var form = this.$el.closest('form');
        if(form.length && 'bootstrapped' in form[0] == false){
            form[0].bootstrapped = true;
            $(form[0]).on('reset', function(event){
                _.forEach(this.elements, (el) => {
                    $(el).change();
                });
            });
        }
    },
    onRender: function(){

        var options = _.clone(this.options);
        delete options.el;
        if('attributes' in options)delete options.attributes;
        if('inputAttibutes' in options){
            options.attributes = options.inputAttibutes;
            delete options.attributes;
        }
        
        switch(this.getOption('type')){
            case 'indexeurautocomplete':
                this.showLabel();
                this.showChildView('input', new FormIndexeurAutocomplete(options));
                break;
            case 'select':
                this.showLabel();
                this.showChildView('input', new FormSelect(options));
                break;
            case 'datepicker':
                this.showLabel();
                this.showChildView('input', new DatePickerView(options));
                break;
            case 'checkbox':
                this.$el.removeClass('form-group');
                this.$el.addClass('checkbox');
                this.showChildView('input', new FormCheckbox(options));
                break;
            case 'hidden':
                this.showChildView('input', new FormInput(options));
                break;
            case 'number':
                this.showLabel();
                options.parseValue = function(p){ return parseFloat(p)};
                this.showChildView('input', new FormInput(options));
                break;
            case 'textarea':
                this.showLabel();
                this.showChildView('input', new FormTextarea(options));
                break;
            case 'text':
            case 'email':
            default:
                this.showLabel();
                this.showChildView('input', new FormInput(options));
                break;
        }
    },
    showLabel: function(){
        if(this.getOption('hideLabel'))return;
        this.showChildView('label', new FormLabel({
            label: this.getOption('label'),
            suffix: this.getOption('suffix'),
            predix: this.getOption('prefix'),
            attribute: this.getOption('attribute'),
            horizontal: this.getOption('horizontal'),
            inline: this.getOption('inline')
        }));
    }
})