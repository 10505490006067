import M from 'backbone.marionette'
import { IndexeurAutocompleteView } from '../utils/autocomplete/indexeurautocomplete'

export const getSearchItemPill = function(item){
    let term = null
    switch(item.model){
        case 'etudiant':
            term = 'ETUD.';
            break;
        case 'enseignant':
            term = 'SPEC.';
            break;
        case 'competence':
            term = 'COMP';
            break;
        case 'cours':
            term = 'COURS';
            break;
        case 'programme':
            term = 'PROG.';
            break;
        case 'contributeurs':
            term = 'CONT.';
            break;
        case 'evals':
            term = 'EVAL.';
            break;
    }
    return term ? '<small><span class="label label-' + item.model + '">' + term + '</span></small> ' : '';
}
export const AccesRapide = M.View.extend({
    template:_.template('<div class="input-group"><input></input><span class="input-group-addon glyphicon glyphicon-search" id="basic-addon1"></span></div>'),
    regions:{
        'input':{
            el: 'input',
            replaceElement:true
        },
    },
    childViewEventPrefix: 'childview',
    initialize: function(){
        this.model = new Backbone.Model();
        this.$el.addClass('accesrapide');
    },
    onRender: function(){
        this.showChildView('input', new IndexeurAutocompleteView({
            attributes: {
                placeholder: 'Accès rapide ...'
            },
            model: this.model,
            serviceUrl: 'autocomplete/search',
            renderItem: (item, currentValue) => {
               
                const element = document.createElement("div");
                element.innerHTML = getSearchItemPill(item) + item.label;
                return element;
            }
        }))
    },
    onChildviewSelect: function(model_id, model_type){
        
        switch(model_type){
            case 'etudiant':
                window.location.href = 'candidat/fiche/'+model_id;
                break;
            case 'enseignant':
                window.location.href = 'specialiste/fiche/'+model_id;
                break;
            case 'competence':
                window.location.href = 'competences/info/'+model_id;
                break;
            case 'cours':
                window.location.href = 'cours/fiche/'+model_id;
                break;
            case 'programme':
                window.location.href = 'programme/fiche/'+model_id;
                break;
            case 'contributeurs':
                window.location.href = 'contributeur/fiche/'+model_id;
                break;
            case 'evals':
                window.location.href = 'evaluation/fiche/'+model_id;
                break;
        }
    }
})