import M from 'backbone.marionette'
import T from './toastcontainer.html'
import css from './toast.css'
import TItem from './toastitem.html'

const ToastItem = M.View.extend({
    template: _.template(TItem),
    className:'toastItem',
    hideInterval: null,
    modelEvents: {
        'change:show':'onShowChanged'
    },
    triggers: {
        'click .btnDismiss':'fade:out'
    },
    initialize: function(){
        if(this.getOption('autoHide'))this.hideInterval = setTimeout(_.bind(this.onFadeOut, this), 5000);
        this.$el.addClass('label-' + (this.model.get('type') ? this.model.get('type') : 'default'));
    },
    onFadeOut: function(){
        if(this.hideInterval){
            clearTimeout(this.hideInterval);
            this.hideInterval = null;
        }
        this.$el.fadeOut({
            complete: () => {
                this.model.set('show', false);
                this.triggerMethod('toast:hidden', this.model)
            }
        })
    },
    onShowChanged: function(){
        if(this.model.get('show')){
            this.$el.stop().css({opacity: 1}).show();
        }else{
            this.$el.stop().css({opacity: 1}).hide();
        }
    },
})
const ToastItems = M.CollectionView.extend({
    template: _.template(''),
    className:'toasts',
    childView: ToastItem,
    childViewTriggers: {
        'toast:hidden':'toast:hidden'
    },
    childViewOptions: {
        autoHide: true
    }
});
const ToastContainer = M.View.extend({
    template: _.template(T),
    className:'toastContainer',
    collection: null,
    ui: {
        'btnUp':'.btnUp',
        'btnDown':'.btnDown',
        'btnToast':'.btnToast',
        'toastHud':'.toastHud',
    },
    events:{
        'click @ui.btnToast':'onToastButonClick',
        'click @ui.btnUp':'onPreviewUpClick',
        'click @ui.btnDown':'onPreviewDownClick',
    },
    regions: {
        'toasts':{
            el: '.toasts',
            replaceElement: true,
        },
        'reviewToasts':{
            el: '.reviewToasts',
            replaceElement: false,
        }
    },
    modelEvents: {
        'change:currToastReviewIndex': 'updateCurrentToast',
    },
    childViewTriggers: {
        'toast:hidden':'toast:hidden'
    },
    initialize: function(){
        this.model = new Backbone.Model({
            currToastReviewIndex: null,
        });
        this.collection = new Backbone.Collection();
        this.listenTo(this.collection, 'change:show add remove', _.bind(this.onShowChanged, this));
    },
    onShowChanged: function(){
        if(this.collection.where({show: true}).length){
            this.ui.toastHud.hide();
        }else{
            this.ui.toastHud.show();
        }
    },
    onRender: function(){
        this.showChildView('toasts', new ToastItems({
            collection: this.collection
        }))
    },
    add: function(message, type, title){
        type = type ? type : 'success';
        this.collection.add({
            id: (this.collection.length+1),
            title: title ? title: null,
            message: message,
            type: type,
            show: true
        });
    },
    onToastButonClick: function(){
        this.model.set('currToastReviewIndex', this.collection.length ? this.collection.length-1 : null);
    },
    onToastHidden: function(model){
        if(this.model.get('currToastReviewIndex') !== null && model == this.collection.at(this.model.get('currToastReviewIndex'))){
            this.model.set('currToastReviewIndex', null);
        }
    },
    updateCurrentToast: function(){
        console.log('Curr index ',this.model.get('currToastReviewIndex'))
        if(this.model.get('currToastReviewIndex')!==null){
            this.showChildView('reviewToasts', new ToastItem({model: this.collection.at(this.model.get('currToastReviewIndex'))}))
            this.ui.btnDown.show();
            this.ui.btnUp.show();
            this.ui.btnDown.prop('disabled', this.model.get('currToastReviewIndex')==this.collection.length-1)
            this.ui.btnUp.prop('disabled', this.model.get('currToastReviewIndex')===0)
        }else{
            this.getRegion('reviewToasts').empty();
            this.ui.btnDown.hide();
            this.ui.btnUp.hide();
        }
    },
    onPreviewUpClick: function(){
        if(this.model.get('currToastReviewIndex')>0){
            this.model.set('currToastReviewIndex', this.model.get('currToastReviewIndex')-1);
        }
    },
    onPreviewDownClick: function(){
        if(this.model.get('currToastReviewIndex')<this.collection.length-1){
            this.model.set('currToastReviewIndex', this.model.get('currToastReviewIndex')+1);
        }
    }
});
const instance = {
    instance: null,
    add: function(message, type, title){
        if(this.instance===null){
            const div = $('<div style="position: fixed; bottom: 0; right: 0"></div>');
            $('body').append(div);
            this.instance = new ToastContainer({el: div}); 
            this.instance.render();
            $('head').append('<style>'+css+'</style>');
        }
        this.instance.add(message, type, title);
    }
}
export const Toast = instance;