import M from 'backbone.marionette'
import { FormControl } from './formcontrol';

const Checkbox = FormControl.extend({
    tagName: 'input',
    className:'',

    attributes: function(){
        return _.extend(FormControl.prototype.attributes.apply(this, arguments), { 
            value: "1",
            type: 'checkbox',
            name: this.getOption('attribute'),
            checked: _.isFunction(this.getOption('parseValue')) ? this.getOption('parseValue')(this.model.get(this.getOption('attribute'))) : this.model.get(this.getOption('attribute')) == '1'
        });
    },
    updateInputValue: function(){
        this.$el.prop('checked',_.isFunction(this.getOption('parseValue')) ? this.getOption('parseValue')(this.model.get(this.getOption('attribute'))) : this.model.get(this.getOption('attribute')) == '1')
    },
    onInputChanged: function(){
        
        this.model.set(this.getOption('attribute'), _.isFunction(this.getOption('prepValue')) ? this.getOption('prepValue')(this.$el.is(':checked')) : (this.$el.is(':checked') ? '1' : '0'));
    },
   
})
export const FormCheckbox = M.View.extend({
    tagName: 'label',
    template: _.template('<div class="checkbox"><input /> <%=label%></div>'),
    serializeData: function(){
        return { label: this.getOption('label') }
    },
    regions: {
        'input' : {
            el: 'input',
            replaceElement: true
        }
    },
    onRender: function(){
        this.showChildView('input', new Checkbox({ 
            model: this.model,
            attribute: this.getOption('attribute'),
            parseValue: this.getOption('parseValue'),
            prepValue: this.getOption('prepValue'),
        }))
    }
})