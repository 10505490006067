import M from 'backbone.marionette'
import { Toast } from '../utils/bootstrap/toast';

const dataapiclass = M.Object.extend({
    data: null,
    loading: null,
    initialize: function(){
        this.data = new Backbone.Collection();
        this.loading = new Backbone.Collection();
    },
    get: function(type, id, callback, errCallback){
        const loading = this.loading.get(type+':'+id);
        if(loading){
            loading.get('callbacks').push(callback);
            if(errCallback)loading.get('errorCB').push(errCallback);
            return;
        }
        const data = this.data.get(type+':'+id);
        if(data){
            if(data.get('model')) return callback(data.get('model'))
            else {
                if(errCallback)errCallback();
                return;
            }
        }
        // l'info n'est pas là, n'est pas en train d'être chargée
        const newDataModel = new Backbone.Model({
            id: type+':'+id,
            model: new Backbone.Model(),
            callbacks: [callback],
            errorCB: errCallback ? [errCallback] : []
        })
        this.loading.add(newDataModel)
        $.ajax({
            url: 'api/data/fetch/'+type+'/'+id,
            success: (response) => {
                this.loading.remove(newDataModel);
                newDataModel.get('model').set(response);
                this.data.add(newDataModel);
                _.forEach(newDataModel.get('callbacks'), (cb) => {
                    cb(newDataModel.get('model'))
                })
            },
            error: () =>{
                this.loading.remove(newDataModel);
                this.data.add(newDataModel);
                _.forEach(newDataModel.get('errorCB'), (cb) => {
                    cb(newDataModel.get('model'))
                })
            }
        })
    },
    handleError: function(xhr){
        if('status' in xhr && _.isNumber(xhr.status)){
            switch(xhr.status){
                case 500:
                    Toast.add('Erreur code 500', 'danger')
                    break;
                case 400:
                    if(_.isObject(xhr.responseJSON)){
                        if('errors' in xhr.responseJSON){
                            _.forEach(xhr.responseJSON.erros, (item, index) => {
                                if(_.isString(item) && item)Toast.add(item, 'danger');
                                return;
                            })
                        }else if('error' in xhr.responseJSON && _.isString(xhr.responseJSON.error) && xhr.responseJSON.error){
                            Toast.add(xhr.responseJSON.error, 'danger');
                            return;
                        }
                    }else if(_.isString(xhr.responseJSON) && xhr.responseJSON){
                        Toast.add(xhr.responseJSON, 'danger');
                        return;
                    }
                    break;
                case 404:
                    if(_.isString(xhr.responseJSON) && xhr.responseJSON){
                        Toast.add(xhr.responseJSON, 'danger');
                    }else{
                        Toast.add('Élément introuvable', 'danger');
                    }
                    return;
                    break;
            }
            Toast.add('Une erreur a eu lieu', 'danger');
        }
    }
});

export const RacAPI = new dataapiclass();