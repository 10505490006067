import M from 'backbone.marionette'
import { FormCollectionControl } from './formcontrol'

const OptionView = M.View.extend({
    template: _.template('<%=val%>'),
    tagName: 'option',
    attributes: function(){
        return { 
            value: this.model.get('value'),
            selected: this.model.get('value') == this.getOption('relatedModel').get(this.getOption('attribute')),
            disabled: this.model.get('disabled')===true
        }
    },
    serializeData: function(){
        return  { val: this.model.get('label') ? this.model.get('label'): this.model.get('value')}
    }
})

export const FormSelect = FormCollectionControl.extend({
    tagName: 'select',
    childView: OptionView,
    attributes: function(){
        return { 
            value: this.model.get(this.getOption('attribute')),
            name: this.getOption('attribute')
        }
    },
    childViewOptions: function(){
        return { 
            relatedModel: this.model,
            attribute: this.getOption('attribute')
        }
    },
})