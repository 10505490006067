import M from 'backbone.marionette'
import T from './access.html'
export const SpecialisteAcces = M.View.extend({
    template: _.template(T),
    serializeData: function(){
        
        var utilisateur = this.getOption('controller').get("utilisateur");
        return {
            utilisateur: utilisateur ? utilisateur.toJSON() : null,
            enseignant : this.getOption('controller').get("enseignant").toJSON(),
            invitations: this.getOption('controller').get("invitations").toJSON(),
        };
    },
    /*
    triggers: {
        'click .btRetirerAcces':'retirer:acces',
        'click .btDonnerAcces':'donner:acces',
    },
    */
    initialize: function(){
        this.listenTo(this.getOption('controller'), 'change:utilisateur', () => { this.render() });
    },
    /*
    onRetirerAcces: function(){

    },
    onDonnerAcces: function(){
        const v = new DonnerAcces({
            Courriel: this.getOption('controller').get('enseignant').get('Courriel')
        });
        showModal('Accès à la plateforme', v);
    }*/
})