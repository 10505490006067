import M from 'backbone.marionette'

const CompensItemView = M.View.extend({
    tagName: 'tr',
    triggers: {
        'click .btSupprimer':'supprimer:compens'
    },
    template: _.template('<td><%= competence.CodeCompetence %> - <%= competence.NomCompetence %></td><td><button class="btn btn-danger btSupprimer"><span class="glyphicon glyphicon-remove-sign" title="Supprimer la compétence"></span></button></td>')
})

export const ListeCompEns = M.CollectionView.extend({
    template: _.template(''),
    tagName: 'tbody',
    childViewTriggers : {
        'supprimer:compens':'supprimer:compens',
    },
    childView: CompensItemView,
})