import M from 'backbone.marionette'
import T from './candidatliste.html'
import TItem from './candidatlisteitem.html'
import TAutreItem from './candidatautrelisteitem.html'
import { FormElement } from '../../utils/bootstrap/formelement';
import { FormHandler } from '../../utils/bootstrap/form/formhandler';
import { RacAPI } from '../dataapi';
import { Toast } from '../../utils/bootstrap/toast';
import { competences_status } from '../config';

const CandidatListeBase = M.View.extend( {
    tagName: 'tr',
    editing: null,
    regions: {
        'InvitationEnvoyee':'.InvitationEnvoyee',
        'InvitationAEnvoyer':'.InvitationAEnvoyer'
    },
    serializeData: function(){
        return _.extend(
            this.model.toJSON(),
            {Statut: this.model.get('Statut') ? competences_status[this.model.get('Statut')] : null}
        )
    },
    ui: {
        'btModifier':'.btModifier',
        'btSauvegarder':'.btSauvegarder',
        'btAnnuler':'.btAnnuler',
        'check':'input.check',
        
    },
    triggers: {
        'click @ui.btModifier':'modifier',
        'click @ui.btSauvegarder':'sauvegarder',
        'click @ui.btAnnuler':'annuler',
        'change @ui.check':'email:selection',
        
    },
    modelEvents: {
        'change:selected':'onSelectedChanged'
    },
    editingModel: null,
    
    onRender: function(){

        if(this.editing==true){

            this.editingModel = _.clone(this.model);

            this.ui.btModifier.hide();
            this.ui.btSauvegarder.show();
            this.ui.btAnnuler.show();

            this.showChildView('InvitationEnvoyee', new FormElement({
                type: 'checkbox',
                attribute: 'InvitationEnvoyee',
                model: this.editingModel,
                hideLabel: true,
                parseValue: function(p){
                    return p=='1'
                },
                prepValue: function(p){
                    return p ? '1' : '0'
                }

            }))
            this.showChildView('InvitationAEnvoyer', new FormElement({
                type: 'checkbox',
                attribute: 'InvitationAEnvoyer',
                model: this.editingModel,
                hideLabel: true,
                parseValue: function(p){
                    return p=='1'
                },
                prepValue: function(p){
                    return p ? '1' : '0'
                }
            }))
        }else{
            this.ui.btModifier.show();
            this.ui.btSauvegarder.hide();
            this.ui.btAnnuler.hide();
        }
    },
    onModifier: function(){
        
        this.editing = true;
        this.render();
    },
    onAnnuler: function(){
        this.editing = false;
        this.render();
    },
    onSauvegarder: function(){
        var formHandler = new FormHandler();
        formHandler.setModel(this.editingModel);
        formHandler.save(
            'api/filtreurdestatut/modifier_condidat_competence',
            (reponse) => {
                this.model.set(reponse);
                this.editing = false;
                this.render();
                Toast.add('Élément sauvegardé', 'success');
            },
            (xhr) => {
                RacAPI.handleError(xhr);
            }
        );
    },
    onEmailSelection: function(){
        this.model.set('selected', this.ui.check.is(':checked'));
    },
    onSelectedChanged: function(){
        this.ui.check.prop('checked', this.model.get('selected'));
    },
    
})

const CandidatListeItem = CandidatListeBase.extend({    
    template: _.template(TItem),
});
const CandidatAutreListeItem = CandidatListeBase.extend({
    template: _.template(TAutreItem),
});

const List = M.CollectionView.extend({
    tagName: 'tbody',
    childViewTriggers: {
        'show:competence:statut':'show:competence:statut',
    },
    childViewOptions: function(){
        return {controller: this.model}
    },
    childView: CandidatListeItem,
    
})
const AutreList = M.CollectionView.extend({
    tagName: 'tbody',
    childViewTriggers: {
        'show:competence:statut':'show:competence:statut',
    },
    childViewOptions: function(){
        return {controller: this.model}
    },
    childView: CandidatAutreListeItem,
    
})

export const CandidatsListeView = M.View.extend({
    template: _.template(T),
    candidatsSelectionnes: null,
    ui :{
        'selectedCount':'.selectedCount',
        'destinataires':'.destinataires',
        'btnExportList':'.btnExportList',
        'btnCopier':'.btnCopier',
        'ids':'#ids'
    },
    triggers: {
        'click .btnTous':'tous:click', 
        'click .btnTousAutre':'tous:autre:click',
        'click .btnCopier':'copier'
    },
    childViewTriggers: {
        'order:by':'order:by',
        'show:competence:statut':'show:competence:statut',
    },
    regions: {
        'tbody':{
            el: 'tbody.liste',
            replaceElement: true,
        },
        'autreliste':{
            el: 'tbody.autreliste',
            replaceElement: true,
        }
    },
    
    collectionEvents: {
        'change:selected':'onSelectedChanged'
    },
    initialize: function(){
        this.candidatsSelectionnes = new Backbone.Collection();
    },
    onDestroy: function(){
        this.candidatsSelectionnes = null;
    },
    onRender: function(){
        
        if(this.model.get('NoProgramme')){
            this.showChildView('tbody', new List({
                model: this.model,
                collection: new Backbone.Collection(this.getOption('collection').filter((item)=> {
                    return item.get('NoProgramme')==this.model.get('NoProgramme')
                }))
            }))
            this.showChildView('autreliste', new AutreList({
                model: this.model,
                collection: new Backbone.Collection(this.getOption('collection').filter((item)=> {
                    return item.get('NoProgramme')!=this.model.get('NoProgramme')
                }))
            }))
        }else{
            this.showChildView('tbody', new List({
                model: this.model,
                collection: this.getOption('collection')
            }))
        }
        this.onSelectedChanged();
    },

    onSelectedChanged: function(){

        this.candidatsSelectionnes.reset(this.collection.where({selected: true}));   
        this.ui.selectedCount.html(this.candidatsSelectionnes.length ? this.candidatsSelectionnes.length : 'aucun');
        const emails = this.candidatsSelectionnes.pluck('Courriel');
        const ids = this.candidatsSelectionnes.pluck('NoCompetenceE');

        this.ui.destinataires.val(emails.join('; '));
        this.ui.ids.val(ids);
        
        this.ui.btnExportList.prop('disabled', this.candidatsSelectionnes.length==0);
        this.ui.btnCopier.prop('disabled', this.candidatsSelectionnes.length==0);
    },

    onCopier: function(){
        this.ui.destinataires.select();
        document.execCommand('copy');
        this.ui.destinataires.blur();
    },

    onTousClick: function(){
        
        // est-ce que toutes les personnes sont sélecitonnées ?
        const c = this.collection.filter((model) => {
            return model.get('NoProgramme')==this.model.get('NoProgramme') && model.get('Courriel') && !model.get('selected')
        });
        
        if(c.length==0){
            // il sont tous sélectionnés. On les déselectionne tous
            this.collection.forEach((model) =>  {
                if(model.get('NoProgramme')==this.model.get('NoProgramme')){
                    if(model.get('Courriel'))model.set('selected', false);
                }
            })
        }else{
            // on les sélectionne tous
            this.collection.forEach((model) =>  {
                if(model.get('NoProgramme')==this.model.get('NoProgramme')){
                    if(model.get('Courriel')){
                        model.set('selected', true);
                    }
                }
            })
        }
    },
    onTousAutreClick: function(){
        // est-ce que toutes les personnes sont sélecitonnées ?
        const c = this.collection.filter((model) => {
            return model.get('NoProgramme')!=this.model.get('NoProgramme') && model.get('Courriel') && !model.get('selected')
        });
        if(c.length==0){
            // il sont tous sélectionnés. On les déselectionne tous
            this.collection.forEach((model) =>  {
                if(model.get('NoProgramme')!=this.model.get('NoProgramme')){
                    if(model.get('Courriel')){
                        model.set('selected', false);
                    }
                }
            })
        }else{
            // on les sélectionne tous
            this.collection.forEach((model) =>  {
                if(model.get('NoProgramme')!=this.model.get('NoProgramme')){
                    if(model.get('Courriel')){
                        model.set('selected', true);
                    }
                }
            })
        }
    },
    
})