import M from 'backbone.marionette'
import T from './invitation.html'
import moment from 'moment'

const InvitationItem = M.View.extend({
    template: _.template(T),
    serializeData: function () {
        return {
            author: this.model.get('author'),
            created: this.model.get('created') ? moment(this.model.get('created')).calendar() : ''
        }
    },
    className: 'list-group',
})

const EmptyItem = M.View.extend({
    template: _.template('<p>Aucune</p>'),
})

export const EtudiantInvitations = M.CollectionView.extend({
    template: _.template(''),
    tagName: 'div',
    className: 'list-group condensed',
    childView: InvitationItem,
    initialize: function () {
        this.collection = this.getOption('controller').get('invitations')
    },
    emptyView: EmptyItem,
})
