import M from 'backbone.marionette'
import TFraisItem from './paiementitemfrais.html'
import TCoursItem from './paiementitemcours.html'
import TPaiementItemNewFrais from './paiementitemnewfrais.html'
import TPaiementItemNewCours from './paiementitemnewcours.html'
import TPaiementItemCoursAbsent from './paiementitemcoursabsent.html'
import { FormElement } from '../../../utils/bootstrap/formelement';
import { PaiementFactures } from './factures'
import { FormHandler } from '../../../utils/bootstrap/form/formhandler'
import { Toast } from '../../../utils/bootstrap/toast'

const PaiementItem = M.View.extend({
    regions: {
        'Type':'.Type',
        'Montant':'.Montant',
        'Paiement':'.Paiement',
        'DatePaiement':'.DatePaiement',
        'Mode':'.Mode',
        'Factures':'.factures',
        'Rembourse':'.Rembourse',
        'related_type':'.related_type'
    },
    ui:{
        'btModifier':'.btModifier',
        'btSauvegarder':'.btSauvegarder',
        'btAnnuler':'.btAnnuler',
        'btSupprimer':'.btSupprimer',
        'btPayer':'.btPayer'
    },
    tagName: 'tr',
    editing: null,
    events: {
        'click @ui.btModifier':'onModifierClick',
        'click @ui.btSauvegarder':'onSauvegarderClick',
        'click @ui.btAnnuler':'onAnnulerClick',
        'click @ui.btSupprimer':'onSupprimerClick',
    },
    triggers: {
        'click .btPayer':'payer',
    },
    
    onModifierClick: function(){
        this.editing = true;
        this.render();
    },
    onSauvegarderClick: function(){

    },
    onAnnulerClick: function(){

    },
    onSupprimerClick: function(){
        // si c'est un paiement en lien avec sima, annuler la suppression
        if(this.model.get('sima_id'))return;
        this.triggerMethod('supprimer:paiement', this.model);
    },

    showCommonInputs: function(model){
        this.showChildView('Montant', new FormElement({
            type: 'number',
            model: this.initialModel,
            attribute: 'Montant',
            collection: this.getOption('controller').get('paiement_types'),
            hideLabel: true,
            inputAttributes: {
                step: '0.01',
                placeholder: '0,00',
            }
        }))
        this.showChildView('Paiement', new FormElement({
            type: 'number',
            model: this.initialModel,
            attribute: 'Paiement',
            hideLabel: true,
            inputAttributes: {
                step: '0.01',
                placeholder: '0,00',
            }
        }))
        this.showChildView('DatePaiement', new FormElement({
            type: 'date',
            model: this.initialModel,
            attribute: 'DatePaiement',
            hideLabel: true,
        }))
        this.showChildView('Mode', new FormElement({
            type: 'select',
            model: this.initialModel,
            attribute: 'mode',
            collection: this.getOption('controller').get('paiement_modes'),
            hideLabel: true,
        }))
        this.showChildView('Rembourse', new FormElement({
            type: 'checkbox',
            model: this.initialModel,
            attribute: 'rembourse',
            hideLabel: true,
        }))
    },
    showHideButtons: function(){
        if(this.editing){
            this.ui.btSauvegarder.show();
            this.ui.btAnnuler.show();
            this.ui.btModifier.hide();
            this.ui.btSupprimer.hide();
            this.ui.btPayer.hide();
        }else{
            this.ui.btSauvegarder.hide();
            this.ui.btAnnuler.hide();
            if(!this.model.get('user_initiated'))this.ui.btModifier.show();
            else this.ui.btModifier.hide();

            if(!this.model.get('sima_id') && !this.model.get('user_initiated'))this.ui.btSupprimer.show();
            else this.ui.btSupprimer.hide();

            if(!this.model.get('sima_id') && !this.model.get('Paiement') && !this.model.get('user_initiated'))this.ui.btPayer.show();
            else this.ui.btPayer.hide();
        }
    }
})

const EditablePaiementItem = PaiementItem.extend({
    
    onRender: function(){
        

        this.showChildView('Montant', new FormElement({
            type: 'number',
            model: this.model,
            attribute: 'Montant',
            collection: this.getOption('controller').get('paiement_types'),
            hideLabel: true,
            inputAttributes: {
                step: '0.01',
                placeholder: '0,00',
            }
        }))
        
    },
})

export const NewPaiementFraisItem = EditablePaiementItem.extend({
    template: _.template(TPaiementItemNewFrais),
    triggers: {
        'click .btnAjouterPaiement':'ajouter:paiement'
    },
    initialize: function(){
        this.model = new Backbone.Model();
        this.onReinitialize();
    },
    onReinitialize: function(){
        this.model.set({
            'Type': null,
            'Montant': null,
            'NoEtudiant': this.getOption('controller').get('etudiant').get('NoEtudiant')
        })
    },
    onRender: function(){
        EditablePaiementItem.prototype.onRender.apply(this, arguments);
        this.showChildView('Type', new FormElement({
            type: 'select',
            model: this.model,
            attribute: 'Type',
            collection: this.getOption('controller').get('paiement_types'),
            hideLabel: true,
        }))
    },
})
export const NewPaiementCoursItem = EditablePaiementItem.extend({
    template: _.template(TPaiementItemNewCours),
    triggers: {
        'click .btnAjouterPaiement':'ajouter:paiement'
    },
    initialize: function(){
        this.model = new Backbone.Model();
        this.onReinitialize();
    },
    modelEvents: {
        'change:related_type':'onRelatedTypeChanged',
        'change:related_id':'onRelatedIdChanged'
    },
    onReinitialize: function(){
        this.model.set({
            'Type': null,
            'related_type':'cours',
            'related_id':null,
            'Montant': null,
            'NoEtudiant': this.getOption('controller').get('etudiant').get('NoEtudiant')
        })
    },
    onRender: function(){
        EditablePaiementItem.prototype.onRender.apply(this, arguments);
        
        this.showRelatedIdInput();
        this.showChildView('related_type', new FormElement({
            type : 'select',
            model: this.model,
            attribute: 'related_type',
            hideLabel: true,
            collection: new Backbone.Collection([
                {value: 'cours'},
                {value: 'programme'}
            ])
        }))
    },
    showRelatedIdInput: function(){
        this.showChildView('Type', new FormElement({
            type: 'indexeurautocomplete',
            model: this.model,
            attribute: 'related_id',
            indexType: this.model.get('related_type'),
            hideLabel: true,
            readonly: false,
            serviceUrl: 'autocomplete/search/'+this.model.get('related_type'),
        }))
    },
    onRelatedTypeChanged: function(){
        
        this.model.set('related_id', null);
        this.showRelatedIdInput();
    },
})

const FraisItem = PaiementItem.extend({
    template : _.template(TFraisItem),
    initialModel: null,
    initialize: function(){
        this.editing = false;
        
    },
    onRender: function(){
        if(this.editing){

            this.initialModel = new Backbone.Model(this.model.toJSON());

            this.showCommonInputs(this.initialModel);

            this.showChildView('Type', new FormElement({
                type: 'select',
                model: this.initialModel,
                attribute: 'Type',
                collection: this.getOption('controller').get('paiement_types'),
                hideLabel: true,
            }))
            
        }else{

        }
        this.showHideButtons();

        this.showChildView('Factures', new PaiementFactures({
            model: this.model,
            constroller: this.controller
        }))
    },
    
    onSauvegarderClick: function(){
        const formHandler = new FormHandler();
        
        formHandler.setModel(this.initialModel);
        formHandler.save(
            'api/etudiants/sauvegarder_paiement',
            () => {
                this.model.set(this.initialModel.toJSON());
                this.editing = false;
                this.render();
                Toast.add('Paiement modifié avec succès', 'success');
            },
            () => {
                Toast.add('Impossible de sauvegarder les modification du paiement', 'danger');
            }
        )
    },
    onAnnulerClick: function(){
        this.model.set(this.initialModel.toJSON());
        
        this.editing = false;
        this.render();
    }
})
const PaiementCoursItem = PaiementItem.extend({
    template : _.template(TCoursItem),
    initialModel: null,
    onRender: function(){
        if(this.editing){

            this.initialModel = new Backbone.Model(this.model.toJSON());

            this.showCommonInputs(this.initialModel);

            this.showChildView('Type', new FormElement({
                type: 'indexeurautocomplete',
                model: this.initialModel,
                attribute: 'related_id',
                indexType: this.initialModel.get('related_type'),
                hideLabel: true,
                readonly: false,
                serviceUrl: 'autocomplete/search/cours',
            }))
            this.showChildView('related_type', new FormElement({
                type : 'select',
                model: this.initialModel,
                attribute: 'related_type',
                hideLabel: true,
                collection: new Backbone.Collection([
                    {value: 'cours'},
                    {value: 'programme'}
                ])
            }))

        }else{

        }
        this.showHideButtons();
        this.showChildView('Factures', new PaiementFactures({
            model: this.model,
            constroller: this.controller
        }))
    },
    onSauvegarderClick: function(){
        const formHandler = new FormHandler();
        formHandler.setModel(this.initialModel);
        formHandler.save(
            'api/etudiants/sauvegarder_paiement',
            () => {
                this.model.set(this.initialModel.toJSON());
                this.editing = false;
                this.render();
                Toast.add('Paiement modifié avec succès', 'success');
            },
            () => {
                Toast.add('Impossible de sauvegarder les modification du paiement', 'danger');
            }
        )
    },
    onAnnulerClick: function(){
        this.model.set(this.initialModel.toJSON());
        
        this.editing = false;
        this.render();
    }
})

const PaiementList = M.CollectionView.extend({
    template: _.template(''),
    tagName:'tbody',
    childViewTriggers: {
        'payer':'payer',
        'supprimer:paiement':'supprimer:paiement',
    },
    childViewOptions: function(){
        return {controller: this.getOption('controller')}
    },
})

export const PaiementListFrais = PaiementList.extend({
    viewFilter: function(view){
        return view.model.get('related_type')==null
    },
    childViewOptions: function(){
        return {controller: this.getOption('controller')}
    },
    childView: FraisItem
})

export const PaiementListeCours = PaiementList.extend({
    viewFilter: function(view){
        return view.model.get('related_type')!==null
    },
    childViewOptions: function(){
        return {controller: this.getOption('controller')}
    },
    childView: PaiementCoursItem
})

const PaiementCoursAbsentItem = M.View.extend({
    tagName: 'tr',
    template: _.template(TPaiementItemCoursAbsent),
    ui: {
        'montant':'.montant'
    },
    triggers: {
        'click .btnAjouter':'ajouter:paiement'
    },
})

export const PaiementListeCoursAbsents = PaiementList.extend({
    childViewTriggers: {
        'ajouter:paiement': 'ajouter:paiement', 
    },
    initialize: function(){
        this.collection = new Backbone.Collection();
        this.resetCollection();
        this.listenTo(this.getOption('controller').get('paiement'),'change', _.bind(this.resetCollection, this));
        this.listenTo(this.getOption('controller').get('paiement'),'add', _.bind(this.resetCollection, this));
        this.listenTo(this.getOption('controller').get('paiement'),'remove', _.bind(this.resetCollection, this));
    },
    
    resetCollection: function(){
        const data = []
        this.getOption('controller').get('inscriptionc').forEach((item) => {
            if(!item.get('cours'))return;
            // le paiement est déjà exigé ?
            var c = this.getOption('controller').get('paiement').where({
                related_type: 'cours',
                related_id: item.get('cours').NoCours
            })
            if(!c || c.length == 0){
                // pas encore présent, on l'ajoute !!!
                const h = parseInt((item.get('cours').Duree).trim());
                const taux = parseFloat(this.getOption('controller').get('inscriptionp').get('TauxHoraire'));
                const total = h*taux

                data.push({
                    id: item.get('cours').NoCours,
                    related_type: 'cours',
                    related_id: item.get('cours').NoCours,
                    Montant: total,
                    NoEtudiant: this.getOption('controller').get('etudiant').get('NoEtudiant'),
                    cours : item.get('cours')
                })
            }
        })
        this.collection.reset(data);
    },
    childViewOptions: function(){
        return {controller: this.getOption('controller')}
    },
    childView: PaiementCoursAbsentItem,
    emptyView: new M.View({tagName:'tr', template: _.template('<td>Aucun</td>')})
})