import M from 'backbone.marionette'
import { showModal } from '../../utils/bootstrap/modal'
import { userdata } from '../user'
import TExpired from './sessionexpired.html'
import TOtherUser from './sessionotheruser.html'

const SessionExpiredMessage = M.View.extend({
    template:_.template(TExpired)
})
const SessionOtherUserMessage = M.View.extend({
    triggers: {
        'click .btnReload':'reload'
    },
    template:_.template(TOtherUser),
    onReload: function(){
        window.location.reload();
    }
})

const UserSessionPersister = M.Object.extend({
    initialize: function(){
        // démarrer une routine de validation
        setInterval(_.bind(this.checkUserSession, this), 1000 * 30);
    },
    sessionExpiredMessage: null,
    sessionOtherUserMessage: null,
    currentWarning: null,
    checkUserSession: function(){
        $.ajax({
            url: './api/data/currentuser',
            success: (response) => {
                if(response.user){
                    // logged
                    if(userdata('user') != response.user){
                        // connecté, mais avec un login différent
                        this.showOtherUserMessage();
                    }else{
                        // tout est beau !
                        if(this.currentWarning){
                            this.currentWarning.triggerMethod('close');
                            this.currentWarning = null;
                        }
                    }

                }else{
                    if(userdata('user')){
                        // session expirée !
                        this.showSessionExpiredMessage();
                    }
                }
                
            }
        })
    },
    showOtherUserMessage: function(){
        if(!this.sessionOtherUserMessage){
            this.sessionOtherUserMessage = new SessionOtherUserMessage()
        }
        if(this.currentWarning !== this.sessionOtherUserMessage){
            if(this.currentWarning){
                this.currentWarning.triggerMethod('close');
            }
            this.currentWarning = this.sessionOtherUserMessage
            showModal('Session périmée', this.currentWarning);
        }
    },
    showSessionExpiredMessage: function(){
        if(!this.sessionExpiredMessage){
            this.sessionExpiredMessage = new SessionExpiredMessage()
        }
        if(this.currentWarning !== this.sessionExpiredMessage){
            if(this.currentWarning){
                this.currentWarning.triggerMethod('close');
            }
            this.currentWarning = this.sessionExpiredMessage
            showModal('Session expirée', this.currentWarning);
        }
    }
})

let userpersisterInstance = null;

export const activateUserSesisonPersistence = () => {
    if(userpersisterInstance == null){
        userpersisterInstance = new UserSessionPersister();
    }
}