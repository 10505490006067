import M from 'backbone.marionette'
import T from './competence.html'
import TCompetenceItem from './competenceitem.html'
import { FormElement } from '../../utils/bootstrap/formelement';
import moment from 'moment';
import { FormHandler } from '../../utils/bootstrap/form/formhandler';
import { Toast } from '../../utils/bootstrap/toast';
import { competences_status, complete_competences_status } from '../config';
import { CompetencesAbsentes } from './competences/competencesabsentes';
import { userdata } from '../user';
import Backbone from 'backbone';



const CompetenceItem = M.View.extend({
    tagName: 'tr',
    template: _.template(TCompetenceItem),
    regions: {
        'nom': '.nom',
        'statut': '.statut',
        'note': '.note',
        'materiel': '.materiel',
        'dateEnvoi': '.dateEnvoi',
        'session': '.session',
        'fm': '.fm',
        'enseignant_id': '.enseignant_id',
        'specialiste': '.specialiste',
        'actions': '.actions'
    },
    ui: {
        'btModifier': '.btModifier',
        'btSauvegarder': '.btSauvegarder',
        'btAnnuler': '.btAnnuler',
        'btSupprimer': '.btSupprimer',
        'btAjouter': '.btAjouter',
        'btSuivi':'.btSuivi',
    },
    triggers: {
        'click .btModifier': 'modifier',
        'click .btSauvegarder': 'sauvegarder',
        'click .btAnnuler': 'annuler',
        'click .btSupprimer': 'supprimer',
        'click .btAjouter': 'ajouter',
    },
    modelEvents: {
        'change:Statut': 'onStatutChanged',
        'change:NoCompetence': 'onCompetenceChanged'
    },
    editing: null,
    editingModel: null,
    onStatutChanged: function () { },
    onCompetenceChanged: function () { },
    serializeData: function () {
        const NoCompeteceE = this.model.get('NoCompetenceE');
        return _.extend(this.model.toJSON(), {
            evals: this.getOption('controller').get('etudiantevals').filter(function(p){ return p.get('NoCompetenceE') == NoCompeteceE}).length,
            Statut: this.model.get('Statut') in competences_status ? competences_status[this.model.get('Statut')] : '-'
        })
    },
    onRender: function () {
        if (this.editing) {

            if(userdata('Type') === 'contributeur'){

                this.showChildView('statut', new FormElement({
                    model: this.editingModel,
                    type: 'select',
                    attribute: 'Statut',
                    hideLabel: true,
                    collection: new Backbone.Collection(
                        _.reduce(competences_status, (prev, curr, index) => {
                            prev.push({
                                value: index,
                                label: curr
                            });
                            return prev;
                        }, [{ value: '', label: '[Sélectionner...]' }])
                    )
                }));
                this.showChildView('note', new FormElement({
                    model: this.editingModel,
                    type: 'number',
                    attribute: 'Note',
                    hideLabel: true,
                    inputAttributes: {
                        style: 'width: 66px'
                    }
                }));
            }

            this.showChildView('materiel', new FormElement({
                model: this.editingModel,
                horizontal: true,
                type: 'checkbox',
                attribute: 'MaterielEnvoye',
                hideLabel: true,
            }));
            this.showChildView('dateEnvoi', new FormElement({
                model: this.editingModel,
                type: 'datepicker',
                attribute: 'DateEnvoiMateriel',
                hideLabel: true,
            }));

            if(userdata('Type') === 'contributeur'){
                const sessions = new Backbone.Collection();
                sessions.add({value: '', label:'Session'});
                // prochaine session
                const y = parseInt(moment().format('YY'))
                let currSession = '';
                switch(parseInt(moment().format('M'))){
                    case 1:
                    case 2:
                    case 3:
                        currSession = 'H'+y;
                        break;
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        currSession = 'E'+y;
                        break;
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        currSession = 'A'+y;
                        break;
                }
                const toYear = parseInt(moment().format('YY')) + 1;
                for(var i = toYear; i >=11 ; i--){
                    
                    if(currSession=='A'+i) sessions.add({value: '* A'+i});
                    else sessions.add({value: 'A'+i});
                    if(currSession=='E'+i) sessions.add({value: '* E'+i});
                    else sessions.add({value: 'E'+i});
                    if(currSession=='H'+i) sessions.add({value: '* H'+i});
                    else sessions.add({value: 'H'+i});
                    
                }
                this.showChildView('session', new FormElement({
                    model: this.editingModel,
                    type: 'select',
                    attribute: 'SessionEtudes',
                    hideLabel: true,
                    collection: sessions
                }));
                this.showChildView('fm', new FormElement({
                    model: this.editingModel,
                    horizontal: true,
                    type: 'checkbox',
                    attribute: 'FM',
                    hideLabel: true,
                }));

                this.showChildView('specialiste', new FormElement({
                    model: this.editingModel,
                    type: 'text',
                    attribute: 'Specialiste',
                    hideLabel: true,
                }));

                this.renderEnseignantList(this.editingModel);

            }else{
                this.renderEnseignantName()
            }

            
        } else {
            this.renderEnseignantName();
        }
    },
    renderEnseignantName: function(){
        const e = this.getOption('controller').get('enseignants').get(this.model.get('enseignant_id'));
        this.showChildView('enseignant_id', new M.View({
            tagName: 'span',
            template: _.template('<%=NoEnseignant ? Prenom + " " + NomEnseignant : ""%>'),
            model: e ? e : new Backbone.Model({ NoEnseignant: null })
        }))
    },
    renderEnseignantList: function (model) {

        var compens = this.getOption('controller').get('listecompens').filter({ NoCompetence: parseInt(model.get('NoCompetence')) });
        var listeEnseignants = new Backbone.Collection();
        listeEnseignants.add({ value: '0', label: 'Aucun' });

        if (compens) {
            for (var i = 0; i < compens.length; i++) {
                const e = this.getOption('controller').get('enseignants').get(compens[i].get('NoEnseignant'));
                if (e) {
                    listeEnseignants.add({
                        value: compens[i].get('NoEnseignant'),
                        label: e.get('Prenom') + ' ' + e.get('NomEnseignant') + ' - ' + compens[i].get('active_comps') + ' / ' + compens[i].get('active_total')
                    })
                }
            }
        }

        this.showChildView('enseignant_id', new FormElement({
            model: model,
            type: 'select',
            attribute: 'enseignant_id',
            hideLabel: true,
            collection: listeEnseignants
        }));
    }

});

const ExistingCompetenceItem = CompetenceItem.extend({
    onRender: function () {
        CompetenceItem.prototype.onRender.apply(this, arguments);

        this.showChildView('nom', new M.View({
            model: this.model,
            template: _.template('<a href="./candidat/competence/<%=NoCompetenceE%>"><%=CodeCompetence%> - <%=NomCompetence%></a>'),
        }))


        if (this.editing) {
            this.ui.btModifier.hide();
            this.ui.btSauvegarder.show();
            this.ui.btAnnuler.show();
            this.ui.btSupprimer.hide();
            this.ui.btAjouter.hide();
            this.ui.btSuivi.hide();
        } else {
            this.ui.btModifier.show();
            this.ui.btSauvegarder.hide();
            this.ui.btAnnuler.hide();
            if(userdata('Type') === 'contributeur'){
                this.ui.btSupprimer.show();
            }else{
                this.ui.btSupprimer.hide();
            }
            this.ui.btAjouter.hide();
            this.ui.btSuivi.hide();
        }
        this.onStatutChanged();
    },
    onStatutChanged: function () {
        if (_.include(complete_competences_status, this.model.get('Statut'))) {
            this.$el.addClass('success');
        } else {
            this.$el.removeClass('success');
        }
    },
    onSauvegarder: function () {
        const formHandler = new FormHandler();
        formHandler.setModel(this.editingModel);
        formHandler.save('api/etudiants/enregistrer_competenceetudiant',
            (response) => {
                this.getOption('controller').updateListeCompens();
                this.model.set(response)
                Toast.add('Compétence « ' + this.model.get('NomCompetence') + ' » sauvegardée', 'success');
                this.editingModel = null;
                this.editing = false;
                this.render();
            },
            (errors) => {
                if (errors) {
                    Toast.add('Erreur lors de la sauvegarde', 'danger');
                } else {
                    Toast.add('Impossible de sauvegarder la compétence ' + this.model.get('NomCompetence'), 'danger');
                }
            }
        );
    },
    onSupprimer: function () {

        if(userdata('Type') !== 'contributeur')return;

        if (confirm('Cliquer sur OK pour supprimer la compétence « ' + this.model.get('NomCompetence') + ' » à ce candidat')) {
            $.ajax({
                url: 'api/etudiants/supprimer_competenceetudiant/' + this.model.get('NoCompetenceE'),
                cache: false,
                success: () => {
                    Toast.add('Compétence « ' + this.model.get('NomCompetence') + ' » supprimée.', 'success');
                    this.model.destroy();
                },
                error: () => {
                    Toast.add('Erreur. Impossible de supprimer la compétence ' + this.model.get('NomCompetence'), 'danger');
                }
            })
        }
    },
    onModifier: function () {

        this.editing = true;
        this.editingModel = new Backbone.Model(this.model.toJSON());
        this.render();
    },
    onAnnuler: function () {
        this.editing = false;
        this.editingModel = null;
        this.render();
    }
})

const NewCompetenceItem = CompetenceItem.extend({
    onRender: function () {
        this.editingModel = this.model;
        this.editing = true;
        CompetenceItem.prototype.onRender.apply(this, arguments);
        this.showChildView('nom', new FormElement({
            horizontal: true,
            type: 'indexeurautocomplete',
            indexType: 'competence',
            label: '',
            hideLabel: true,
            model: this.model,
            attribute: 'NoCompetence',
            serviceUrl: 'autocomplete/search/competence',
            inputAttribute: {
                placeholder: 'Rechercher une compétence'
            }
        }));
        this.ui.btModifier.hide();
        this.ui.btSauvegarder.hide();
        this.ui.btAnnuler.hide();
        this.ui.btSupprimer.hide();
        this.ui.btAjouter.show();
    },
    onAjouter: function () {
        var formHandler = new FormHandler();
        formHandler.setModel(this.model);
        formHandler.save('api/etudiants/ajouter_competenceetudiant',
            (response) => {
                Toast.add('Compétence ajoutée avec succès', 'success');
                this.triggerMethod('ajout:competence', response);
            },
            (errors) => {
                Toast.add(errors ? errors.join('<br />') : 'Une erreur est survenue lors de l\'ajout de cette compétence', 'danger');
            }
        );
    },
    onCompetenceChanged: function () {
        
        this.renderEnseignantList(this.model);
    }
})
const CompetenceCollection = M.CollectionView.extend({
    template: _.template(''),
    tagName: 'tbody',
    childViewTriggers: {
        'ajout:competence': 'ajout:competence'
    },
    childView: ExistingCompetenceItem,
    viewComparator: 'CodeCompetence'
})

export const CompetenceView = M.View.extend({
    template: _.template(T),
    id: 'etudiantPartial',
    regions: {
        'tbody': {
            el: 'tbody.content',
            replaceElement: true,
        },
        'newitem': {
            el: '.newitem',
            replaceElement: false
        },
        'competencesAbsentes':'.competencesAbsentes'
    },

    childViewTriggers: {
        'ajout:competence': 'ajout:competence',
        'ajouter:competence:absente':'ajouter:competence:absente'
    },

    onRender: function () {
        this.showChildView('tbody', new CompetenceCollection({
            collection: this.getOption('controller').get('competenceetudiant'),
            childViewOptions: {
                controller: this.getOption('controller'),
            }
        }));
        
        if(userdata('Type') === 'contributeur'){
            this.showNewCompetence();
            this.showChildView('competencesAbsentes', new CompetencesAbsentes({
                model: this.getOption('controller')
            }))
        }
    },

    showNewCompetence: function () {
        this.showChildView('newitem', new NewCompetenceItem({
            model: this.getEmptyCompetence(),
            controller: this.getOption('controller'),
        }));
    },
    getEmptyCompetence: function(){
        return new Backbone.Model({
            NoEtudiant: this.getOption('controller').get('etudiant').get('NoEtudiant'),
            NoCompetence: null,
            NoCompetenceE: null,
            Statut: null,
            Specialiste: null,
            SessionEtudes: null,
            MaterielEnvoye: null,
            DateEnvoiMateriel: null,
            FM: null,
            Note: null,
            enseignant_id: null

        })
    },
    onAjoutCompetence: function (serverData) {
        this.getOption('controller').get('competenceetudiant').add(serverData);
        this.showNewCompetence();
    },
    onAjouterCompetenceAbsente: function(view){
        const that = this
        const newCompetence = this.getEmptyCompetence();
        newCompetence.set('NoCompetence', view.model.get('NoCompetence'))
        
        $.ajax({
            url: 'api/etudiants/ajouter_competenceetudiant',
            type: 'post',
            data: {
                data: JSON.stringify(newCompetence.toJSON()),
            },
            success: function(serverData){
                Toast.add('Compétence ' + view.model.get('NomCompetence') + ' ajoutée', 'success')
                that.getOption('controller').get('competenceetudiant').add(serverData);
            },
            error: function(){
                Toast.add('Impossible d\'ajouter cette compétence', 'danger')
            }
        })
    }
})
