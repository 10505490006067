import M from 'backbone.marionette'
import T from './suivis.html'
import { NouveauSuivi } from './suivis/nouveausuivi'
import { FormHandler } from '../../utils/bootstrap/form/formhandler'
import { ListSuivis } from './suivis/liste'
import { Toast } from '../../utils/bootstrap/toast'
import moment from 'moment'
import { FormElement } from '../../utils/bootstrap/formelement'

export const SuivisView = M.View.extend({
    template: _.template(T),
    id: 'etudiantPartial',
    childViewTriggers: {
        'ajouter:suivi':'ajouter:suivi',
        'modifier': 'modifier',
        'sauvegarder': 'sauvegarder',
        'annuler': 'annuler',
        'supprimer': 'supprimer',
    },
    regions: {
        'nouveausuivi':{
            el: '.nouveausuivi',
            replaceElement: true,
        },
        'list': {
            el: 'tbody.list',
            replaceElement: true
        },
        'filtreContributeur':'.filtreContributeur',
        'filtreType':'.filtreType'
    },
    modelEvents: {
        'change:filtreContributeur':'onFilterChanged',
        'change:filtreType':'onFilterChanged',
    },
    initialize: function(){
        this.model = new Backbone.Model({
            filtreContributeur: null,
            filtreType: '',
        })
    },
    onRender: function(){
        
        this.showChildView('nouveausuivi', new NouveauSuivi({
            controller: this.getOption('controller')
        }))

        this.showChildView('list', new ListSuivis({
            collection: this.getOption('controller').get('suivi'),
            controller: this.getOption('controller'),
            viewComparator: _.bind(this.listViewComparator, this),
            viewFilter: _.bind(this.listViewFilter, this),
        }))

        this.showChildView('filtreContributeur', new FormElement({
            type: 'select',
            model: this.model,
            attribute: 'filtreContributeur',
            label:'Auteur',
            collection: new Backbone.Collection(this.getOption('controller').get('suiviusers').reduce((prev, curr) => {
                prev.push({
                    value: curr.get('NoUtilisateur'),
                    label: curr.get('displayName')
                })
                return prev;
            }, [{value: '', label: '[ Tous les contributeurs ]'}])),
        }))
        this.showChildView('filtreType', new FormElement({
            type: 'select',
            model: this.model,
            attribute: 'filtreType',
            label:'Type de suivi',
            collection: this.getOption('controller').get('types_suivis')
        }))
    },

    onFilterChanged: function(){
        this.getChildView('list').removeComparator();
        this.getChildView('list').setComparator(_.bind(this.listViewFilter, this));
    },

    listViewFilter: function(view){
        return  (!this.model.get('filtreContributeur') || this.model.get('filtreContributeur') == view.model.get('author_id')) &&
                (!this.model.get('filtreType') ||  this.model.get('filtreType') == view.model.get('Type'));
    },
    listViewComparator: function(viewa, viewb){
        return viewa.model.get('NoSuivi') > viewb.model.get('NoSuivi') ? -1 : 1;
    },

    onAjouterSuivi : function(view){
        var formHandler = new FormHandler();
        formHandler.setModel(view.model);
        formHandler.save(
            'api/etudiants/ajouter_suivi',
            (response) => {
                this.getOption('controller').get('suivi').add(response);
                view.triggerMethod('reset');
                Toast.add('Suivi ajouté avec succès !', 'success');
            },
            () => {
                Toast.add('Erreur. Impossible d\'ajouter le suivi', 'danger');
            }
        )
    },
    onSauvegarder : function(view){
        var formHandler = new FormHandler();
        formHandler.setModel(view.initialModel);
        formHandler.save(
            'api/etudiants/modifier_suivi',
            (response) => {
                view.model.set(response);
                view.triggerMethod('reset');
                Toast.add('Suivi ajouté avec succès !', 'success');
            },
            () => {
                Toast.add('Erreur. Impossible d\'ajouter le suivi', 'danger');
            }
        )
    },
    onSupprimer: function(view){
        if(confirm('Êtes-vous certain de vouloi supprimer ce suivi ?')){
            $.ajax({
                url: 'api/etudiants/supprimer_suivi',
                type: 'post',
                data: {
                    NoSuivi: view.model.get('NoSuivi')
                },
                success: (response) => {
                    if(response===true){
                        Toast.add('Suivi supprimé avec succès', 'success');
                        this.getOption('controller').get('suivi').remove(view.model);
                    }else{
                        Toast.add('Impossible de supprimer le suivi.', 'danger');
                    }
                },
                error: () => {
                    Toast.add('Impossible de supprimer le suivi.', 'danger');
                }
            })
        }
    }
})