import M from 'backbone.marionette'
//import './jquery.autocomplete'
import css from './jquery.autocomplete.css'
import autocomplete from 'autocompleter'
//import css from 'autocompleter/autocomplete.css'

/**
 * Fonctionne avec les modèles Indexeur => 
 * {
 *  model: string -> Type de ressource (nom de la table)
 *  model_id: number -> Identifiant de la ressource
 *  text_title: string -> Représentation textuelle de la ressource
 * }
 */

export const IndexeurAutocompleteView = M.View.extend({
    tagName: 'input',
    type: 'text',
    className: 'form-control autocomplete',
    template: _.template(''),
    modelEvents: {},
    selectedSuggestion: null,
    autocompleteRef: null,
    autocompleteOptions: null,
    initialize: function () {
        this.$el.attr('onclick', 'this.select()');

        if (this.getOption('readonly')) this.$el.prop('readonly', true);

        if ($('style[data-autocomplete]').length == 0) {
            $('head').append('<style type="text/css" data-autocomplete>' + css + '</style>');
        }

        console.log('val', this.model.get(this.getOption('attribute')));
        console.log('indexType', this.getOption('indexType'))
        if (this.model.get(this.getOption('attribute')) && this.getOption('indexType')) {

            this.modelEvents['change:' + this.getOption('attribute')] = 'onModelValueChanged';

            // on va cacher l'input. Mieux vaut voir apparaitre l'input en entier après
            // une fraction de seconde plus tard que seulement son contenu (c'est bizarre)
            console.log('GOGO')
            this.$el.hide();
            this.fetchModelTitleValue();

        }

        this.autocompleteOptions = {
            input: this.el,
            debounceWaitMs: 200,
            fetch: (text, update) => {
                $.ajax({
                    url: this.getOption('serviceUrl') + '?query=' + text,
                    success: (response) => {
                        update(response.suggestions)
                    }
                })
            },
            onSelect: _.bind(this._onSelect, this),
            render: this.getOption('renderItem') ? this.getOption('renderItem'): (item, currentValue) => {
                console.log('No renderItem option. Render it manually', item)
                const element = document.createElement("div");
                element.innerHTML = item.label;
                return element;
            }
        }

    },
    fetchModelTitleValue: function () {
        console.log('lets do it')
        $.ajax({
            url: 'autocomplete/title/' + this.getOption('indexType') + '/' + this.model.get(this.getOption('attribute')),
            success: (response) => {
                this.updateInputValue(response.title);
                this.$el.show();
            }
        })
    },
    onModelValueChanged: function () {
        if (!this.selectedSuggestion || this.model.get(this.getOption('attribute')) != this.selectedSuggestion.model_id) {
            this.updateInputValue('Chargement...');
            this.fetchModelTitleValue();
        }
    },
    onDestroy: function () {
        this.selectedSuggestion = null;
    },
    updateInputValue: function (val) {
        this.$el.val(val);
    },
    onRender: function () {

    },
    onAttach: function () {

        this.autocompleteRef = autocomplete(this.autocompleteOptions)

        /*
        if (this.getOption('readonly')) return;
        var acOptions = _.extend({
            onSelect: _.bind(this._onSelect, this),
            width: 'auto',
            formatResult: function (suggestion, currentValue) {
                return suggestion.text_title;
            },
        }, this.options);
        //ac(acOptions)
        $(this.el).autocomplete(acOptions);
        */
    },
    onDetach: function () {
        //this.$el.autocomplete('dispose');
        this.autocompleteRef.destroy();

    },
    _onSelect: function (suggestion) {
        this.selectedSuggestion = suggestion;
        this.updateInputValue(suggestion.label);
        this.triggerMethod('select', suggestion.value, suggestion.model);
    },
    reset: function () {
        this.selectedSuggestion = null;
        this.$el.val('');
        this.autocompleteRef.clear();
        //this.autocompleteRef = autocomplete(this.autocompleteOptions)
        //this.$el.autocomplete('clear');
        //this.autocompleteRef.
    },
})
