import M from 'backbone.marionette'
import { DepotView } from '../depot/depot'

export const CandidatDocuments = M.View.extend({
    template: _.template('<div></div>'),
    regions: {
        'content':{
            el: '>div',
            replaceElement: true,
        }
    },
    onRender: function(){
        this.showChildView('content', new DepotView({
            collection: this.getOption('controller').get('documents'),
            relatedType:'dossier_candidat',
            relatedId: this.getOption('controller').get('etudiant').get('NoEtudiant')
        }))
    }
})