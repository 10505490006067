import M from 'backbone.marionette'
import T from './ajoutercours.html'
import moment from 'moment';
import { FormElement } from '../../../utils/bootstrap/formelement';

export const AjouterCours = M.View.extend({
    template: _.template(T),
    tagName: 'td',
    attributes: {
        colspan:"3"
    },
    regions: {
        'choixCours': '.choixCours',
        'inputDateInscription':'.inputDateInscription',
        'frais':'.frais',
    },
    triggers: {
        'click .btnAjouter':'ajouter:cours',
    },
    modelEvents: {
        'change:NoCours': 'onCoursChanged',
    },
    initialize: function(){
        this.model = new Backbone.Model({
            NoCours: null,
            DateInscription: moment().format('YYYY-MM-DD'),
            NoEtudiant: this.getOption('controller').get('etudiant').get('NoEtudiant'),
            frais: null,
        })
    },
    onRender: function(){
        const currentCoursIds = _.map(this.getOption('collection').toJSON(), (item) => { return item.NoCours});
        const choixCours = new Backbone.Collection();
        choixCours.add({value: '', label: '[Sélectionner]'});
        choixCours.add(_.reduce(this.getOption('controller').get('programmecours').toJSON(), (prev, item) => {
            if(item.NoCours){
                prev.push({
                    value: item.NoCours,
                    label: item.CodeCours + ' - ' + item.NomCours,
                    disabled: _.includes(currentCoursIds, item.NoCours)
                })
            }
            return prev;
        }, []));
        
        this.showChildView('choixCours', new FormElement({
            type: 'select',
            model: this.model,
            label:'Cours',
            attribute: 'NoCours',
            collection: choixCours
        }));
        this.showChildView('inputDateInscription', new FormElement({
            type: 'datepicker',
            model: this.model,
            label:'Date d\'inscription',
            attribute: 'DateInscription',
        }))
        
        this.onCoursChanged();
    },
    onCoursChanged: function(){
        let tauxHoraire = this.getOption('controller').get('inscriptionp').get('TauxHoraire');
        let cours = this.getOption('controller').get('programmecours').get(this.model.get('NoCours'));
        if(cours){
            this.model.set('frais', parseInt(cours.get('Duree')) * parseFloat(tauxHoraire))
        }
        this.showChildView('frais', new FormElement({
            type: 'text',
            model: this.model,
            label:"Frais d'inscription à ajouter " + (cours ? ' ( Cours de ' + cours.get('Duree') + ' h)' : ''),
            attribute: 'frais',
        }))
    },
})