import { showConfirm } from './../../utils/bootstrap/modal'

const ChargeBaseModel = Backbone.Model.extend({
    defaults: {
        'id': null,
        'isEnseignant': false,
        'isCompetence': false
    },
    isEnseignant: function(){ return false; },
    isCompetence: function(){ return false; }
})
const ChargeCompetenceModel = ChargeBaseModel.extend({
    defaults: {
        'id': null,
        'isEnseignant': false,
        'isCompetence': true
    },
    isCompetence: function(){ return true; }
})
const ChargeEnseignantModel = ChargeBaseModel.extend({
    defaults: {
        'id': null,
        'isEnseignant': true,
        'isCompetence': false
    },
    isEnseignant: function(){ return true; },
})


const ChargesCollection = Backbone.Collection.extend({
    comparator: 'name',
})
const EnseignantCollection = Backbone.Collection.extend({
    comparator: 'NomEnseignant',
})

const GestionChargeControllerModel = Backbone.Model.extend({
    charges: null,
    initialize: function(){
        this.set('charges', new ChargesCollection());
        this.set('loading', false);
        this.set('error', null);
        this.set('type', null);
        this.set('id', null);
        this.set('selectedCompetenceEtudiant', null);
        this.set('selectedCompetence', null);
        this.set('selectedCompetenceEtudiants', new Backbone.Collection());
        this.set('selectedCompetenceCount', 0);
        this.set('currentDragCompetence', null);
        this.set('currentDropChargeId', null);
        this.set('alteredCompetences', 0);
        this.set('previously_assigned_enseignant_id', null);
    },
    
    loadData: function(type, id){
        if(this.get('alteredCompetences')){
            const message = this.get('alteredCompetences') > 1 ? (this.get('alteredCompetences') + ` compétences n'ont pas été sauvegardées et seront perdues. Poursuivre sans sauvegarder ?`) : `Une compétence n'a pas été sauvegardée et sera perdue. Poursuivre sans sauvegarder ?`;
            showConfirm(message, (value) => {
                if(value===true){   
                    this.doLoadData(type, id)
                }
            })
        }else{
            this.doLoadData(type, id)
        }
    },
    doLoadData: function(type,id){
        this.set('loading', true);
        this.set('type', type);
        this.set('id', parseInt(id));
        $.ajax({
            url: './api/gestioncharges/' + type + '/' + id,
            success: (response) => {
                gestionChargeController.parseData(response);
            },
            error: (xhr) => {
                this.set('error', 'Erreur lors du chargement...');
            }
        })
    },

    parseData: function(response){
        switch(this.get('type')){
            case 'competence':
                this.set('model', new ChargeCompetenceModel(response.competence));        
                this.set('enseignants', new EnseignantCollection());
                break;
            case 'enseignant':
                this.set('model', new ChargeEnseignantModel(response.enseignant));        
                this.set('enseignants', new EnseignantCollection(response.enseignants));
                break;
            default:
                this.set('error', 'Élément introuvable ...');
                break;
        }
        this.set('enseignant', new Backbone.Collection(response.enseignant));
        this.set('listecompens', new Backbone.Collection(response.listecompens));
        this.set('competences', new Backbone.Collection(response.competences));
        this.set('competenceetudiant', new Backbone.Collection(response.competenceetudiant));
        this.get('competenceetudiant').forEach(item => item.set('previous_enseignant_id', item.get('enseignant_id')));
        this.set('alteredCompetences', 0)
        this.hydrate();
        this.set('loading', false);
        
    },
    hydrate: function(){

        const charges = new Backbone.Collection();
        

        if(this.get('type') == 'competence'){

            // ajouter les charges orphelins
            charges.add({
                id: 0,
                competenceetudiant: new Backbone.Collection(),
                listecompens: null,
                name: '',
                enseignant: null
            })
            // ajouter d'abord tous les enseignants attitrés à la compétence
            this.get('listecompens').forEach((item) => {
                let id = item.get('compens').NoEnseignant;
                if(!id)id = 0;
                if(!charges.get(id)){
                    const enseignant = this.get('enseignant').get(item.get('compens').NoEnseignant)
                    charges.add({
                        id: id,
                        competenceetudiant: new Backbone.Collection(),
                        listecompens: item.toJSON(),
                        name: enseignant ? enseignant.get('Prenom') + ' ' + enseignant.get('NomEnseignant') : '',
                        enseignant: enseignant
                    })
                }
            })
            this.get('competenceetudiant').forEach((competenceetudiant) => {
                const id = competenceetudiant.get('enseignant_id') ? competenceetudiant.get('enseignant_id'): 0;
                if(!charges.get(id)){
                    const enseignant = this.get('enseignant').get(competenceetudiant.get('enseignant_id'))
                    const listecompens = this.get('listecompens').find((item) => {
                        return item.get('compens').NoEnseignant == competenceetudiant.get('enseignant_id');
                    })
                    
                    charges.add({
                        id: id,
                        competenceetudiant: new Backbone.Collection(),
                        listecompens: listecompens ? listecompens.toJSON() : null,
                        name: enseignant ? enseignant.get('Prenom') + ' ' + enseignant.get('NomEnseignant') : '',
                        enseignant: enseignant
                    })
                }
                charges.get(id).get('competenceetudiant').add(competenceetudiant);
            });
        }else if(this.get('type') == 'enseignant'){
            
            // ajouter d'abord tous les enseignants attitrés à la compétence
            this.get('listecompens').forEach((item) => {
                if(item.get('compens').NoEnseignant == this.get('id')){
                    let competence = item.get('competence');
                    if(!charges.get(competence.NoCompetence)){
                        charges.add({
                            id: competence.NoCompetence,
                            competenceetudiant: new Backbone.Collection(),
                            listecompens: item.toJSON(),
                            name: competence.CodeCompetence + ' - ' + competence.NomCompetence,
                            competence: competence
                        })
                    }
                }
            })
            this.get('competenceetudiant').forEach((item) => {
                if(!charges.get(item.get('NoCompetence'))){
                    const competence = this.get('competences').get(item.get('NoCompetence')).toJSON();
                    charges.add({
                        id: item.get('NoCompetence'),
                        competenceetudiant: new Backbone.Collection(),
                        listecompens: null,
                        name: competence.CodeCompetence + ' - ' + competence.NomCompetence,
                        competence: competence
                    })
                }
                charges.get(item.get('NoCompetence')).get('competenceetudiant').add(item);
            });
        }
        
        this.get('charges').reset(charges.toJSON());
    },
    selectionnerToutesTachesEnseignant: function(NoEnseignant){
        this.resetCompetenceSelections();
        this.get('selectedCompetenceEtudiants').reset(
            this.get('competenceetudiant').filter(item => item.get('enseignant_id') == NoEnseignant).map(item => {
                item.trigger('addSelected')
                return item
            })
        );
        this.selectCompetenceEtudiant(this.get('selectedCompetenceEtudiants').last(), true)
        
    },
    selectionnerToutesTachesCompetence: function(NoCompetence){
        this.resetCompetenceSelections();
        this.get('selectedCompetenceEtudiants').reset(
            this.get('competenceetudiant').filter(item => item.get('NoCompetence') == NoCompetence).map(item => {
                item.trigger('addSelected')
                return item
            })
        );
        this.selectCompetenceEtudiant(this.get('selectedCompetenceEtudiants').last(), true)
        
    },
    selectCompetenceEtudiant: function(competenceetudiant, addElement){
        
        const sortant = this.get('selectedCompetenceEtudiant')
        if(sortant){
            if(sortant == competenceetudiant){
                sortant.trigger('unsetSelected');
                sortant.trigger('removeSelected');
                this.get('selectedCompetenceEtudiants').remove(sortant)
                this.set('selectedCompetence', null);
                this.set('selectedCompetenceEtudiant', null);
                return;
            }else{
                sortant.trigger('unsetSelected');
            }
        }
        if(competenceetudiant){
            if(addElement != true){
                this.resetCompetenceSelections();
            }
            if(this.get('type') == 'enseignant'){
                // ajouter, mais s'assurer que toutes les autres competences etudiant font référence à la meme competence !
                const remove = this.get('selectedCompetenceEtudiants').reduce((acc, curr) => {
                    if(curr.get('NoCompetence') != competenceetudiant.get('NoCompetence')){
                        acc.push(curr)
                        curr.trigger('unsetSelected');
                        curr.trigger('removeSelected');
                    }
                    return acc
                }, [])
                this.get('selectedCompetenceEtudiants').remove(remove);
            }
            this.get('selectedCompetenceEtudiants').add(competenceetudiant);
            
            
            this.set('selectedCompetenceEtudiant', competenceetudiant);
            this.set('selectedCompetence', this.get('competences').get(competenceetudiant.get('NoCompetence')));
            if(competenceetudiant.get('previous_enseignant_id') != competenceetudiant.get('enseignant_id')){
                this.set('previously_assigned_enseignant_id', competenceetudiant.get('previous_enseignant_id') ? competenceetudiant.get('previous_enseignant_id'): 0);
            }else{
                this.set('previously_assigned_enseignant_id', null);
            }
            competenceetudiant.trigger('setSelected');
            competenceetudiant.trigger('addSelected');
        }else{
            this.set('selectedCompetenceEtudiant', null);
            this.set('selectedCompetence', null);
            this.set('previously_assigned_enseignant_id', null);
        }
        this.set('selectedCompetenceCount', this.get('selectedCompetenceEtudiants').length)
    },
    resetCompetenceSelections: function(){
        this.get('selectedCompetenceEtudiants').forEach((item) => {
            item.trigger('removeSelected');
            item.trigger('unsetSelected');
        })
        this.set({
            selectedCompetenceEtudiant: null,
            selectedCompetence: null,
        })
        this.get('selectedCompetenceEtudiants').reset();
        this.set('selectedCompetenceCount', this.get('selectedCompetenceEtudiants').length)
        this.set('previously_assigned_enseignant_id', null);
    },
    onCompetencesAltered: function(){
        this.set('alteredCompetences', this.get('competenceetudiant').filter(item => item.get('previous_enseignant_id') != item.get('enseignant_id')).length)
    }
})

export const gestionChargeController = new GestionChargeControllerModel();