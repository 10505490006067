import TItem from './discussionitem.html'
import { fromnow } from '../fromnow/fromnow'
import { userdata } from '../user'
import { showConfirm, showLoading, showModal } from '../../utils/bootstrap/modal';
import { Toast } from '../../utils/bootstrap/toast';

export const DiscussionItem = M.View.extend({
    template: _.template(TItem),
    className: 'discussion-item',
    regions: {
        'date':'.discussion-item-date'
    },
    ui:{
        'messageOptions':'.messageOptions',
        'suiviPublicLabel':'.suiviPublicLabel'
    },
    triggers: {
        'click .btnAddSuiviPublic':'ajouter:suivi:public'
    },
    modelEvents: {
        'change':'render',
    },
    onRender: function(){
        if(userdata('Type') == 'contributeur'){
            if(!this.model.get('NoSuivi')){
                this.ui.messageOptions.show();
                this.ui.suiviPublicLabel.hide();
                this.$el.removeClass('suivipublic');
            }else{
                this.ui.messageOptions.hide();
                this.ui.suiviPublicLabel.show();
                this.$el.addClass('suivipublic');
            }
        }else{
            this.ui.messageOptions.hide();
            this.ui.suiviPublicLabel.hide();
            this.$el.removeClass('suivipublic');
        }
        this.showChildView('date', new fromnow({
            date: this.model.get('created')
        }))
    },
    onAjouterSuiviPublic: function(){
        showConfirm('Le contenu de ce message sera ajouté dans la section « suivis publics ».', (value) => {
            
            if(value===true){   
                const loadView = showLoading('Ajout du message en cours...')
                setTimeout(() => {
                    $.ajax({
                        url: './api/discussions/copier_en_suivi_public/',
                        data: {
                            id: this.model.get('id')
                        },
                        type: 'post', 
                        success: (response) => {
                            this.model.set(response);
                            Toast.add('Suivi ajouté', 'success')
                            loadView.close()
                        },
                        error: (xhr) => {
                            Toast.add('Une erreur a eu lieu :(', 'danger')
                            loadView.close()
                        }
                    })
                }, 1000);
            }
        })
    },
})
