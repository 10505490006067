import M from 'backbone.marionette'
import { showModal } from '../utils/bootstrap/modal';

export const logged = function(){
    return document.userdata && userdata('id')
}

export const userdata = function(item){
    if(!item)return document.userdata;
    else return document.userdata && Object.keys(document.userdata).includes(item) ? document.userdata[item] : null
}