/**
 * Fichier bootstrap du site Web
 * 
 * Le fichier bootstrap parcours le DOM à la recherche des
 * modules qui s'y trouvent. Une fois trouvé, le module est instensié en
 * utiliant l'élément html comme racine. Tout le contenu sera supprimé et remplacé
 * par le contenu html du module.
 * 
 * <div data-module="monmodule" data-id="1234"><p>Chargement...</p></div>
 * 
 * Cela instanciera le module src/modules/monmodule.js. Ce module pourra récupérer
 * les variables indiquées dans les attributs "data-". Comme les attributs sont des 
 * chaines de caractères, il faut prévoir faire la conversion au besoin :
 * 
 * contenu de src/modules/monmodule.js
 * import M from 'backbone.marionette';
 * export default M.View.extend({
 *      template :_.template('<p>Mon module</p>'),
 *      initialize: function(){
 *          console.log(parseInt(this.getOption('id')))
 *      }
 * })
 */
 
import moment from 'moment'
moment.locale('fr');
import css from 'css.css'
import tinycss from 'tinymce/skins/ui/oxide/skin.css';
import { FicheView } from './views/etudiant/fiche';
import { AccesRapide } from './views/accesrapide';
import { SpecialisteView } from './views/specialiste/specialiste';
import { AutocompleteView } from './views/autocomplete';
import { CoursView } from './views/cours/cours';
import { ProgrammeView } from './views/programme/programme';
import { GrilleProgramme } from './views/filtreurdestatut/grilleprogramme'
import { GrilleCandidats } from './views/filtreurdestatut/grillecandidats';
import { MesPaiements } from './views/mespaiements/mespaiements';
import { NouveauCandidatChoixProgramme } from './views/nouveaucandidat/choixprogramme';
import { DepotView } from './views/depot/depot';
import { DatePickerView } from './utils/bootstrap/form/datepicker';
import { TestView } from './views/test/test'; 
import { UserDropdownNotices } from './views/notices/userdropdownnotices';
import { fromnow } from './views/fromnow/fromnow';
import { tinymceView } from './views/tinymce/tinymceview'; 
import { DiscussionView } from './views/discussion/discussion';
import { testchatbox } from './views/chatbox/testchatbox';
import { PhotoUploadButton } from './views/upload/photouploadbutton';
import { activateUserSesisonPersistence } from './views/sessionpersister/sessionpersister';
import { GestionChargeButton, GestionCharge } from './views/gestioncharges/gestionchargecomptence';
  





var modules = {
    fiche: FicheView,
    accesrapide: AccesRapide,
    specialiste: SpecialisteView,
    autocomplete: AutocompleteView,
    cours: CoursView,
    programme: ProgrammeView,
    grilleprogramme: GrilleProgramme,
    grillecandidats: GrilleCandidats, 
    mespaiements: MesPaiements,
    nouveaucandidatchoixprogramme: NouveauCandidatChoixProgramme,
    depot: DepotView, 
    datepicker: DatePickerView,
    test: TestView,
    usernotices: UserDropdownNotices,
    fromnow: fromnow,
    tinymce: tinymceView,
    discussion: DiscussionView,
    testchatbox: testchatbox,
    photouploadbutton: PhotoUploadButton,
    gestionCharge: GestionCharge,
    getsionChargeButton : GestionChargeButton
};






window.camelCased = function (myString) { return myString.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }); }

$(function () {
    $('head').append('<style type="text/css">' + css + tinycss + '</style>');
    bootstrapContent($('body')); 
    activateUserSesisonPersistence() 
});
window.bootstrapContent = function (el) {
    $(el).find('[data-module]').each(function () {
        var module_name = $(this).attr('data-module');
        if ($(this).attr('data-module') in modules) {   

            $(this).attr('data-attached-module', $(this).attr('data-module')); // remove to avoid bootstrapping again
            $(this).removeAttr('data-module'); // remove to avoid bootstrapping again
            var moduleOptions = { el: this };
            $.each(this.attributes, function () {
                // this.attributes is not a plain object, but an array
                // of attribute nodes, which contain both the name and value
                if (this.specified && this.name.substr(0, 5) == 'data-') {
                    moduleOptions[camelCased(this.name.substr(5))] = this.value
                } 
            });
            var objClass = modules[module_name];
            new objClass(moduleOptions).render();
        } else {
            console.error('Module ' + module_name + ' not found :(');
        }
    });
    alterTableTRDataHref(el);
}

function alterTableTRDataHref(el){
    $(el).find('tr[data-href]').each(function(){
        $(this).css('cursor', 'pointer')
            .on('mouseover', function(){
                $(this).addClass('active');
            })
            .on('mouseout', function(){
                $(this).removeClass('active');
            })
            .on('click', function(){
                document.location = $(this).attr('data-href'); 
            })
    })
}