import M, { triggerMethod } from 'backbone.marionette'
import T from './depot.html'
import TItem from './depotdocumentitem.html'
import { Uploader } from './uploader';
import moment from 'moment';
import { Toast } from '../../utils/bootstrap/toast';

const DocumentItem = M.View.extend({
    tagName: 'tr',
    template: _.template(TItem),
    triggers: {
        'click .btDelete': 'delete:file'
    },
    onDeleteFile: function(){
        if(confirm('Le fichier ' + this.model.get('client_name') + ' sera supprimé définitivement')){
            $.ajax({
                url: './api/data/deletedocument/' + this.model.get('id'),
                success: _.bind(this.onDeleteSuccess, this),
                error: _.bind(this.onDeleteError, this)
            })
        }
    },
    onDeleteSuccess: function(response){
        Toast.add('Document supprimée.', 'success');
        this.model.collection.remove(this.model);
    },
    onDeleteError: function(){
        Toast.add('Impossible de supprimer le fichier', 'danger');
    }
})

const DocumentList = M.CollectionView.extend({
    tagName: 'tbody',
    childView: DocumentItem,
    viewComparator: function (view) {
        return view.model.get('m_value') * -1;
    }
})

const DocumentModel = Backbone.Model.extend({
    initialize: function () {
        const m = new moment(this.get('created'));
        const e = this.get('expires') ? new moment(this.get('expires')) : null
        this.set({
            'm': m,
            'm_value': m.valueOf(),
            'e': e
        });
    }
})
const DocumentCollection = Backbone.Collection.extend({
    model: DocumentModel,
})

export const DepotView = M.View.extend({
    template: _.template(T),
    regions: {
        'liste': {
            el: 'tbody',
            replaceElement: true
        },
        'upload': '#upload',
    },
    triggers: {
        'click .btTeleverser': 'televerser'
    },
    ui: {
        'upload': '#upload',
        'btTeleverser': '.btTeleverser'
    },
    initialize: function () {
        this.model = new Backbone.Model({
            'related_type': this.getOption('relatedType'),
            'related_id': this.getOption('relatedId'),
        });
        
        if (this.getOption('collection')) {
            this.collection = new DocumentCollection(this.getOption('collection').toJSON());
        } else {
            var initialData = this.$el.text();
            if(initialData){
                this.collection = new DocumentCollection(JSON.parse(atob(initialData)));
                this.$el.show();
            }else{
                this.collection = new DocumentCollection();
            }
        }
    },
    onRender: function () {
        this.showChildView('upload', new Uploader({
            onUploaded: (document) => {
                this.collection.add(document);
            },
            related_type: this.getOption('relatedType'),
            related_id: this.getOption('relatedId')
        }))
        this.showChildView('liste', new DocumentList({
            collection: this.collection,
        }))
    },
    onTeleverser: function () {
        this.ui.btTeleverser.hide();
        this.ui.upload.slideDown();
    }
})
