import Backbone from 'backbone';
import M from 'backbone.marionette'
import { IndexeurAutocompleteView } from '../../utils/autocomplete/indexeurautocomplete';
import { getSearchItemPill } from '../accesrapide';
import { gestionChargeController } from './controller';
import T from './header.html'

export const GestionChargesHeader = M.View.extend({
    template: _.template(T),
    childViewEventPrefix: 'childview',
    serializeData: function(){
        return _.extend({'hasItem': gestionChargeController.get('model') != null}, gestionChargeController.get('model') ? gestionChargeController.get('model').toJSON() : {});
    },
    regions: {
        lookupInput: {
            el: '#lookupInput',
            replaceElement: true
        },
    },
    initialize: function(){
        this.model = new Backbone.Model();
    },
    onRender: function(){
        this.showChildView('lookupInput', new IndexeurAutocompleteView({
            attributes: {
                placeholder: 'Rechercher spécialiste ou compétence ...'
            },
            model: this.model,
            serviceUrl: 'autocomplete/gestiontachesearch',
            renderItem: (item, currentValue) => {
               
                const element = document.createElement("div");
                element.innerHTML = getSearchItemPill(item) + item.label;
                return element;
            }
        }))
    },
    onChildviewSelect: function(model_id, model_type){
        
        switch(model_type){
           
            case 'enseignant':
            case 'competence':
                gestionChargeController.loadData(model_type, model_id);
                //window.location.href = 'competences/gestion/competence/'+model_id;
                break;
        }
    }
})