import Backbone from 'backbone';
import M from 'backbone.marionette'
import T from './gestionchargecomptence.html'
import TChargesEnseignant from './chargesenseignant.html'
import TChargesCompetence from './chargescompetence.html'
import TFooter from './footer.html'
import TInfoCompetenceEtudiant from './tinfocompetenceetudiant.html'
import TChargesEnseignantCompetence from './chargesenseignantcompetence.html'
import { Loading } from '../../utils/bootstrap/loading'
import { showAlert, showConfirm, showLoading, showModal } from '../../utils/bootstrap/modal';
import { GestionChargesHeader } from './header';
import { ListeEnseignants } from './listeenseignants';
import { ChargeDropZone } from './chargedropzone';
import { gestionChargeController } from './controller';


export const GestionChargeButton = M.View.extend({
    template: _.template(''),
    events: {
        'click':'onClick'
    },
    onClick: function(){
        showModal('Gestion de charge', new GestionCharge({noCompetence: this.getOption('noCompetence')}))
    },
    render: function(){
        // do nothing
    }
})


const CompetenceEtudiantPill = M.View.extend({
    tagName: 'button',
    className: 'btn btn-default btn-xs competence-pill',
    attributes:function(){
        return {
            title: this.getTitle()
        }
    },
    getTitle: function(){ 
        return this.model.get('Prenom') + ' ' + this.model.get('NomFamille') + ' (' + this.model.get('DA') + ')';
    },
    template : _.template(`<%=initials%> <%=Statut%><%= etudianteval_id ? ' <span class="glyphicon glyphicon-star" aria-hidden="true"></span>' : '' %>`),
    initialize: function(){
        if(gestionChargeController.get('selectedCompetenceEtudiant') == this.model)this.setSelected();
        else if(gestionChargeController.get('selectedCompetenceEtudiants').find(this.model))this.addSelected();
        this.onEnseignantIdChanged();
    },
    events: {
        'click':'onClick',
        'dragstart':'onDragStart',
        'drag':'onDrag',
        'dragend':'onDragEnd'
    },
    modelEvents: {
        'setSelected':'setSelected',
        'unsetSelected':'unsetSelected',
        'addSelected':'addSelected',
        'removeSelected':'removeSelected',
        'change:enseignant_id':'onEnseignantIdChanged',
    },
    onClick: function(event){
        gestionChargeController.selectCompetenceEtudiant(this.model, true);
    },
    setSelected: function(){
        this.$el.addClass('btn-info');
    },
    unsetSelected: function(){
        this.$el.removeClass('btn-info');
    },
    addSelected: function(){
        this.$el.addClass('btn-primary');
    },
    removeSelected: function(){
        this.$el.removeClass('btn-primary');
    },
    onEnseignantIdChanged: function(){
        if(this.model.get('enseignant_id') != this.model.get('previous_enseignant_id')){
            this.$el.addClass('altered');
        }else{
            this.$el.removeClass('altered');
        }
    }
})
const DraggableCompetenceEtudiantPill = CompetenceEtudiantPill.extend({
    attributes:function(){
        return {
            draggable: "true",
            title: this.getTitle()
        }
    },
    events: {
        'click':'onClick',
        'dragstart':'onDragStart',
        'drag':'onDrag',
        'dragend':'onDragEnd'
    },
    onDragStart: function(event){
        gestionChargeController.set('currentDragCompetence', this.model)
        this.$el.addClass('')
    },
    onDrag: function(event){
    },
    onDragEnd: function(event){
        gestionChargeController.set('currentDragCompetence', null)
    },
    onClick: function(event){
        gestionChargeController.selectCompetenceEtudiant(this.model, event.ctrlKey);
    },

});

const CompetencePills = M.CollectionView.extend({
    template: _.template(''),
    childView: DraggableCompetenceEtudiantPill,
})



const CompensContainerBase = M.View.extend({
    
})

const NoCompetenceActives = M.View.extend({
    template: _.template('<p>Aucune compétence active actuellement attribué à <%= Prenom %> <%= NomEnseignant %></p>'),
    serializeData: function(){
        return gestionChargeController.get('model').toJSON()
    }
})

const ChargesCompetence = CompensContainerBase.extend({
    tagName: 'div',
    template: _.template(TChargesCompetence),
    triggers: {
        'click .btConsulter': 'consulter:click',
    },
    events: {
        'click .btSelectionnerTous':'onSelectionnerTous'
    },
    className: 'zoneCompetence',
    
    regions: {
        'list':'>.competenceetudiant-container',
    },
    
    onConsulterClick: function(){
        gestionChargeController.loadData('competence', this.model.get('competence').NoCompetence)
    },  

    onSelectionnerTous: function(event){
        event.preventDefault();
        gestionChargeController.selectionnerToutesTachesCompetence(this.model.get('id'))
    },
    
    onRender: function(){
        this.showChildView('list', new CompetencePills({
            collection: this.model.get('competenceetudiant'),
            emptyView: NoCompetenceActives
        }));
    },
   
})


const ChargesEnseignant = CompensContainerBase.extend({
    tagName: 'div',
    template: _.template(TChargesEnseignant),
    className: 'zoneSpecialiste',
    serializeData: function(){
        const previousCount = this.model.get('listecompens') ? this.model.get('listecompens').compens.active_comps : null;
        const currCount = this.model.get('competenceetudiant').length;
        return _.extend(this.model.toJSON(), {
            diff: previousCount !== null ? (currCount-previousCount) : null
        });
    },
    triggers: {
        'click .btConsulter': 'consulter:click'
    },
    events: {
        'click .btSelectionnerTous':'onSelectionnerTous'
    },
    regions: {
        'dropzone':{
            el: '.dropzone',
            replaceElement: true,
        },
        'list':'>.competenceetudiant-container',
    },
    attributes: {
        style: "width: 200px"
    },
    onConsulterClick: function(){
        console.log(this.model)
        gestionChargeController.loadData('enseignant', this.model.get('enseignant').get('NoEnseignant'))
    },
    onSelectionnerTous: function(event){
        event.preventDefault();
        gestionChargeController.selectionnerToutesTachesEnseignant(this.model.get('id'))
    },
    initialize: function(){
        this.listenTo(gestionChargeController, 'change:previously_assigned_enseignant_id', _.bind(this.checkIsPreviouslyAssignedIndicator, this))
    },
    checkIsPreviouslyAssignedIndicator: function(){
        if(gestionChargeController.get('previously_assigned_enseignant_id') !== null && gestionChargeController.get('previously_assigned_enseignant_id') == this.model.get('id')){
            this.$el.addClass('isPreviouslyAssignedEnseignant');
        }else{
            this.$el.removeClass('isPreviouslyAssignedEnseignant');
        }
    },
    onRender: function(){
        this.checkIsPreviouslyAssignedIndicator();
        this.showChildView('list', new CompetencePills({
            collection: this.model.get('competenceetudiant'),
        }));
        this.showChildView('dropzone', new ChargeDropZone({model: this.model}))
    },
})


const ListeCompetenceEnseignant = M.CollectionView.extend({
    template: _.template(''),
    childView: ChargesCompetence,
})

const ChargesParEnseignant = M.View.extend({
    tagName: 'div',
    template: _.template(TChargesEnseignantCompetence),
    regions: {
        'chargesVerticales':'.chargesVerticales',
        'listeenseignants':'.listeenseignants'
    },
    onRender: function(){
        this.showChildView('chargesVerticales', new ListeCompetenceEnseignant({collection: gestionChargeController.get('charges')}))
    }
})

const ChargesParCompetence = M.CollectionView.extend({
    tagName: 'div',
    className:'chargesContainer',
    childView: ChargesEnseignant,
})

const InfoCompetenceEtudiant = M.View.extend({
    getTemplate: function(){
        if(this.model)return _.template(TInfoCompetenceEtudiant)
        else return _.template('')
    },
    initialize: function(){
        this.listenTo(gestionChargeController, 'change:selectedCompetenceEtudiant', _.bind(this.onselectedCompetenceEtudiantChanged, this));
        this.model = gestionChargeController.get('selectedCompetenceEtudiant');
    },
    onselectedCompetenceEtudiantChanged: function(){
        this.model = gestionChargeController.get('selectedCompetenceEtudiant');
        this.render();
    },
    onRender: function() {

    }
})


const CompetenceList = M.CollectionView.extend({
    template: _.template(''),
    childView: CompetenceEtudiantPill
})

const Footer = M.View.extend({
    template: _.template(TFooter),
    id: 'selecteurs',
    tickTimeout: null,
    height: null,
    regions: {
        'liste':'.selecteurContainer',
        'infocompetenceetudiant':'#infocompetenceetudiant'
    },
    ui: {
        'saveButton':'.btnSave',
        'selectCount':'.selectCount',
        'btnDeselect':'.btnDeselect',
        'savecount':'.savecount',
    },
    events: {
        'click @ui.saveButton':'onSaveClick',
        'click @ui.btnDeselect':'onDeselectClick'
    },
    initialize: function(){
      this.listenTo(gestionChargeController, 'change:alteredCompetences', _.bind(this.onCompetenceAltered, this));
        
      this.listenTo(gestionChargeController, 'change:selectedCompetenceCount', _.bind(this.onselectedCompetenceEtudiantCountChanged, this));
      this.tickTimeout = setInterval(() => {
        if(this.height !== this.$el.outerHeight()){
            gestionChargeController.set('footerHeight', this.$el.outerHeight());
            this.height = this.$el.outerHeight();
        }
        
      }, 2)
    },
    onDestroy: function(){
        clearInterval(this.tickTimeout);
    },
    onRender: function(){
        this.onselectedCompetenceEtudiantCountChanged();
        this.showChildView('liste', new CompetenceList({
            collection: gestionChargeController.get('selectedCompetenceEtudiants')
        }));
        this.showChildView('infocompetenceetudiant', new InfoCompetenceEtudiant())
        this.onCompetenceAltered();
    },
    onCompetenceAltered: function(){
        this.ui.saveButton.prop('disabled', gestionChargeController.get('alteredCompetences') == 0);
        this.ui.savecount.html(gestionChargeController.get('alteredCompetences') ? gestionChargeController.get('alteredCompetences') + ' compétence'  +(gestionChargeController.get('alteredCompetences')>1?'s':'') : '');
    },
    onSaveClick: function(){
        const loadingView = showLoading('Sauvegarde en cours...');
        const altered_competences = gestionChargeController.get('competenceetudiant').filter(item => item.get('previous_enseignant_id') != item.get('enseignant_id')).map(item => item.toJSON());
        $.ajax({
            url: './api/gestioncharges/updatecharges' + gestionChargeController.get('type') + '/' + gestionChargeController.get('model').get('id'),
            data: {
                data: JSON.stringify({altered_competences: altered_competences})
            },
            type: 'post',
            success: (response) => {
                gestionChargeController.parseData(response)
                loadingView.close();
            },
            error: (xhr) => {
                loadingView.close();
                showAlert('Erreur', 'Une erreur a eu lieu durant la sauvegarde :(')
            }
        })
    },
    onDeselectClick: function(){
        gestionChargeController.resetCompetenceSelections();
    },
    onselectedCompetenceEtudiantCountChanged: function(){
        this.ui.btnDeselect.prop('disabled', gestionChargeController.get('selectedCompetenceCount') == 0)
    },

})

const NoRootElementSelectedView = M.View.extend({
    template: _.template(`<h2>Aucun élément séléctionné</h2><p class="noRootElementSelected">Veuillez choisir un spécialiste ou une compétence à l'aide du champs de recherche ci-dessus, dans la barre supérieure.</div>`),
})

export const GestionCharge = M.View.extend({

    template: _.template(T),

    ui: {
        'chargesScroll':'#chargesScroll',
        'charges':'#charges',
        'footer':'#footer',
        'header':'#header',
        'listeenseignants':'#listeenseignants',
    },

    regions: {
        charges:{
            el: '#charges',
            replaceElement: false
        },
        footer: {
            el: '#footer',
            replaceElement: false
        },
        listeenseignants: '#listeenseignants',
        header: '#header',
    },

    

    serializeData: function(){
        return {};
    },

    initialize: function(){
        this.listenTo(gestionChargeController, 'change:loading', _.bind(this.render, this));
        this.listenTo(gestionChargeController, 'change:error', _.bind(this.render, this));
        this.listenTo(gestionChargeController, 'change:currItem', _.bind(this.render, this));
        $(document).on('keyup', _.bind(this.onKeyUp, this))
        $(window).on('resize', _.bind(this.adjustSrollHeight, this))
        this.listenTo(gestionChargeController, 'change:footerHeight', _.bind(this.adjustSrollHeight, this));
        if(this.getOption('type') && this.getOption('id')){
            gestionChargeController.loadData(this.getOption('type'), this.getOption('id'))
        }
    },

    onDestroy: function(){
        $(document).off('keyup');
        $(window).off('resize');
    },

    adjustSrollHeight: function(){
        if(gestionChargeController.get('footerHeight')){
            const availableHeight = $(window).height() - gestionChargeController.get('footerHeight') - this.ui.header.height();
            this.ui.charges.height(availableHeight)
            this.ui.listeenseignants.height(availableHeight)
        }
        
        if(gestionChargeController.get('model') && gestionChargeController.get('model').isEnseignant()){
            this.ui.charges.width($(window).width() - this.ui.listeenseignants.outerWidth() - 5)
        }else{
            this.ui.charges.width('100%')
        }
    },

    onKeyUp: function(event){
        switch(event.keyCode){
            case 27: // escape
                gestionChargeController.resetCompetenceSelections();
                break;
        }
    },

    onRender: function(){
        if(gestionChargeController.get('loading')){
            this.showChildView('charges', new Loading());
        }else if(gestionChargeController.get('error')){
            this.showChildView('charges', new M.View({template: _.template(gestionChargeController.get('error'))}));
        }else{
            if(!gestionChargeController.get('model')){
                this.showChildView('charges', new NoRootElementSelectedView({model: gestionChargeController}));
            }else if(gestionChargeController.get('model').isCompetence()){
                this.showChildView('charges', new ChargesParCompetence({collection: gestionChargeController.get('charges')}))
            }else if(gestionChargeController.get('model').isEnseignant()){
                this.showChildView('charges', new ChargesParEnseignant({model: gestionChargeController}));    
                this.showChildView('listeenseignants', new ListeEnseignants({collection: gestionChargeController.get('enseignants')}))
            }else{
                this.showChildView('charges', new NoRootElementSelectedView({model: gestionChargeController}));    
            }   
        }
        this.showChildView('header', new GestionChargesHeader());
        this.showChildView('footer', new Footer());
        
        this.adjustSrollHeight();
    },
    

})

