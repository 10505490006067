import Datepicker from 'bootstrap-datepicker'
import css from 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css'
import { FormControl } from './formcontrol';

export const DatePickerView = FormControl.extend({
    tagName:'input',
    dateTimePickerRef: null,
    initialize: function(){
        
        if($('style [data-bootstrap-datepicker]').length==0){
            $('head').append('<style data-bootstrap-datepicker>' + css + '</style>');
        }
    },
    attributes: function(){
        var res = _.extend(FormControl.prototype.attributes.apply(this, arguments), {type: 'text' });
        return res;
    },
    onRender: function(){
        this.dateTimePickerRef = $(this.el).datepicker({format: 'yyyy-mm-dd'});
    },
    onDetach: function(){
        $(this.el).datepicker('destroy');
    }
})