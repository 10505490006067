import { FormControl } from './formcontrol'


export const FormTextarea = FormControl.extend({
    template: _.template('<%=content%>'),
    tagName: 'textarea',
    attributes: function(){
        return this.getOption('inputAttributes');
    },
    serializeData: function(){
        return { 'content' : this.model.get(this.getOption('attribute'))}
    },
})