import M from 'backbone.marionette'
import T from './mespaiementsitem.html'
import { PaiementFactures } from '../etudiant/paiement/factures';

export const MesPaiementsItem = M.View.extend({
    tagName: 'tr',
    template: _.template(T),
    regions: {
        'Factures':'.factures',
    },
    onRender: function(){
        this.showChildView('Factures', new PaiementFactures({
            model: this.model,
            constroller: this.controller,
            etudiant: true
        }))
    }
});