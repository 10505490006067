import Backbone from 'backbone';
import { Chatbox } from '../chatbox/chatbox';
import { getNavItems } from '../etudiant/fiche';
import { FicheNav } from '../etudiant/fichenav';
import { userdata } from '../user';
import T from './discussion.html'
import { DiscussionItem } from './discussionitem';
import { NewDiscussionItem } from './newdiscussionitem';
import TIndicator from './topindicator.html' 


const DiscussionModel = Backbone.Model.extend({id: 0, count: 0, related_type: null, related_id: null});
const DiscussionItemModel = Backbone.Model.extend({id: 0, discussion_id: 0, user_id: null, type: null, content: null, created: null});
const DiscussionItemCollection = Backbone.Collection.extend({
    model: DiscussionItemModel
})

const TopView = M.View.extend({
    template: _.template(TIndicator),
    className: 'text-center top-indicator',
    modelEvents: {
        'change:count':'render',
        'change:loading':'render'
    },
    triggers: {
        'click .btLoadMore':'load:more',
    },
    initialize: function(){
        this.listenTo(this.getOption('collection'), 'add', _.bind(this.render, this));
        this.listenTo(this.getOption('collection'), 'reset', _.bind(this.render, this));
        this.listenTo(this.getOption('collection'), 'remove', _.bind(this.render, this));
    },
    serializeData: function(){
        return {
            count: this.model.get('count'),
            total: this.getOption('collection').length,
            loading: this.model.get('loading')
        }
    }
})

export const DiscussionView = M.View.extend({

    template: _.template(T),

    newMessage: null,
    discussionItems: null,

    regions: {
        'list':{
            el: '.discussion-list>div',
            replaceElement: true,
        },
        'add':{
            el: '.discussion-add-item',
            replaceElement: true,
        },
        'nav': {
            el: '.discussion-nav'
        }
    },

    childViewEventPrefix: 'childview',

    initialize: function(){

        this.model = new DiscussionModel({
            related_type: this.getOption('relatedType'),
            related_id: this.getOption('relatedId'),
            loading: false,
            newestId: null,
            oldestId: null,
        })
        
        this.discussionItems = new DiscussionItemCollection();
        
        this.newMessage = new DiscussionItemModel();
        this.initNewMessage();

        if(this.$el.text()){
            this.onDataLoaded(JSON.parse(atob(this.$el.text())))
            this.$el.text('');
        }else{

        }
        
        this.$el.addClass('discussion-container');
    },

    initNewMessage: function(){
        this.newMessage.set({
            id: 0,
            content: '',
            submitting: false,
            type: 'TEXT'
        });
    },

    onDetach:function(){
        $(window).off(this.cid);
        $(document).off(this.cid);
    },

    onDataLoaded: function(json){
        if(json.discussion.id){
            this.model.set(json.discussion);
        }
        this.discussionItems.add(json.items)
    },

    onRender: function() {
        this.$el.show();
        this.showChildView('add', new NewDiscussionItem({model: this.newMessage}));
        this.showChildView('list', new Chatbox({
            collection: this.discussionItems,
            childView: DiscussionItem, 
            viewComparator: (a, b) => { return a.model.get('id') > b.model.get('id') ? 1 : -1},
            topView: new TopView({
                model: this.model,
                collection: this.discussionItems,
                onLoadMore: () => {
                    this.loadMore()
                }
            })
        }));
        if(userdata('Type') === 'contributeur'){
            const m  = new Backbone.Model({currentTab: 'conversation'})
            this.showChildView('nav', new FicheNav({
                model: m,
                collection: getNavItems(this.getOption('relatedId'), true)
            }))
        }
    },


    loadMore: function(){
        this.model.set('loading', true);
        var that = this;
        const lowestId = this.discussionItems.reduce(function(acc, curr){
            return curr.get('id') < acc ? curr.get('id') : acc;
        }, Number.MAX_VALUE);
        $.ajax({
            url: './api/discussions/messages/'+this.model.get('related_type') + '/' + this.model.get('related_id') + '/' + (lowestId === Number.MAX_VALUE? 0 : lowestId),
            success: function(response){
                that.onDataLoaded(response)
                that.model.set('loading', false);
            },
            error: function(xhr){
                that.model.set('loading', false);
            }
        })
    },

    onChildviewTopReached: function(){
        /*
        if(this.model.get('count') > this.getOption('discussionItems').length && this.model.get('loading') !== true){
            console.log('loadMore !')
            this.loadMore();
        }
        */
    },

    onChildviewSubmitMessage: function(){
        
        this.newMessage.set('submitting', true);
        var that = this;
        $.ajax({
            url: './api/discussions/send/'+this.model.get('related_type') + '/' + this.model.get('related_id'),
            type: 'POST',
            data: this.newMessage.toJSON(),
            success: function(response){
                that.onDataLoaded(response);
                that.initNewMessage();
            },
            error: function(xhr){
                that.newMessage.set('submitting', false);
            }
        })
    },

    maximizeConversationSpace: function(){
        let espaceOccupee = $('.mainContainerNav').outerHeight() + $('.mainFooter').outerHeight();
        if($('.mobileNav').height() > 0){
            espaceOccupee += $('.mobileNav').height();
        }
        if($('.enTete').outerHeight() > 0){ 
            espaceOccupee += $('.enTete').outerHeight();
        }
        const availableHeight = $(window).height();
        let maximizedHeight = availableHeight - espaceOccupee;
        maximizedHeight = maximizedHeight < 400 ? 400 : maximizedHeight; // minimum 400 pixel de hauteur !
        this.$el.css('height', maximizedHeight + 'px');
    }
})