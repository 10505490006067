import M from 'backbone.marionette'
import T from './paiements.html'
import { FormElement } from '../../utils/bootstrap/formelement';
import { PaiementListFrais, PaiementListeCours, NewPaiementFraisItem, NewPaiementCoursItem, PaiementListeCoursAbsents } from './paiement/paiementlist';
import { Toast } from '../../utils/bootstrap/toast';
import { showAlert, showLoading, showModal } from '../../utils/bootstrap/modal';
import { PasserellePaiement } from './paiement/passerelle';
import TMaximumEditing from './paiement/maximumediting.html'


const MontantMaximum = M.View.extend({
    editing: null,
    getTemplate: function(){
        return this.editing === true ? _.template(TMaximumEditing) : _.template('<%=maximum.toFixed(2)%> $ <button class="btn btn-xs btn-default bt-modifier">Modifier</button>')
    },
    ui: {
        'maximumInput':'#maximumInput'
    },
    modelEvents: {
        'change:maximum':'render',
    },
    events: {
        'click .bt-modifier':'onModifierClick',
        'click .bt-appliquer':'onAppliquerClick',
        'click .bt-annuler':'onAnnulerClick',
    },
    onModifierClick: function(){
        this.editing = true;
        this.render();
    },
    onAnnulerClick: function(){
        this.editing = false;
        this.render();
    },
    onAppliquerClick: function(){
        const view = showLoading('Sauvegarde en cours...')
        $.ajax({
            url: './api/etudiants/modifiermontantmaximum/' + this.getOption('controller').get('inscriptionp').get('NoInscription'),
            data: {
                maximum: this.ui.maximumInput.val()
            },
            type: 'post',
            success: (response) => {
                view.close();
                this.getOption('controller').get('inscriptionp').set('MontantMaximum', response)
                this.model.trigger('updateWindow')
                this.editing = false;
                this.render();
                
            },
            error : (xhr) => {
                view.close();
                showAlert('Erreur', 'Une erreur a eu lieu.')
            }
        })
        
    }
})

export const PaiementsView = M.View.extend({
    template: _.template(T),
    id: 'etudiantPartial',
    childViewTriggers: {
        'ajouter:paiement': 'ajouter:paiement',
        'payer': 'payer',
        'supprimer:paiement': 'supprimer:paiement',
        'ajouter:cours:absent': 'ajouter:cours:absent',
    },
    modelEvents: {
        'updateWindow': 'updateWindow'
    },
    regions: {
        'newTypeFrais': '.newTypeFrais',
        'newMontant': '.newMontant',
        'paiementsfrais': {
            el: 'tbody.paiementsFrais',
            replaceElement: true,
        },
        'paiementscours': {
            el: 'tbody.paiementsCours',
            replaceElement: true,
        },
        'newPaiementFrais': {
            el: '.newPaiementFrais',
            replaceElement: true,
        },
        'newPaiementCours': {
            el: '.newPaiementCours',
            replaceElement: true,
        },
        'listePaiementCoursAbsents': {
            el: 'tbody.listePaiementCoursAbsents',
            replaceElement: true,
        },
        'letotal': '.letotal',
        'solde': '.solde',
        'maximum': '.maximum',
        'maximum_restant': '.maximum_restant'

    },

    initialize: function () {
        this.model = new Backbone.Model({
            total: 0,
            solde: 0,
            maximum: this.getOption('controller').get('inscriptionp').get('MontantMaximum'),
            maximum_restant: 0
        })
        this.updatePaiementSummary();
        this.listenTo(this.getOption('controller').get('paiement'), 'change:Montant', _.bind(this.updatePaiementSummary, this));
        this.listenTo(this.getOption('controller').get('paiement'), 'change:Paiement', _.bind(this.updatePaiementSummary, this));
        this.listenTo(this.getOption('controller').get('paiement'), 'add', _.bind(this.updatePaiementSummary, this));
        this.listenTo(this.getOption('controller').get('paiement'), 'remove', _.bind(this.updatePaiementSummary, this));
    },
    updateWindow: function(){
        this.updatePaiementSummary();
        this.render();
    },
    updatePaiementSummary: function () {
        this.model.set({
            total: 0,
            solde: 0,
            maximum: this.getOption('controller').get('inscriptionp').get('MontantMaximum'),
            maximum_restant: 0
        })
        const data = {
            total: 0,
            solde: 0,
            maximum_restant: this.model.get('maximum')
        }
        this.getOption('controller').get('paiement').forEach((item) => {
            if(item.get('Paiement')){
                data.total += item.get('Paiement')
            }
            data.solde += (item.get('Montant') - item.get('Paiement'));
            if((item.get('related_type')=='cours' || item.get('related_type')=='etudiantevals') && item.get('Paiement') && item.get('rembourse') != true){
                data.maximum_restant -= item.get('Paiement')
                
            }
            /*
            data.total += item.get('Montant');
            if(!item.get('Paiement'))data.solde += item.get('Montant');
            */
        });
        //data.maximum_restant = this.model.get('maximum') - data.total;
        this.model.set(data);
    },

    onRender: function () {

        this.showChildView('paiementsfrais', new PaiementListFrais({
            collection: this.getOption('controller').get('paiement'),
            controller: this.getOption('controller')
        }))
        this.showChildView('paiementscours', new PaiementListeCours({
            collection: this.getOption('controller').get('paiement'),
            controller: this.getOption('controller')
        }))

        this.showChildView('listePaiementCoursAbsents', new PaiementListeCoursAbsents({
            controller: this.getOption('controller')
        }))
        this.showChildView('newPaiementFrais', new NewPaiementFraisItem({
            controller: this.getOption('controller')
        }))
        this.showChildView('newPaiementCours', new NewPaiementCoursItem({
            controller: this.getOption('controller')
        }))

        this.showChildView('letotal', new M.View({
            tagName: 'span',
            template: _.template('<%=total.toFixed(2)%> $'),
            modelEvents: { 'change:total': 'render' },
            model: this.model
        }))
        this.showChildView('solde', new M.View({
            tagName: 'span',
            template: _.template('<%=solde.toFixed(2)%> $'),
            modelEvents: { 'change:solde': 'render' },
            model: this.model
        }))
        /*
        this.showChildView('maximum', new M.View({
            tagName: 'span',
            template: _.template('<%=maximum.toFixed(2)%> $'),
            modelEvents: { 'change:maximum': 'render' },
            model: this.model
        }))
        */
        this.showChildView('maximum', new MontantMaximum({
            model: this.model,
            controller: this.getOption('controller')
        }))
        this.showChildView('maximum_restant', new M.View({
            tagName: 'span',
            template: _.template('<%=maximum_restant.toFixed(2)%> $ <%= maximum_restant <= 0 ? "(le montant maximum a été atteint)" : "" %>'),
            modelEvents: {
                'change:maximum_restant': 'render',
            },
            model: this.model
        }))
    },

    onAjouterPaiement: function (view) {
        $.ajax({
            url: 'api/etudiants/ajouterpaiement',
            type: 'post',
            data: view.model.toJSON(),
            success: (response) => {
                this.getOption('controller').get('paiement').add(response);
                view.triggerMethod('reinitialize');
                Toast.add('Paiement ajouté avec succès', 'success');
            },
            error: () => {
                Toast.add('Impossible d\'ajouter le paiement', 'danger');
            }
        })
    },

    onPayer: function (view) {
        // vérifier s'il y a des éléments à payer d'abord...
        let peutPayer = false;
        this.getOption('controller').get('paiement').forEach((item) => {
            if (!item.get('Paiement')) peutPayer = true;
        })
        if (peutPayer) {
            const v = new PasserellePaiement({
                paiements: this.getOption('controller').get('paiement'),
                NoEtudiant: this.getOption('controller').get('etudiant').get('NoEtudiant')
            })
            showModal('Passerelle de paiement en ligne', v);
        } else {
            showAlert('Paiement', 'Aucun élément est en attente de paiement.')
        }
        // afficher la fenêtre de paiement à l'usager
    },
    onSupprimerPaiement: function (model) {
        
        if (confirm('Êtes-vous certain de vouloir supprimer ce paiement ?')) {
            $.ajax({
                url: 'api/etudiants/supprimer_paiement/',
                type: 'post',
                data: {
                    IDPaiement: model.get('IDPaiement')
                },
                success: () => {
                    this.getOption('controller').get('paiement').remove(model);
                    Toast.add('Le paiement a été supprimé avec succès !', 'success');
                },
                error: () => {
                    Toast.add('Le paiement n\'a pu être supprimé', 'danger');
                }
            })
        }
    },
})
