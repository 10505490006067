import M from 'backbone.marionette'
import T from './choixprogramme.html'
import backboneMarionette from 'backbone.marionette'
import { FormElement } from '../../utils/bootstrap/formelement';
export const NouveauCandidatChoixProgramme = M.View.extend({
    template: _.template(T),
    programmes: null,
    admin: null,
    regions: {
        'Programmes':'.Programmes',
        'ajouterFraisFixe':'.ajouterFraisFixe',
        'MontantMaximum':'.MontantMaximum',
    },
    modelEvents: {
        'change:NoProgramme':'onChoixProgrammeChanged',
        'change:MontantMaximum':'render',
    },
    initialize: function(){
        console.log('montantAec', parseFloat(this.getOption('montantAec')))
        console.log('montantDec', parseFloat(this.getOption('montantDec')))
        this.model = new Backbone.Model({
            NoProgramme: this.getOption('noProgramme'),
            AjouterFraisFixe: this.getOption('ajouterfraisfixe'),
            MontantMaximum: 0,
            ExpectedMontantMaximum: 0,
        })
        this.programmes = new Backbone.Collection();
        this.admin = new Backbone.Model();
        $.ajax({
            url: 'api/data/donneeschoixprogramme',
            cache: false,
            success: (response) => {
                this.programmes.reset(response.programmes);
                this.admin.set(response.admin);
                this.render();
            },
            error: () => {
                alert('Une erreur de chargement a eu lieu')
            }
        })
    },
    onChoixProgrammeChanged: function(){
        const selectedProgramme = this.programmes.get(this.model.get('NoProgramme'));
        const expectedMontantMaximum = selectedProgramme ? (selectedProgramme.get('Type') == 'AEC' ? parseFloat(this.getOption('montantAec')) : parseFloat(this.getOption('montantDec'))) : 0;
        const otherMontantMaximum = selectedProgramme ? (selectedProgramme.get('Type') == 'AEC' ? parseFloat(this.getOption('montantDec')) : parseFloat(this.getOption('montantAec'))) : 0;
        if(!this.model.get('MontantMaximum') || this.model.get('MontantMaximum') ==  otherMontantMaximum){
            this.model.set('MontantMaximum', expectedMontantMaximum)
        }
        this.model.set('ExpectedMontantMaximum', expectedMontantMaximum)
        this.render();
    },
    onRender: function(){
        
        const col = new Backbone.Collection(this.programmes.reduce((prev, curr, index) => {
            prev.push({
                value: curr.get('id'),
                label: curr.get('CodeProgramme') + ' - ' + curr.get('NomProgramme') + ' (' + curr.get('Type') + ')'
            })
            return prev
        }, [{value: null, label: 'Sélectionner un programme...'}]))
        this.showChildView('Programmes', new FormElement({
            type: 'select',
            collection: col,
            model: this.model,
            attribute: 'NoProgramme',
            label : 'Programme de formation'
        }))
        if(this.model.get('NoProgramme')){
            
            let frais = 0;
            const programme = this.programmes.get(this.model.get('NoProgramme'));
            
            if(programme){
                switch(programme.get('Type')){
                    case 'AEC':
                    case 'DEC':
                        frais = this.admin.get('FraisFixe'+programme.get('Type'));
                        break;
                    default:
                        break;
                }
            }
            if(frais){
                this.showChildView('ajouterFraisFixe', new FormElement({
                    type: 'checkbox',
                    model: this.model,
                    attribute: 'AjouterFraisFixe',
                    label: 'Ajouter le frais fixe de ' + (frais.toFixed(2)) + ' $ pour frais fixe d\'inscription à un programme ' + programme.get('Type'),
                }));
            }
            this.showChildView('MontantMaximum', new FormElement({
                type: 'number',
                inputAttibutes: {
                    step: '0.01',
                },
                model: this.model,
                attribute: 'MontantMaximum',
                label: 'Montant maximum pour ce candidat' + (this.model.get('ExpectedMontantMaximum')!=this.model.get('MontantMaximum') ? (' <strong>Attention : </strong><span class="text-danger">(normalement ' + this.model.get('ExpectedMontantMaximum').toFixed(2) + ' $)</span>') : ''),
            }));
        }
    }
})