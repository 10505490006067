import M from 'backbone.marionette'
import { gestionChargeController } from './controller';

export const ChargeDropZone = M.View.extend({
    template:_.template('<div>Déplacer ici<br /><span class="glyphicon glyphicon-circle-arrow-down" aria-hidden="true"></span></div>'),
    className: 'dropzone hidden',
    events: {
        'dragenter':'onDragenter',
        'dragover':'onDragover',
        'dragleave':'onDragLeave',
        'drop':'onDrop',
    },
    initialize: function(){
        this.listenTo(gestionChargeController, 'change:currentDragCompetence', _.bind(this.onCurrentDragElementCompetence, this))
    },
    onDragenter: function(event){
        this.$el.addClass('drag-over');
        gestionChargeController.set('currentDropChargeId', this.model.get('id'));
        event.preventDefault();
    },
    onDragover: function(event){
        this.$el.addClass('drag-over');
        gestionChargeController.set('currentDropChargeId', this.model.get('id'));
        event.preventDefault();
    },
    onDragLeave: function(event){
        this.$el.removeClass('drag-over');
        if(gestionChargeController.get('currentDropChargeId') == this.model.get('id')){
            gestionChargeController.set('currentDropChargeId', null);
        }
    },
    onDrop: function(event){

        const previousCompetenceEtudiantSelected = gestionChargeController.get('selectedCompetenceEtudiant')
        
        this.$el.removeClass('drag-over');
        if(gestionChargeController.get('currentDropChargeId') == this.model.get('id')){
            gestionChargeController.set('currentDropChargeId', null);
        }

        // la compétence étudiant sélectionnée fait-elle partie de compétences sélectionnées ?
        if(gestionChargeController.get('selectedCompetenceEtudiants')){
            if(gestionChargeController.get('selectedCompetenceEtudiants').find(gestionChargeController.get('currentDragCompetence'))){
                // on déplace tout le monde
                gestionChargeController.get('selectedCompetenceEtudiants').forEach((item) => {
                    item.set('enseignant_id', this.model.get('id') ? this.model.get('id'): null);
                })
                
                gestionChargeController.resetCompetenceSelections();
                gestionChargeController.onCompetencesAltered();
                gestionChargeController.hydrate();
                if(gestionChargeController.get('type') == 'enseignant'){
                    gestionChargeController.selectCompetenceEtudiant(previousCompetenceEtudiantSelected);
                }
                return;
            }
        }
        gestionChargeController.get('currentDragCompetence').set('enseignant_id', this.model.get('id') ? this.model.get('id') : null);
        
        gestionChargeController.resetCompetenceSelections();
        gestionChargeController.onCompetencesAltered();
        gestionChargeController.hydrate();
        if(gestionChargeController.get('type') == 'enseignant'){
            gestionChargeController.selectCompetenceEtudiant(previousCompetenceEtudiantSelected);
        }
    },
    onCurrentDragElementCompetence: function(){
        
        const competenceetudiant = gestionChargeController.get('currentDragCompetence');
        if(competenceetudiant){
            if(competenceetudiant.get('enseignant_id')){
                if(this.model.get('id') == competenceetudiant.get('enseignant_id')){
                    this.$el.addClass('hidden');
                }else{
                    this.$el.removeClass('hidden');
                }
            }else{
                if(this.model.get('id')==0){
                    this.$el.addClass('hidden');
                }else{
                    this.$el.removeClass('hidden');
                }
            }
        }else{
            this.$el.addClass('hidden');
        }
    }

})