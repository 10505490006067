import M from 'backbone.marionette'
import T from './mespaiements.html'
import { Loading } from '../../utils/bootstrap/loading';
import { MesPaiementsItem } from './mespaiementsitem';
import { RacAPI } from '../dataapi';
import { PasserellePaiement } from '../etudiant/paiement/passerelle';
import { showModal, showAlert } from '../../utils/bootstrap/modal';

const Liste = M.CollectionView.extend({
    template: _.template(T),
    ui: {
        'btPayer':'.btPayer',
        'hasPaiements':'.hasPaiements',
        'hasNoPaiements':'.hasNoPaiements'
    },
    triggers: {
        'click .btPayer':'payer'
    },
    childViewContainer: 'tbody',
    childView: MesPaiementsItem,
    aAuMoinsUnPaiement: function(){
        for(var i = 0; i < this.collection.length ;i++){
            if(!this.collection.at(i).get('Paiement') && this.collection.at(i).get('Montant')){
                return true;
            }
        }
        return false;
    },
    onRender: function(){
        const paiementEnabled = this.aAuMoinsUnPaiement();
        this.ui.btPayer.prop('disabled', !paiementEnabled);

        if(paiementEnabled){
            this.ui.hasPaiements.show();
            this.ui.hasNoPaiements.hide();
        }else{
            this.ui.hasPaiements.hide();
            this.ui.hasNoPaiements.show();
        }
    },
    onPayer: function(){
        // vérifier s'il y a des éléments à payer d'abord...
        let peutPayer = false;
        this.collection.forEach((item)=>{
            if(!item.get('Paiement') && item.get('Montant'))peutPayer = true;
        })
        if(peutPayer){
            const v = new PasserellePaiement({
                paiements: this.collection,
                NoEtudiant: document.userdata.id
            })
            showModal('Passerelle de paiement en ligne', v);
        }else{
            showAlert('Paiement', 'Aucun élément est en attente de paiement.')
        }
        // afficher la fenêtre de paiement à l'usager
    },
})


export const MesPaiements = M.View.extend({
    template:_.template('<div></div>'),
    regions: {
        'content':{
            el: '>div',
            replaceElement: true,
        }
    },
    modelEvents: {
        'change:loading':'render',
    },
    paiements: null,
    initialize: function(){
        this.model = new Backbone.Model({
            loading: true,
        });
        $.ajax({
            url: 'api/data/mespaiements',
            cache: false,
            success: (response) => {
                this.paiements = new Backbone.Collection(response);
                this.model.set('loading', false);
            },
            errors : (xhr) => {
                RacAPI.handleError(xhr);
            }
        })
    },
    onDestroy: function(){
        this.paiements = null;
    },
    onRender: function(){
        if(this.model.get('loading')){
            this.showChildView('content', new Loading());
        }else{
            this.showChildView('content', new Liste({
                collection: this.paiements
            }))
        }
    }
});
