import M from 'backbone.marionette'
import T from './grilleprogramme.html'
import { Loading } from '../../utils/bootstrap/loading';
import { CandidatsListeView } from './candidatsliste';



const Candidats = M.View.extend({
    template: _.template(T),
    childViewTriggers: {
        'order:by':'order:by',
        'show:competence:statut':'show:competence:statut',
    },
    regions: {
        'liste':'.liste',
    },
    
    onRender: function(){
        this.showChildView('liste', new CandidatsListeView({
            collection: this.getOption('results'),
            model: this.model,
        }))
    },
    
})

export const GrilleCandidats = M.View.extend({

    template: _.template('<div></div>'),
    regions: {
        'content':{
            el: '>div',
            replaceElement: true
        }
    },
    results: null,
    modelEvents: {
        'change:loading':'render',
        'change:loaded':'render',
    },

    initialize: function(){

        this.model = new Backbone.Model({
            loading: false,
            loaded: false,
            NoCompetence: this.getOption('noCompetence'),
            Statut: this.getOption('statut'),
            NoProgramme: this.getOption('noProgramme'),
        });
        this.results = new Backbone.Collection();
        this.loadData();
    },

    loadData: function(){
        this.model.set({
            loading: true,
            loaded: false
        })
        $.ajax({
            url: 'api/filtreurdestatut/candidats',
            cache: false,
            data: {
                NoCompetence: this.model.get('NoCompetence'),
                Statut: this.model.get('Statut'),
                NoProgramme: this.model.get('NoProgramme')
            },
            success: (response) => {
                this.results.reset(response.results)
                this.model.set({
                    loading: false,
                    loaded: true
                })
            },
            error: (xhr) => {

            }
        })
    },
    onRender: function(){
        if(this.model.get('loading')){
            this.showChildView('content', new Loading())
        }else{
            this.showChildView('content', new Candidats({
                model: this.model,
                results: this.results
            }))
        }
    },
    onShowCompetenceStatut: function(view){
        // un statut a été sélectionné. Charger les infos en lien avec la compétence et le statut sélectionné
        // finalement, on y va avec un direct link (pour permettre d'Accéder à cet interface à partir de
        // n'importe quel endroit dans la plateforme...)
    }
})