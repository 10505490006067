import { showUploadPhotoDialog } from "./photoupload"

/**
 * PHOTO UPLOADER
 * <input type="text" name="imageurl" id="imageurl" />
 * <img src="image/path/photo.jpg" id="myimage" />
 * <button data-module="photouploadbutton" data-input-id="imageurl" data-image-id="myimage">Changer l'image</button>
 */

export const PhotoUploadButton = M.View.extend({
    template: _.template('Changer l\'image'),
    tagName: 'button',
    className: 'btn btn-default',
    triggers: {
        'click':'click'
    },
    onClick: function(){
        showUploadPhotoDialog({
            onUploaded: (imageUrl) => {
                $('#'+this.getOption('inputId')).val(imageUrl);
                $('#'+this.getOption('imageId')).attr('src', 'assets/photos/' + imageUrl);
                $('#'+this.getOption('backgroundImageId')).css('background-image', 'URL(assets/photos/' + imageUrl + ')');
            }
        })
    }
})