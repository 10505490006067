import T from './nouveausuivi.html'
import moment from 'moment';
import { BaseSuiviView } from './basesuiviview';
import { userdata } from '../../user';

export const NouveauSuivi = BaseSuiviView.extend({
    tagName: 'tr',
    className:'bAjout bas',
    template: _.template(T),
    triggers: {
        'click .btnAjouter':'ajouter:suivi',
    },
    initialize: function(){
        this.model = new Backbone.Model();
        this.onReset();
    },
    onReset: function(){
        this.model.set({
            NoSuivi: null,
            TextSuivi: '',
            NoEtudiant: this.getOption('controller').get('etudiant').get('NoEtudiant'),
            author_id: userdata('user_id'),
            DateSuivi: moment().format('YYYY-MM-DD HH:mm:ss'),
            Type: null,
        })
    },
    onRender: function(){

        this.renderInputs(this.model);
        
    }
})