import M from 'backbone.marionette'
import T from './info.html'
import { FormElement } from '../../utils/bootstrap/formelement'
import { showAlert } from '../../utils/bootstrap/modal'
import { Toast } from '../../utils/bootstrap/toast'
import { FormHandler } from '../../utils/bootstrap/form/formhandler'
import { AccesEtudiant } from './access/accessetudiant'
import { showUploadPhotoDialog } from '../upload/photoupload'


export const InfoView = M.View.extend({
    template: _.template(T),
    id: 'etudiantPartial',
    className: 'candidat',
    ui: {
        'btnSave': '.btnSave',
        'avatar': '#avatar',
        'btnModifierPhoto': '.btnModifierPhoto',
    },
    regions: {
        'photo': {
            el: '.photo',
            replaceElement: true,
        },
        'noProgrammeInput': {
            el: '.noProgrammeInput',
            replaceElement: true,
        },
        'dateEntrevueInput': {
            el: '.dateEntrevueInput',
            replaceElement: true
        },
        "arretInput": {
            el: '.arretInput',
            replaceElement: true
        },
        'diplomeObtenuInput': {
            el: '.diplomeObtenuInput',
            replaceElement: true
        },
        'dateFinReelleInput': {
            el: '.dateFinReelleInput',
            replaceElement: true,
        },

        'appelInput': {
            el: '.appelInput',
            replaceElement: true,
        },
        'prenomInput': {
            el: '.prenomInput',
            replaceElement: true,
        },
        'nomFamilleInput': {
            el: '.nomFamilleInput',
            replaceElement: true,
        },
        'dateNaissanceInput': {
            el: '.dateNaissanceInput',
            replaceElement: true,
        },
        'daInput': {
            el: '.daInput',
            replaceElement: true
        },
        'courrielInput': {
            el: '.courrielInput',
            replaceElement: true
        },
        'telephoneInput': {
            el: '.telephoneInput',
            replaceElement: true,
        },
        'telephoneCellulaireInput': {
            el: '.telephoneCellulaireInput',
            replaceElement: true,
        },

        'fonctionInput': {
            el: '.fonctionInput',
            replaceElement: true,
        },
        'employeurInput': {
            el: '.employeurInput',
            replaceElement: true,
        },
        'telephoneTravailInput': {
            el: '.telephoneTravailInput',
            replaceElement: true
        },
        'posteTravail': {
            el: '.posteTravail',
            replaceElement: true,
        },
        'courrielTravail': {
            el: '.courrielTravail',
            replaceElement: true
        },
        'typeEmployeurTravail': {
            el: '.typeEmployeurTravail',
            replaceElement: true
        },
        'accesPlateforme': {
            el: '.accesPlateforme',
            replaceElement: true
        },
    },
    events: {
        'click .btnSave': 'onSaveClick',
        'click .btnModifierPhoto': 'onModifierPhotoClick'
    },
    serializeData: function () {
        return {
            etudiant: this.getOption('controller').get('etudiant').toJSON()
        }
    },

    dropZoneInstance: null,
    etudiant: null,
    inscriptionp: null,
    initialData: null,

    
    onRender: function () {
        this.showChildView('photo', new FormElement({
            type: 'hidden',
            label: 'Photo du candidat',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'Photo',
        }));
        this.showChildView('noProgrammeInput', new FormElement({
            horizontal: true,
            type: 'indexeurautocomplete',
            indexType: 'programme',
            label: 'Programme',
            model: this.getOption('controller').get('inscriptionp'),
            attribute: 'NoProgramme',
            serviceUrl: 'autocomplete/search/programme',
            renderItem: (item, currentValue) => {
                console.log('RENDER AN ITEM', item);
                const element = document.createElement("div");
                element.innerHTML = item.label;
                return element;
            }
        }));
        this.showChildView('dateEntrevueInput', new FormElement({
            horizontal: true,
            type: 'datepicker',
            label: 'Date de l\'entrevue',
            model: this.getOption('controller').get('inscriptionp'),
            attribute: 'DateEntrevue',
        }));
        this.showChildView('arretInput', new FormElement({
            horizontal: true,
            type: 'checkbox',
            label: 'Arrêt',
            model: this.getOption('controller').get('inscriptionp'),
            attribute: 'Arret',
        }));
        this.showChildView('diplomeObtenuInput', new FormElement({
            horizontal: true,
            type: 'checkbox',
            label: "Diplôme obtenu ?",
            model: this.getOption('controller').get('inscriptionp'),
            attribute: 'DiplomeObtenu',
        }));
        this.showChildView('dateFinReelleInput', new FormElement({
            horizontal: true,
            type: 'datepicker',
            label: 'Date de fin',
            model: this.getOption('controller').get('inscriptionp'),
            attribute: 'DateFinReelle',
        }));


        this.showChildView('appelInput', new FormElement({
            horizontal: true,
            type: 'select',
            label: 'Appel',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'Appel',
            collection: new Backbone.Collection([
                { value: '', label: '[Veuillez sélectionner]' },
                { value: 'Monsieur' },
                { value: 'Madame' }
            ])
        }));
        this.showChildView('prenomInput', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'Prénom',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'Prenom',
        }));
        this.showChildView('nomFamilleInput', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'Nom',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'NomFamille',
        }));
        this.showChildView('dateNaissanceInput', new FormElement({
            horizontal: true,
            type: 'datepicker',
            label: 'Date de naissance',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'DateNaissance',
        }));
        this.showChildView('daInput', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'DA',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'DA',
        }));
        this.showChildView('courrielInput', new FormElement({
            horizontal: true,
            type: 'email',
            label: 'Courriel',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'Courriel',
        }));
        this.showChildView('telephoneInput', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'Téléphone',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'Telephone',
        }));
        this.showChildView('telephoneCellulaireInput', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'Mobile',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'TelephoneCellulaire',
        }));

        this.showChildView('fonctionInput', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'Fonction',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'Fonction',
        }));
        this.showChildView('employeurInput', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'Employeur',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'Employeur',
        }));
        this.showChildView('telephoneTravailInput', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'Téléphone (Travail)',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'TelephoneTravail',
        }));
        this.showChildView('posteTravail', new FormElement({
            horizontal: true,
            type: 'text',
            label: 'Poste',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'Poste',
        }));
        this.showChildView('courrielTravail', new FormElement({
            horizontal: true,
            type: 'email',
            label: 'Courriel au travail',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'CourrielTravail',
        }));
        this.showChildView('typeEmployeurTravail', new FormElement({
            horizontal: true,
            type: 'select',
            label: 'Type d\'employeur',
            model: this.getOption('controller').get('etudiant'),
            attribute: 'TypeEmployeur',
            collection: new Backbone.Collection([
                { value: '', label: '[Veuillez sélectionner]' },
                { value: 'Privé' },
                { value: 'Public' }
            ])
        }));
        this.showChildView('accesPlateforme', new AccesEtudiant({
            controller: this.getOption('controller'),
        }))
    },

    onModifierPhotoClick: function () {
        showUploadPhotoDialog({
            onUploaded: (imageUrl) => {
                this.ui.avatar.css('background-image','URL(assets/photos/' + imageUrl + ')');
                this.getOption('controller').get('etudiant').set('Photo', imageUrl);
            }
        });

    },

    onSaveClick: function (event) {
        const formHandler = new FormHandler();
        formHandler.addModel('etudiant', this.getOption('controller').get('etudiant'));
        formHandler.addModel('inscriptionp', this.getOption('controller').get('inscriptionp'));
        formHandler.addCollection('competenceetudiant', this.getOption('controller').get('competenceetudiant'));
        
        this.ui.btnSave.prop('disabled', true);
        formHandler.save('api/etudiants/enregistrerfiche/' + this.getOption('controller').get('etudiant').get('NoEtudiant'),
            (response) => {
                this.triggerMethod('data:received', response)
                Toast.add('La sauvegarde a fonctionné !', 'success');
                this.ui.btnSave.prop('disabled', false);
                this.render();
            },
            (errors) => {
                Toast.add('Une erreur a eu lieu :(', 'danger')
                this.ui.btnSave.prop('disabled', false);
                if (errors) {
                    showAlert('Erreur', errors.join('<br />'));
                }
            }
        )
    },
})
