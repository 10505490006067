import Backbone from 'backbone'
import M from 'backbone.marionette'
import _ from 'underscore'
import T from './userdropdownnotices.html'
import TItem from './noticeitem.html'
import moment from 'moment'

const NoticeListItem = M.View.extend({
    triggers: function(){
        return _.extend(this.model.get('url') ? {} : {'click' : 'userclick'}, 
            {
                'click .btnDelete' : 'delete:click',
                'click .btnToggleVu' : 'toggle:vu:click',
            }
        );
    },
    modelEvents: {
        'change':'render',
    },
    template: _.template(TItem),
    tagName: 'li',
    serializeData: function(){
        return _.extend(this.model.toJSON(), {
            updated: this.model.get('updated') ? moment(this.model.get('updated')).fromNow() : null,
            showButtons: this.model.get('url') || this.model.get('onClick'),
            loading: this.model.get('loading')
        })
    },
    onRender: function(){
        if(this.model.get('vu')===false) this.$el.addClass('new');
        else this.$el.removeClass('new');
    },
    onUserclick: function(){
        if(this.model.get('onClick')){
            this.model.get('onClick')();
        }
    },
    onToggleVuClick: function(){
        this.model.set('loading', true);
        $.ajax({
            url: './api/data/notices_toggle_vu',
            type: 'post',
            data: {
                notice_id: this.model.get('id')
            },
            success: _.bind(this.onToggleVu, this),
            error: _.bind(this.onError, this)
        })
    },
    onToggleVu: function(response){
        this.triggerMethod('list:result', response);
        this.model.set('loading', null);
    },
    onDeleteClick: function(){
        this.model.set('loading', true);
        $.ajax({
            url: './api/data/delete_notice',
            type: 'post',
            data: {
                notice_id: this.model.get('id')
            },
            success: _.bind(this.onDeleteSuccess, this),
            error: _.bind(this.onError, this)
        })
    },
    onDeleteSuccess: function(response){
        if(response && 'total' in response && 'new' in response){
            this.triggerMethod('delete', this.model.get('id'), response)
        }
        this.model.set('loading', null);
    },
    onError: function(xhr){
        this.model.set('loading', null);
    }
})

const NoticeList = M.CollectionView.extend({
    tagName: 'ul',
    className: 'dropdown-menu dropdown-menu-right dropdown-notices',
    template: _.template(''),
    childView: NoticeListItem,
    childViewEventPrefix: 'childview',
    onChildviewListResult: function(response){
        this.triggerMethod('list:result', response);
    },
    onChildviewDelete: function(noticeId, response){
        this.triggerMethod('delete', noticeId, response)
    }
})

const NoticeModel = Backbone.Model.extend({
    default: {
        id: 0,
    }
})
const NoticeCollection = Backbone.Collection.extend({
    model: NoticeModel
});

export const UserDropdownNotices = M.View.extend({
    template: _.template(T),
    events: {
        'click':'onClick',
    },
    childViewEventPrefix: 'childview',
    regions: {
        'list': {
            el: '.dropdown-notices',
            replaceElement: true
        } 
    },
    modelEvents: {
        'change':'updateUI',
    },
    ui:{
        'button':'.compteButton',
        'icon':'.compteIcon',
        'text':'.compteText'
    },
    onClick: function(){
        if(this.notices.length==0){
            this.requestList();
        }
    }, 
    notices: null,
    initialize: function(){
        this.model = new Backbone.Model({
            notices: this.getOption('notices') ?? '0',
            total: null,
            loading: false
        });
        this.notices = new NoticeCollection();
        this.notices.comparator = 'nochangment';
    },
    onDestroy: function(){
        this.notices = null;
        this.initialFetchDone = null;
    },
    requestList: function(){
        
        var limits = this.model.get('total') ? this.notices.reduce(function(acc, curr){ 
            return [
                curr.get('nochangement') ? (parseInt(curr.get('nochangement')) < acc[0] ? parseInt(curr.get('nochangement')) : acc[0]) : acc[0],
                curr.get('nochangement') ? (parseInt(curr.get('nochangement')) > acc[1] ? parseInt(curr.get('nochangement')) : acc[1]) : acc[1]
                ]}, [Number.MAX_VALUE, 0]) : null;
        this.loading(true);
        $.ajax({
            url: './api/data/notices/' + (limits ? limits[0] + '/' + limits[1] : ''),
            cache: false,
            success: _.bind(this.onListResult, this),
            error: _.bind(this.onListError, this)
        });
    },
    onChildviewListResult: function(response){
     
        this.onListResult(response);
    },
    onChildviewDelete: function(noticeId, response){
        this.notices.remove(noticeId);
        this.normalizeInfos(response);
    },
    onListResult: function(response){
        this.loading(false);
        this.notices.add(response.notices, {parse: true, merge: true});
        this.normalizeInfos(response);
    },
    normalizeInfos: function(response){
        this.model.set({
            'total': response.total,
            'new': response.new
        });
        
        if(this.notices.length==0){
            this.notices.add({
                id: 0,
                created: null,
                vu: null,
                url: null,
                title: 'Aucune notification à afficher',
                text: null,
                onClick: null
            })
        }
    },
    onListError: function(){

    },
    onRender: function(){
        this.showChildView('list', new NoticeList({collection: this.notices}));
    },
    loading: function(p){
        if(this.loading !== p ){
            this.model.set('loading', p);
            if(p){
                this.notices.remove(0);
                this.notices.add({
                    id: 0,
                    created: null,
                    vu: null,
                    url: null,
                    title: 'Chargement ...',
                    text: null,
                    onClick: null
                })
            }else{
                this.notices.remove(0);
                if(this.model.get('total') && this.model.get('total') > this.notices.length){
                    this.notices.add({
                        id: 0,
                        created: null,
                        vu: null,
                        url: null,
                        title: 'Charger plus',
                        text: null,
                        onClick: _.bind(this.requestList, this)
                    });
                }
            }
        }
    },
    updateUI: function(){
        this.ui.text.text(this.model.get('new'));
        if(this.model.get('new')>0){
            this.ui.button.addClass('indicateur');
        }else{
            this.ui.button.removeClass('indicateur');
        }
    }

})