import M from 'backbone.marionette'

export const FormHandler = M.Object.extend({
    models: null,
    collections: null,
    singleModel: null,
    initialize: function(){
        this.models = {};
        this.collections = {};
    },
    addModel: function(name, model){
        this.models[name] = model;
        return this;
    },
    addCollection: function(name, col){
        this.collections[name] = col;
    },
    setModel: function(model){
        this.singleModel = model;
        return this;
    },
    save: function(url, success, error){
        var data = null;
        if(this.singleModel){
            data = this.singleModel.toJSON();
            this.singleModel.trigger('syncing', true);
        }else{
            data = {};
            for(var s in this.models){
                this.models[s].trigger('syncing', true);
                data[s] = this.models[s].toJSON()
            }
            for(var s in this.collections){
                this.collections[s].trigger('syncing', true)
                data[s] = this.collections[s].toJSON()
            }
        }
        $.ajax({
            url: url,
            type: 'post',
            data: {
                data: JSON.stringify(data),
            },
            success: (response) => {
                if(this.singleModel){
                    this.singleModel.trigger('syncing', false);
                    this.singleModel.trigger('error', null);
                    this.singleModel.set(response);
                }else{
                    for(var s in this.models){
                        this.models[s].trigger('syncing', false);
                        this.models[s].trigger('error', null);
                        if(s in response)this.models[s].set(response[s]);
                    }
                    for(var s in this.collections){
                        this.collections[s].trigger('syncing', false);
                        this.collections[s].trigger('error', null);
                        this.collections[s].set(s in response ? response[s] : []);
                    }
                }
                
                success(response);
            },
            error: (xhr) => {
                const validCodes = [400, 404, 403];
                if(this.singleModel){
                    this.singleModel.trigger('syncing', false);
                }else{
                    for(var s in this.models){
                        this.models[s].trigger('syncing', false);
                    }
                    for(var s in this.collections){
                        this.collections[s].trigger('syncing', false);
                    }
                }
                if( xhr.status==400 && _.isObject(xhr.responseJSON)){
                    // model error response code standard
                    if(this.singleModel){                        
                        this.singleModel.trigger('error', xhr.responseJSON);
                    }else{
                        for(var s in this.models){
                            if(s in xhr.responseJSON){
                                this.models[s].trigger('error', xhr.responseJSON[s]);
                            }
                        }
                        for(var s in this.collections){
                            if(s in xhr.responseJSON){
                                this.collections[s].trigger('error', xhr.responseJSON[s]);
                            }
                        }
                    }
                }
                var args = null;
                if(_.isObject(xhr.responseJSON) && 'errors' in xhr.responseJSON) args = xhr.responseJSON['errors'];
                else if(_.includes(validCodes, xhr.status) && _.isString(xhr.responseJSON) && xhr.responseJSON){
                    args = [xhr.responseJSON];
                }
                error(args);
            }
        });
        return this;
    }
})