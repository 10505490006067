import M from 'backbone.marionette'
import moment from 'moment'
import _ from 'underscore'

export const fromnow = M.View.extend({
    template: _.template('<%=value%>'),
    tagName: 'span',
    initialize: function(){
        this.$el.attr('title', this.getOption('date') ? moment(this.getOption('date')).calendar() : '')
    },
    serializeData: function(){
        return {
            value: this.getOption('date') ? moment(this.getOption('date')).fromNow() : ''
        }
    },
})