import Backbone from 'backbone';
import T from './competencesabsentes.html'


const AbsenceListItem = M.View.extend({
    template: _.template('<%= CodeCompetence %> <%= NomCompetence %> <button class="btn btn-default btn-xs">Ajouter</button>'),
    triggers: {
        'click button':'ajouter:competence:absente'
    },
    tagName: 'li',
    className: 'list-group-item',
})

const AbsenceList = M.CollectionView.extend({
    template: _.template(''),
    tagName: 'ul',
    className: 'list-group',
    childView: AbsenceListItem,
    childViewTriggers: {
        'ajouter:competence:absente':'ajouter:competence:absente'
    },
    attributes: {
        style: 'margin : 0.5em 0'
    },
})

export const CompetencesAbsentes = M.View.extend({
    template: _.template(T),
    regions: {
        'list':{
            el: '.listeAbsences',
            replaceElement: true
        }
    },
    childViewTriggers: {
        'ajouter:competence:absente':'ajouter:competence:absente'
    },
    collection: null,
    initialize: function(){
        this.collection = new Backbone.Collection();
        this.updateList();
        this.listenTo(this.model.get('competenceetudiant'), 'change', _.bind(this.updateList, this))
        this.listenTo(this.model.get('competenceetudiant'), 'add', _.bind(this.updateList, this))
        this.listenTo(this.model.get('competenceetudiant'), 'remove', _.bind(this.updateList, this))
    },
    onDestroy: function() {
        this.collection = null;
    },
    updateList: function(){
        var that = this;
        this.collection.reset(this.model.get('listecompetences').reduce(function(prev, curr){
            if(curr.get('NoCompetence') && !that.model.get('competenceetudiant').findWhere({NoCompetence: curr.get('NoCompetence')})){
                prev.push(curr)
            }
            return prev
        }, []))
        if(this.collection.length){
            this.$el.show();
        }else{
            this.$el.hide();
        }
    },
    onRender: function(){
        this.showChildView('list', new AbsenceList({
            collection: this.collection
        }))
    }
})