import M from 'backbone.marionette'
import T from './fiche.html'
import { FicheNav } from './fichenav';
import { Loading } from '../../utils/bootstrap/loading';
import { InfoView } from './info';
import { CompetenceView } from './competence';
import { CoursInscritsView } from './coursinscrits/coursinscrits';
import { CoursCompletesView } from './courscompletes';
import { PaiementsView } from './paiements';
import { SuivisView } from './suivis';
import { CandidatDocuments } from './documents';
import { userdata } from '../user';
import Backbone from 'backbone';

const FicheRouteur = Backbone.Router.extend({
    preinitialize: function (options) {
        options.navItems.forEach((item) => {
            if(item.get('view')){
                this.routes[item.get('id')] = item.get('id');
            }
        })
    },
    routes: {},
})


export const getNavItems = (NoEtudiant, urlOnly) => {

    const c = new Backbone.Collection();
    if(userdata('Type') == 'contributeur'){
        c.reset([
            { id: 'info', title: 'Informations', view: InfoView, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#info' },
            { id: 'competences', title: 'Compétences programmes', view: CompetenceView, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#competences' },
            { id: 'coursinscrits', title: 'Cours inscrits', view: CoursInscritsView, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#coursinscrits' },
            { id: 'courscompletes', title: 'Cours complétés', view: CoursCompletesView, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#courscompletes' },
            { id: 'paiements', title: 'Paiements', view: PaiementsView, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#paiements' },
            { id: 'suivis', title: 'Suivis publics', view: SuivisView, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#suivis' },
            { id: 'conversation', title: 'Conversation RAC', urlOnly: urlOnly, url: './candidat/conversation/' + NoEtudiant },
            { id: 'documents', title: 'Documents', view: CandidatDocuments, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#documents' }
        ]);
    }else{
        c.reset([
            { id: 'competences', title: 'Compétences programmes', view: CompetenceView, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#competences' },
            { id: 'suivis', title: 'Suivis', view: SuivisView, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#suivis' },
            { id: 'documents', title: 'Documents', view: CandidatDocuments, urlOnly: urlOnly, url: './candidat/fiche/' + NoEtudiant + '#documents' }
        ]);
    }
    return c
}

const EnseignantModel = Backbone.Model.extend({ idAttribute: 'NoEnseignant' });
const EnseignantCollection = Backbone.Collection.extend({ model: EnseignantModel });

const FicherController = Backbone.Model.extend({
    updateListeCompens: function(){
        $.ajax({
            url: 'api/etudiants/listecompens/' + this.get('etudiant').get('NoEtudiant'),
            cache: false,
            success: (response) => {
                this.get('listecompens').reset(response);
            }
        })
    }
});

export const FicheView = M.View.extend({
    template: _.template(T),
    regions: {
        'nav': '#ficheNav',
        'content': '#ficheContent',
    },
    views: null,
    childViewEventPrefix: 'childview',
    modelEvents: {
        'change:loading': 'render',
        'change:currentTab': 'render'
    },
    router: null,
    navItems: null,
    initialize: function () {
        this.navItems = getNavItems(this.getOption('id'), false)
        this.model = new FicherController({
            currentTab: this.getOption('tab') ? this.getOption('tab') : this.navItems.at(0).get('id'),
            loading: true,
            etudiant: new Backbone.Model(),
            inscriptionp: new Backbone.Model(),
            competenceetudiant: new Backbone.Collection(),
            inscriptionc: new Backbone.Collection(),
            programmecours: new Backbone.Collection(),
            listecompetences: new Backbone.Collection(),
            listecompens: new Backbone.Collection(),
            enseignants: new EnseignantCollection(),
            paiement: new Backbone.Collection(),
            paiement_types: new Backbone.Collection(),
            paiement_modes: new Backbone.Collection(),
            types_suivis: new Backbone.Collection(),
            suivi: new Backbone.Collection(),
            suiviusers: new Backbone.Collection(),
            documents: new Backbone.Collection(),
            utilisateurs: new Backbone.Collection(),
            invitations: new Backbone.Collection(),
            etudiantevals: new Backbone.Collection()
        });

        this.router = new FicheRouteur({navItems: this.navItems});
        this.navItems.forEach((item) => {
            if(item.get('view')){
                this.router.on('route:' + item.get('id'), () => {
                    this.model.set('currentTab', item.get('id'))
                })
            }else{
                if(item.get('id')==='conversation'){
                    item.set('url', './candidat/conversation/' + this.getOption('id'))
                }
            }
        })
        Backbone.history.start();

        this.views = _.clone(this.navItems);
        /*if(this.$el.text()) this.hydrateController(JSON.decode(atob(this.$el.text())));
        else*/ this.loadData();
    },
    loadData: function () {
        $.ajax({
            url: 'api/etudiants/data/' + this.getOption('id'),
            cache: false,
            success: _.bind(this.hydrateController, this),
            error: () => {
                this.showChildView('content', new M.View({ template: _.template('<h2>Erreur de chargement</h1>') }));
            }
        })
    },
    hydrateController: function(data){
        if(userdata('Type') === 'contributeur'){
            this.model.get('inscriptionp').set(data.inscriptionp);
            this.model.get('programmecours').reset(data.programmecours);
            this.model.get('paiement').reset(data.paiement);
            this.model.get('paiement_types').reset(_.map(data.paiement_types, (item) => { return { value: item } }));
            this.model.get('paiement_modes').reset(_.union([''], _.map(data.paiement_modes, (item) => { return { value: item } })));
            this.model.get('invitations').reset(data.invitations);
            this.model.get('inscriptionc').reset(data.inscriptionc);
        }
        this.model.get('etudiant').set(data.etudiant);
        this.model.get('competenceetudiant').reset(data.competenceetudiant);
        this.model.get('listecompetences').reset(data.listecompetences);
        this.model.get('listecompens').reset(data.listecompens);
        this.model.get('enseignants').reset(data.enseignants);
        this.model.get('types_suivis').reset(_.union([''], _.map(data.types_suivis, (item) => { return { value: item } })));
        this.model.get('suivi').reset(data.suivi);
        this.model.get('suiviusers').reset(data.suiviusers);
        this.model.get('documents').reset(data.documents);
        this.model.get('utilisateurs').reset(data.utilisateurs);
        this.model.get('etudiantevals').reset(data.etudiantevals)
        
        this.model.set('loading', false);
    },
    onRender: function () {
        if (this.model.get('loading')) {
            this.showChildView('content', new Loading());
        } else {
            this.showChildView('nav', new FicheNav({
                model: this.model,
                collection: this.views
            }));
            const viewItem = this.views.get(this.model.get('currentTab'));
            const viewClass = viewItem.get('view');
            let viewOptions = _.extend(
                viewItem.get('viewOptions') ? viewItem.get('viewOptions') : {},
                { controller: this.model }
            );
            const view = new viewClass(viewOptions);
            this.showChildView('content', view);
        }
    },
    onChildviewChangeTab: function (tabId) {
        const viewItem = this.views.get(tabId);
        if (tabId != this.model.get('currentTab') && viewItem) {
            this.model.set('currentTab', tabId);
        }
    },
    onDataReceived: function(ficheData){
        console.log('re-hydrate fiche data !');
        this.hydrateController(ficheData);
    }

})
