import M from 'backbone.marionette'
import T from './invitationsender.html'
import { Toast } from '../../../utils/bootstrap/toast'
import { RacAPI } from '../../dataapi'


export const InvitationSender = M.View.extend({
    template: _.template(T),
    triggers: {
        'click .btTransmettre': 'transmettre'
    },
    onTransmettre: function () {
        $.ajax({
            url: 'api/etudiants/inviter',
            type: 'post',
            data: {
                NoEtudiant: this.model.get('etudiant').get('NoEtudiant'),
            },
            success: (response) => {
                Toast.add("L'invitation a été transmise", 'success');
                this.model.get('invitations').reset(response)
                this.triggerMethod('close');
            },
            error: (xhr) => {
                RacAPI.handleError(xhr)
                this.triggerMethod('close')
            }
        })
    }
})
