import M from 'backbone.marionette'

const NavItem = M.View.extend({
    template: _.template('<a href="#<%=id%>"><%=title%></a>'),
    tagName: 'li',
    attributes: {
        'data-toggle': 'tab'
    },
    
    events: {
        'click >a':'onClick',
    },
    
    initialize: function(){
        this.listenTo(this.getOption('controller'), 'change:currentTab', _.bind(this.render, this))
    },
    
    onClick: function(){
        if(this.model.get('urlOnly') || !this.model.get('view')){
            window.location.href = this.model.get('url');
        }
        if(this.model.get('view')){
            Backbone.history.navigate('#'+this.model.get('id'), {trigger: true});
        }
    },
    onRender: function(){
        if(this.getOption('controller').get('currentTab')==this.model.get('id'))this.$el.addClass('active');
        else this.$el.removeClass('active');
    }

})

export const FicheNav = M.CollectionView.extend({
    template: _.template(''),
    tagName: 'ul',
    className: 'nav nav-tabs rac',
    childViewEventPrefix: 'childview',
    childView: NavItem,
    childViewOptions: function(){
        return {controller: this.model}
    },
    onChildviewChangeTab: function(tabId){
        this.triggerMethod('change:tab', tabId);
    }

})