import M from 'backbone.marionette'

const BaseControl = {
    template: _.template(''),
    className:'form-control',
    events: {
        'change':'onInputChanged',
    },
    attributes: function(){
        return _.extend(this.getOption('inputAttributes') ? this.getOption('inputAttributes') : {}, { 
            value: this.model.get(this.getOption('attribute')),
            type: this.getOption('type'),
            name: this.getOption('attribute')
        });
    },
    modelEvents: {},
    initialize: function(){
        this.modelEvents['change:'+this.getOption('attribute')] = 'updateInputValue'
        this.modelEvents['syncing'] = 'onSync';
    },
    updateInputValue: function(){
        const p = this.model.get(this.getOption('attribute'));
        this.$el.val(_.isFunction(this.getOption('prepValue')) ? this.getOption('prepValue')(p) : p)
    },
    onInputChanged: function(){
        console.log('Input changed ', this.$el.val());
        this.model.set(this.getOption('attribute'), _.isFunction(this.getOption('parseValue')) ? this.getOption('parseValue')(this.$el.val()) : this.$el.val());
    },
    onSync: function(syncing){
        this.$el.prop('disabled', syncing);
    }
}
export const FormCollectionControl = M.CollectionView.extend(BaseControl);
export const FormControl = M.View.extend(BaseControl);