import M from 'backbone.marionette'
import { Toast } from '../../../utils/bootstrap/toast';
import T from './passerelle.html'

const PaiementItem = M.View.extend({
    tagName: 'tr',
    template:_.template('<td><input type="checkbox" value="<%=id%>" <%=selected ? "checked" : "" %> /></td><td><%=title%></td><td><%=amount.toFixed(2)%> $</td>'),
    ui: {
        'checkbox':'input[type="checkbox"]',
    },
    events: {
        'change @ui.checkbox':'onSelectedChanged',
    },
    onSelectedChanged: function(){
        this.model.set('selected', this.ui.checkbox.is(':checked'));
    }
})

const ListePaiements = M.CollectionView.extend({
    tagName: 'table',
    className: 'table',
    template: _.template('<thead><tr><th></th><th>Item</th><th>Montant</th></tr></thead><tbody></tbody>'),
    childViewContainer: 'tbody',
    childView: PaiementItem,
})

const EditPaiement = M.View.extend({
    template: _.template(T),
    regions: {
        'paiement_liste':'.paiement_liste'
    },
    triggers: {
        'click .btnPayer':'payer',
        'click .btnAnnuler':'annuler'
    },
    ui: {
        'btnPayer':'.btnPayer',
        'total':'.total'
    },
    initialize: function(){
        this.listenTo(this.getOption('collection'), 'change', _.bind(this.onPaiementChanged, this))
    },
    
    onRender: function(){
        this.showChildView('paiement_liste', new ListePaiements({
            collection: this.getOption('collection'),
        }))
        this.onPaiementChanged();
    },
    onPaiementChanged: function(){
        const total = _.reduce(this.getOption('collection').toJSON(), (prev, curr) => {
            return prev + (curr.selected ? curr.amount : 0)
        }, 0)
        this.ui.total.html(total.toFixed(2) + ' $');
        this.ui.btnPayer.prop('disabled', !total);
    }
})

export const PasserellePaiement = M.View.extend({
    template: _.template('<div></div>'),
    regions: {
        'content':'>div',
    },
    childViewTriggers: {
        'payer':'payer',
        'annuler':'annuler'
    },
    collection: null,
    initialize: function(){
        this.collection = new Backbone.Collection();
        this.getOption('paiements').forEach((item) => {
            
            if(!item.get('Paiement') && item.get('Montant') && !item.get('rembourse')){
                this.collection.add({
                    id: item.get('IDPaiement'),
                    amount: item.get('Montant'),
                    taxable: 0,
                    title: item.get('paiement_title'),
                    selected: true
                })
            }
        })
    },
    onDestroy: function(){
        this.collection = null;
    },
    onRender: function(){
        this.showChildView('content', new EditPaiement({
            collection : this.collection,
            NoEtudiant: this.getOption('NoEtudiant')
        }))
    },
    onPayer: function(){
        this.showChildView('content', new M.View({
            template:_.template('<h1 class="text-center">Préparation de la commande...</h1><h2 class="text-center">Veuillez patienter</h2>'),
        }));
        $.ajax({
            url: 'api/etudiants/preparer_commande',
            type: 'post',
            data: {
                paiement_ids: JSON.stringify(this.collection.reduce((prev, curr) => {
                    if(curr.get('selected'))prev.push(curr.get('id'));
                    return prev;
                }, [])),
                NoEtudiant : this.getOption('NoEtudiant'),
                return_url: document.location.href
            },
            success: function(response){
                if(_.isString(response) && response.substr(0, 4) === 'http'){
                    document.location.href = response;
                }
            },
            error: function(){
                Toast.add('Une erreur a eu lieu. Veuillez réessayer plus tard', 'danger');
                this.triggerMethod('close');
            }
        })

    },
    onAnnuler: function(){
        this.triggerMethod('close');
    }
})