import M from 'backbone.marionette'
import { FormControl } from './formcontrol'
import { IndexeurAutocompleteView } from '../../autocomplete/indexeurautocomplete';

const IndexeurInput = FormControl.extend({
    tagName: 'input',
})


export const FormIndexeurAutocomplete = M.View.extend({
    template: _.template('<div style="display: none"><div class="hiddenInput"></div></div><input type="text" class="form-control autocomplete" />'),
    regions: {
        'hidden': {
            el: '.hiddenInput',
            replaceElement: true,
        },
        'autocomplete': {
            el: '.autocomplete',
            replaceElement: true
        }
    },
    childViewEventPrefix: 'childview',
    onRender: function(){

        //this.showChildView('hidden', new IndexeurInput(_.extend(_.clone(options), {type: 'hidden', name: this.getOption('attribute')})));
        this.showChildView('hidden', new IndexeurInput({
            type: 'hidden', 
            inputAttributes: {
                name: this.getOption('attribute'),
            },
            model: this.getOption('model'),
            attribute: this.getOption('attribute')
        }));
        

        var autocompleteOptions = _.pick(this.options, [
            'serviceUrl',
            'attribute',
            'model',
            'indexType',
            'readonly',
            'placeholder',
        ])
        autocompleteOptions.attributes = this.getOption('inputAttributes');
        this.showChildView('autocomplete', new IndexeurAutocompleteView(autocompleteOptions));
    },
    onChildviewSelect: function(model_id, _model){
       this.model.set(this.getOption('attribute'), model_id);
    }
})