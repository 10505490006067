import T from './chatbox.html'

const ChatBoxList = M.CollectionView.extend({
    template: _.template(''),
    className: 'chatbox-list-group',
    childViewEventPrefix: 'childview',
    attributes: {
        'style':'position: relative',
    },
    onChildviewAttach: function(){
        
        this.triggerMethod('new:list:item:attached')
    },
})

export const Chatbox = M.View.extend({
    
    template: _.template(T),

    attributes: {
        style: 'position: relative; overflow-y: scroll; height: 100%'
    },

    events: {
        'scroll':'onScroll'
    },

    regions: {
        'list':{
            el: '.chatbox-list-group',
            replaceElement: true
        },
        'top': {
            el: '.topindicator',
            replaceElement: true
        }
    },

    className: 'chatbox',

    //childViewContainer: '.chatbox-list-group',

    firstChildBeforeDomAdjustments: null,

    tickIntervalId: null,

    domAlteredOnCurrentProcess: null,
    currentlyFixingScroll: null,
    lastScrollTop: null,

    isAtBottom: null,

    childViewEventPrefix: 'childview',

    collectionEvents: {
        'add':'onCollectionAdd',
    },

    onRender: function(){
        this.showChildView('list', new ChatBoxList({
            collection: this.collection, 
            childView: this.getOption('childView'),
            viewComparator: this.getOption('viewComparator'),
            viewFilter: this.getOption('viewFilter')
        }))
        if(this.getOption('topView')){
            this.showChildView('top', this.getOption('topView'))
        }
    },

    onAttach: function(){
        this.tickIntervalId = setInterval(_.bind(this.tick, this));
        this.scrollDown();
    },

    onDetach: function(){
        if(this.tickIntervalId){
            clearInterval(this.tickIntervalId)
            this.tickIntervalId = null
        }
    },

    onCollectionAdd: function(){
        this.lastScrollTop = this.el.scrollTop
        this.firstChildBeforeDomAdjustments = this.getChildView('list').$container[0].firstChild
        this.domAlteredOnCurrentProcess = true
    },

    onChildviewNewListItemAttached: function(){
        if(this.isAtBottom === true){
            this.scrollDown();
        }else{
            this.fixScroll()
        }
    },

    scrollDown: function(){
        
        this.el.scrollTop = this.el.scrollHeight - this.el.clientHeight;
        this.isAtBottom = true;

    },

    fixScroll: function(){
        let oldElementsHeight = 0
        let currElement = this.firstChildBeforeDomAdjustments ? this.firstChildBeforeDomAdjustments.previousElementSibling : null
        while(currElement){
            oldElementsHeight += currElement.scrollHeight
            currElement = currElement.previousElementSibling
        }
        this.currentlyFixingScroll = true
        this.el.scrollTop = this.lastScrollTop + oldElementsHeight;
    },

    isBottom: function(){
        return this.el.scrollTop == (this.el.scrollHeight - this.el.clientHeight);
    },

    tick: function(){
        if(this.domAlteredOnCurrentProcess===true){
            this.domAlteredOnCurrentProcess = null
        }
    },    

    onScroll: function(){
        if(this.domAlteredOnCurrentProcess !== true){
            if(this.el.scrollTop === 0){
                this.triggerMethod('top:reached')
            }
            this.isAtBottom = this.isBottom();
        }
    }
})