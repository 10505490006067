export const paiement_types = [ "Autres","Frais fixe", "Frais de transport", "Supplément - analyse de plan de cours", "Frais d'évaluation en entreprise", "Document officiel"];
export const paiement_modes = [ "Terminal de paiement", "Comptant", "Chèque"];
export const types_suivis = ["autres", "courriel", "personne", "téléphone", "skype"];
export const competences_status = {
    'AS': 'AS',
    'EQ': 'EQ',
    'EV': 'EV',
    'FP': 'EV-FP',
    'FC': 'FC',
    'R': 'RÉUSSI',
    'SU': 'SU',
    'OA': 'OA',
    'EQC': 'EQ-C',
};
export const competences_status_descriptions = {
    'AS' : 'En analyse scolaire',
    'EQ' : 'Équivalence',
    'EV' : 'A évaluer',
    'FP' : 'À faire en formation partielle',
    'FC' : 'À faire en formation complète',
    'R' : 'Compétence acquise',
    'SU' : 'Substitution',
    'OA' : 'Objectif atteint',
    'EQC' : 'Équivalence sous condition',
};
export const complete_competences_status = [
    'R',
    'EQ',
    'SU',
    'OA',
];
export const uncompleted_competences_status = [
    'AS',
    'EV',
    'FP',
    'FC',
    'EQC',
];